import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReports(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('fetchReports')
        // if (ability.can('read', 'reports') && qvars.reportType !== null && qvars.reportType !== undefined && qvars.clientFilter !== null && qvars.clientFilter !== undefined) {
        if (ability.can('read', 'reports')) {
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.reports.fetchingReportList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'report_id') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort report_id desc')
                  return b.report_id.localeCompare(a.report_id)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort report_id asc')
                  return a.report_id.localeCompare(b.report_id)
                })
              }
            }
            if (qvars.sortBy === 'client_display_name') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort client_display_name desc')
                  return b.client_display_name.localeCompare(a.client_display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort client_display_name asc')
                  return a.client_display_name.localeCompare(b.client_display_name)
                })
              }
            }
            if (qvars.sortBy === 'report_type') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort report_type desc')
                  return b.report_type.localeCompare(a.report_type)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort report_type asc')
                  return a.report_type.localeCompare(b.report_type)
                })
              }
            }
            if (qvars.sortBy === 'user_display_name') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort user_display_name desc')
                  return b.user_display_name.localeCompare(a.user_display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort report_type asc')
                  return a.user_display_name.localeCompare(b.user_display_name)
                })
              }
            }
            if (qvars.sortBy === 'report_date_local') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort report_date_local desc')
                  return b.report_date_local.localeCompare(a.report_date_local)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort report_type asc')
                  return a.report_date_local.localeCompare(b.report_date_local)
                })
              }
            }
            if (qvars.sortBy === 'status') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort status desc')
                  return b.status.localeCompare(a.status)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort status asc')
                  return a.status.localeCompare(b.status)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              // filter by status
              if (qvars.approved !== null && item.approved !== qvars.approved) {
                addThisItem = false
              }
              // filter by Services
              // console.log('filter by Services')
              // console.log(qvars.service)
              // console.log(item.services)
              // if (typeof qvars.service !== 'undefined' && qvars.service !== null) {
              //   addThisItem = false
              //   if (typeof item.services !== 'undefined') {
              //     Object.keys(item.services).forEach(e => {
              //       if (e === qvars.service && item.services[e] === true) {
              //         console.log('Add the Item')
              //         addThisItem = true
              //       }
              //     })
              //   }
              // }
              // filter by search term
              if (qvars.q !== null
                && !item.report_id.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.client_display_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.report_type.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.user_display_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.report_date_local.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('reports') === null
            || parseInt(localStorage.getItem('reports_lastRefresh'), 10) + 60000 < timeDiff // cache for 1 hour
          ) {
            console.log('Refreshing Reports')
            console.log(qvars)
            const apiName = 'APIGateway'
            const path = `/reports/${qvars.reportType}/${qvars.clientFilter}`
            const myInit = { response: true, body: qvars }

            API.post(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                items.forEach(element => {
                  if (element.approved === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'true'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'false'
                  }
                })
                // Store Report data in local storage
                localStorage.setItem('reports', JSON.stringify(items))
                localStorage.setItem('reports_lastRefresh', d1.getTime())
                theResponse.data.reports = resortFilterItems(items)
                theResponse.data.totalReports = totalItems
                console.log(theResponse)
                store.state.reports.fetchingReportList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.reports.fetchingReportList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Reports Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem('reports'))
            theResponse.data.reports = resortFilterItems(itemsData)
            theResponse.data.totalReports = totalItems
            // console.log(theResponse)
            store.state.reports.fetchingReportList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchReport(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'reports')) {
          const apiName = 'APIGateway'
          // console.log(reportname)
          const path = `/reports/${qvars.reportType}/sk/${encodeURIComponent(qvars.SK)}`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              const reportData = {}
              reportData.value = response.data
              theResponse.data = reportData.value
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addReport(ctx, reportData) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'reports')) {
          const apiName = 'APIGateway'
          const path = '/reports'

          API.post(apiName, path, { body: reportData })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateReport(ctx, reportData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'reports')) {
          const apiName = 'APIGateway'
          const path = '/reports'

          API.patch(apiName, path, { body: reportData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              const oldReports = JSON.parse(localStorage.getItem('reports'))
              const newReports = []
              oldReports.forEach(item => {
                if (item.report_id === reportData.report_id) {
                  newReports.push(reportData)
                } else {
                  newReports.push(item)
                }
              })
              localStorage.setItem('reports', JSON.stringify(newReports))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
