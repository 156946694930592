<template>

  <div v-if="$can('read', abilityRequired)">
    <div class="iframe-container">
      <iframe
        :src="externalUrl"
        frameborder="0"
        width="100%"
        height="100%"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>

export default {
  setup() {
    const abilityRequired = 'elearning'

    return {
      abilityRequired,
    }
  },

  data() {
    return {
      externalUrl: 'https://lms.udutu.ca/LMSPortal/Account/Logon?orgCode=CanCoSec', // Set your external URL here
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.iframe-container {
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
