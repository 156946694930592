<template>
  <div>
    <b-card v-if="itemData && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Patrol Template Details
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Client Selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientname"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.clientname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="clientname"
                      :disabled="thePatrolname !== null"
                      @input="handleSelectClient"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.sitename"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siteOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sitename"
                      :disabled="thePatrolname !== null"
                      @input="handleSelectSite"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Patrol Name -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Patrol Name"
                  label-for="patrolname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="patrolname"
                    rules="required"
                  >
                    <b-form-input
                      id="patrolname"
                      v-model="itemData.patrolname"
                      :disabled="thePatrolname !== null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: submitted_email_list -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Submitted Email List"
                  label-for="submitted_email_list"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="submitted_email_list"
                    rules="required"
                  >
                    <b-form-input
                      id="submitted_email_list"
                      v-model="itemData.submitted_email_list"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: flagged_email_list -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Flagged Email List"
                  label-for="flagged_email_list"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="flagged_email_list"
                    rules="required"
                  >
                    <b-form-input
                      id="flagged_email_list"
                      v-model="itemData.flagged_email_list"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: patrol_in_order -->
            <b-row>
              <b-col
                md="2"
              >
                <b-form-group
                  label=""
                  label-for="patrol_in_order"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="patrol_in_order"
                  >
                    <b-form-checkbox
                      id="patrol_in_order"
                      v-model="itemData.patrol_in_order"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Patrol In Order
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- ***** Checkpoint Form ***** -->
            <b-row
              v-if="thePatrolname"
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Checkpoint</h4>
              </b-col>
            </b-row>
            <!-- New Checkpoints Form -->
            <!-- New Checkpoint Name Field -->
            <b-row
              v-if="thePatrolname"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Name"
                  label-for="checkpointName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="checkpointName"
                  >
                    <b-form-input
                      id="checkpointName"
                      v-model="checkpointName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Checkpoint pointDescription Field -->
            <b-row
              v-if="thePatrolname"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Checkpoint Description"
                  label-for="pointDescription"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pointDescription"
                  >
                    <b-form-input
                      id="pointDescription"
                      v-model="pointDescription"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: geoRequired -->
            <b-row
              v-if="thePatrolname"
            >
              <b-col
                md="2"
              >
                <b-form-group
                  label=""
                  label-for="geoRequired"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="geoRequired"
                  >
                    <b-form-checkbox
                      id="geoRequired"
                      v-model="geoRequired"
                      name="check-button"
                      switch
                      inline
                    >
                      Geolocation Required
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Checkpoint geo_location_lat Field -->
            <b-row
              v-if="thePatrolname && geoRequired"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Latitude"
                  label-for="geoLocationLat"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="geoLocationLat"
                  >
                    <b-form-input
                      id="geoLocationLat"
                      v-model="geoLocationLat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Checkpoint geo_location_long Field -->
            <b-row
              v-if="thePatrolname && geoRequired"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Longitude"
                  label-for="geoLocationLong"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="geoLocationLong"
                  >
                    <b-form-input
                      id="geoLocationLong"
                      v-model="geoLocationLong"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- New Checkpoint Buttons Field -->
            <b-row
              v-if="thePatrolname"
              class="mb-1"
            >
              <b-col
                v-if="$can('update', 'patrols')"
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleAddCheckpoint()"
                >
                  {{ checkpointBtnLable }}
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', abilityRequired)"
                md="2"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="resetCheckpointForm()"
                >
                  Clear
                </b-button>
              </b-col>
              <b-col
                v-if="$can('update', abilityRequired)"
                md="2"
              >
                <b-button
                  v-if="nfcTag"
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="button"
                  @click="handleCheckpointDelete()"
                >
                  Delete
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="thePatrolname"
              class="mt-1"
            >
              <b-col
                v-if="$can('update', abilityRequired)"
                md="12"
              >
                <span>Checkpoints in the table below will be save when you click on the Save button at the bottom of the form.</span>
              </b-col>
            </b-row>
            <!-- ***** Checkpoints List ***** -->
            <b-row
              v-if="thePatrolname"
              class="mt-2 mb-1 pt-1 border-top-secondary"
            >
              <b-col
                md="12"
              >
                <h4>Checkpoints List</h4>
              </b-col>
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-center
                  variant="primary"
                >
                  Reorder List
                </b-button>
              </b-col>
            </b-row>
            <!-- Checkpoints table -->
            <b-table
              v-if="thePatrolname"
              ref="checkpointsTable"
              class="position-relative"
              :items="itemData.checkpoints"
              responsive
              :fields="checkpointsTableColumns"
              primary-key="nfc_tag"
              show-empty
              empty-text=""
              style="min-height: 10rem;"
            >
              <!-- Column: Name -->
              <template #cell(name)="data">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="handleCheckpointSelect(data.item.nfc_tag)"
                >
                  {{ data.item.name }}
                </b-link>
              </template>
            </b-table>
            <!-- Pagination -->
            <b-row
              v-if="thePatrolname"
            >
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              />
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-right justify-content-right justify-content-sm-end"
              >
                <b-pagination
                  v-model="checkpointsCurrentPage"
                  :total-rows="totalCheckpoints"
                  :per-page="checkpointsperPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
            <!-- modal vertical center -->
            <b-modal
              id="modal-center"
              centered
              title="Vertically Centered"
              ok-only
              ok-title="Done"
            >
              <draggable
                v-model="itemData.checkpoints"
                class="list-group list-group-flush cursor-move"
                tag="ul"
                @end="onReorder"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <b-list-group-item
                    v-for="listItem in itemData.checkpoints"
                    :key="listItem.nfc_tag"
                    tag="li"
                  >
                    <div class="d-flex">
                      <div class="ml-25">
                        <b-card-text class="font-weight-bold mb-0">
                          {{ listItem.name }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-list-group-item>
                </transition-group>
              </draggable>
            </b-modal>
            <!-- Submit Button -->
            <div
              v-if="thePatrolname === null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Add {{ itemTypeName }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Buttons -->
            <div
              v-if="thePatrolname !== null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="allowPrinting && $can('update', abilityRequired)"
                  md="2"
                >
                  <!-- Print Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="printItem"
                  >
                    Print {{ itemTypeName }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="allowApproval && $can('delete', abilityRequired)"
                class="mt-4"
              >
                <b-col
                  v-if="!itemData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Files -->
    <b-card v-if="itemData && thePatrolname !== null && maxFiles > 0 && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Files
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('create', abilityRequired)"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openFileSelector"
              >
                Add File
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- File List -->
        <section
          v-if="itemData.attachment"
          class="grid-view"
        >
          <b-card>
            <b-card-footer
              v-if="itemData.attachment && itemData.attachment.access_url && itemData.attachment.filename"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    v-if="$can('read', abilityRequired)"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="downloadFile(itemData.attachment)"
                  >
                    Download File
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    v-if="$can('delete', abilityRequired)"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteFile(itemData.attachment)"
                  >
                    Delete File
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="fileInput"
          type="file"
          accept="*/*"
          style="display: none;"
          @change="handleFileChange"
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardFooter, BFormCheckbox, BTable, BPagination, BLink,
  BListGroupItem, BCardText,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import { onUnmounted, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import draggable from 'vuedraggable'
import storeModule from '../storeModule'
import clientStoreModule from '../../clients/clientStoreModule'
import useList from './useCheckPointsList'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BCardFooter,
    BFormCheckbox,
    vSelect,
    BTable,
    BPagination,
    BLink,
    draggable,
    BListGroupItem,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
    }
  },
  setup() {
    const toast = useToast()
    const abilityRequired = 'patrols'
    const storeModuleName = 'app-patrols'
    const itemTypeName = 'Patrol Template'
    const printTitle = 'Patrols'
    const allowPrinting = false
    const allowApproval = false
    const filesDir = 'company/patrols'
    const maxFiles = 0 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 0 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    const clientsData = ref([])
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])

    let thePatrolname = null
    let clientsite = null

    const checkpointBtnLable = 'Add Checkpoint'
    const checkpointProcessType = 'Adding'
    const geoRequired = false
    const checkpointName = ref(null)
    const pointDescription = ref(null)
    const checkpointOrder = ref(null)
    const geoLocationLat = ref(null)
    const geoLocationLong = ref(null)
    const docsAttachmentList = ref(null)
    const nfcTag = ref(null)

    // const itemData = { checkpoints: [] }
    const itemData = ref(null)

    if (router.currentRoute.params.patrolname !== undefined && router.currentRoute.params.patrolname !== null
      && router.currentRoute.params.clientsite !== undefined && router.currentRoute.params.clientsite !== null) {
      console.log('Found Params in route')
      thePatrolname = router.currentRoute.params.patrolname
      clientsite = router.currentRoute.params.clientsite
    } else {
      const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
      // Register module
      if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
      })

      // Get client list
      if (ability.can('read', 'clients')) {
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Client List',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          forceRefresh: false,
          q: null,
          checkpointsperPage: 0,
          page: 1,
          sortBy: 'display_name',
          sortDesc: true,
          status: null,
          service: null,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClients', qvars)
          .then(response => {
            clientsData.value = response.data
            clientsData.value.clients.forEach(item => {
              clientOptions.value.push({ label: item.display_name, value: item.clientname })
            })
            console.log('clientsData')
            console.log(clientsData)
            console.log('clientOptions')
            console.log(clientOptions)
          })
          .catch(error => {
            console.log(error) // No current user
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              clientsData.value = undefined
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching clients list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    // Get Client Sites function - used when a client is selected
    function getClientSites(clientname) {
      console.log(clientname)
      console.log('fetchClientSites')
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Site List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      if (ability.can('read', 'clients')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          checkpointsperPage: 0,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            console.log(response)
            console.log(sitesData.value)
            sitesData.value = response.data
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            siteOptions.value.unshift({ label: '<select>', value: '' })
            console.log('this.sitesData')
            console.log(sitesData)
            console.log('this.siteOptions')
            console.log(siteOptions)
          })
          .catch(error => {
            console.log(error)
            store.state.clients.fetchingClientSitesList = false
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Client Sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const {
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useList(itemData)

    return {
      abilityRequired,
      storeModuleName,
      itemTypeName,
      allowPrinting,
      allowApproval,
      printTitle,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
      thePatrolname,
      clientsite,

      clientsData,
      clientOptions,
      siteOptions,
      sitesData,
      getClientSites,

      checkpointBtnLable,
      checkpointProcessType,
      geoRequired,
      checkpointName,
      pointDescription,
      checkpointOrder,
      geoLocationLat,
      geoLocationLong,
      docsAttachmentList,
      nfcTag,

      itemData,
      fetchListItems,
      checkpointsTableColumns,
      checkpointsperPage,
      checkpointsCurrentPage,
      totalCheckpoints,
      theList,
      theListComplete,
      dataMeta,
      checkpointsperPageOptions,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }
  },
  created() {
    // Get the item details if patrolname is passed in
    console.log()
    if (this.thePatrolname === null) {
      this.itemData = {}
      this.itemData.patrolname = null
      this.itemData.checkpoints = []
    } else {
      this.getExistingItemData()
    }
  },
  methods: {
    generateUUID() {
      return crypto.randomUUID()
    },
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.thePatrolname !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          patrolname: this.thePatrolname,
          clientsite: this.clientsite,
        }
        store.dispatch(`${this.storeModuleName}/fetchTemplate`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    handleSelectClient(val) {
      console.log('handleSelectClient')
      console.log(val)
      this.clientsData.clients.forEach(item => {
        if (item.clientname === val) {
          this.itemData.client_display_name = item.display_name
        }
      })
      this.getClientSites(val)
    },
    handleSelectSite(val) {
      console.log('handleSelectClientSite')
      console.log(val)
    },
    validateForm() {
      console.log('validateForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (this.thePatrolname === null && ability.can('create', this.abilityRequired)) {
        console.log('adding template')
        processType = 'add'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/addTemplate`
        toastText = 'Add'
      } else if (this.thePatrolname !== null && ability.can('update', this.abilityRequired)) {
        console.log('editing template')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/updateTemplate`
        toastText = 'Updat'
      }
      // Additional Manual Validation
      if (typeof this.itemData.patrol_in_order !== 'boolean') {
        this.itemData.patrol_in_order = false
      }

      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.itemData.SK = response.SK
                    this.itemData.clientsite = response.clientsite
                    this.thePatrolname = this.itemData.patrolname
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.itemTypeName} ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing ${this.itemTypeName}.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    handleAddCheckpoint() {
      console.log('handleAddCheckpoint')
      console.log(this.checkpointName)
      console.log(this.pointDescription)
      console.log(this.geoRequired)
      if ((this.checkpointName !== null
        && this.pointDescription !== null)
        && (this.geoRequired === false || (this.geoRequired === true
        && this.geo_location_lat !== null && this.geo_location_long !== null
        ))) {
        let tmp = {}
        if (this.geoRequired === true) {
          tmp = {
            checkpoint_order: this.checkpointOrder,
            name: this.checkpointName,
            point_description: this.pointDescription,
            geo_required: this.geoRequired,
            geo_location_lat: this.geoLocationLat,
            geo_location_long: this.geoLocationLong,
            docs_attachment_list: this.docsAttachmentList,
            nfc_tag: this.nfcTag,
          }
        } else {
          tmp = {
            checkpoint_order: this.checkpointOrder,
            name: this.checkpointName,
            point_description: this.pointDescription,
            geo_required: this.geoRequired,
            docs_attachment_list: this.docsAttachmentList,
            nfc_tag: this.nfcTag,
          }
        }
        // Add to or edit checkpoint to list
        if (this.checkpointProcessType === 'Adding') {
          this.checkpointOrder = this.itemData.checkpoints.length + 1
          tmp.checkpoint_order = this.checkpointOrder
          this.nfcTag = this.generateUUID()
          tmp.nfc_tag = this.nfcTag
          this.itemData.checkpoints.push(tmp)
          this.checkpointBtnLable = 'Edit Checkpoint'
          this.checkpointProcessType = 'Editing'
        } else if (this.checkpointProcessType === 'Editing') {
          this.itemData.checkpoints.forEach((item, index) => {
            if (item.nfc_tag === tmp.nfc_tag) {
              this.itemData.checkpoints[index] = tmp
              this.itemData.checkpoints.push({})
              this.itemData.checkpoints.pop()
            }
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error Adding Checkpoint. Please provide all information.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      console.log(this.itemData)
    },
    handleCheckpointSelect(nfc) {
      console.log('handleCheckpointSelect')
      console.log(nfc)
      // Loop through the list of Checkpoints to find the selected one
      this.itemData.checkpoints.forEach(item => {
        if (item.nfc_tag === nfc) {
          this.checkpointOrder = item.checkpoint_order
          this.checkpointName = item.name
          this.pointDescription = item.point_description
          this.nfcTag = nfc
          this.docsAttachmentList = item.docs_attachment_list
          this.geoRequired = item.geo_required
          if (this.geoRequired) {
            this.geoLocationLat = item.geo_location_lat
            this.geoLocationLong = item.geo_location_long
          }
          this.checkpointBtnLable = 'Edit Checkpoint'
          this.checkpointProcessType = 'Editing'
        }
      })
    },
    handleCheckpointDelete() {
      this.itemData.checkpoints = this.itemData.checkpoints.filter(item => item.nfc_tag !== this.nfcTag)
      this.resetCheckpointForm()
    },
    onReorder() {
      console.log('Reordered items')
      const finalcheckpoints = []
      this.itemData.checkpoints.forEach((item, index) => {
        const tmp = item
        tmp.checkpoint_order = index + 1
        finalcheckpoints.push(tmp)
      })
      this.itemData.checkpoints = finalcheckpoints
    },
    resetCheckpointForm() {
      console.log('resetCheckpointForm')
      this.checkpointOrder = null
      this.checkpointName = null
      this.pointDescription = null
      this.geoLocationLat = null
      this.geoLocationLong = null
      this.docsAttachmentList = null
      this.nfcTag = null
      this.checkpointBtnLable = 'Add Checkpoint'
      this.checkpointProcessType = 'Adding'
    },
    openCamera() {
      // Trigger the file input click event
      this.$refs.cameraInput.click()
    },
    openImageSelector() {
      // Trigger the file input click event
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          const theFileName = `${this.thePatrolname.replace(' ', '')}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.jpg`
          const theFullFileName = `patrols/${this.theClientname.replace(' ', '')}/${this.theSitename.replace(' ', '')}/${theFileName}`
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              this.itemData.attachment_list.push({
                filename: theFileName,
                access_url: result.key,
                original_ext: fileExtension,
              })
              this.validateForm()
            })
            .catch(err => {
              console.log(err)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(item) {
      console.log('deleteimage')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
.sortable-chosen {
  background-color: #f8f9fa;
  cursor: grabbing;
}

.sortable-drag {
  opacity: 0.8;
}
.draggable-row {
  cursor: grab;
}
</style>
