<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Reports
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  @click="goBack"
                >
                  Reports
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Alarm Response Report
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="reportData && clientOptions && ($can('read', abilityRequired) || $can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Report Details
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- User Info: Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Standard Hidden Fields -->
            <input
              v-model="reportData.report_type"
              type="hidden"
              name="report_type"
            >
            <input
              v-model="reportData.time_zone"
              type="hidden"
              name="time_zone"
            >
            <input
              v-model="reportData.client_display_name"
              type="hidden"
              name="client_display_name"
            >
            <input
              v-model="reportData.attachment_list"
              type="hidden"
              name="attachment_list"
            >
            <!-- Field: Client Selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client"
                  label-for="clientname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="clientname"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.clientname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="clientname"
                      @input="handleSelectClient"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Site selection -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site"
                  label-for="sitename"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sitename"
                    rules="required"
                  >
                    <v-select
                      v-model="reportData.sitename"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siteOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sitename"
                      @input="handleSelectSite"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Site Address"
                  label-for="site_address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="site_address"
                    rules="required"
                  >
                    <b-form-input
                      id="site_address"
                      v-model="reportData.site_address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Date and Time Received -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Date and Time Received"
                  label-for="received_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="received_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="received_date"
                      v-model="reportData.received_date"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Arrival and Departure -->
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group
                  label="Arrival"
                  label-for="arrival_time"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="arrival_time"
                    rules="required"
                  >
                    <b-form-timepicker
                      id="arrival_time"
                      v-model="reportData.arrival_time"
                      class="form-control"
                      locale="en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Email -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Departure"
                  label-for="departure_time"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="departure_time"
                    rules="required"
                  >
                    <b-form-timepicker
                      id="departure_time"
                      v-model="reportData.departure_time"
                      class="form-control"
                      locale="en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Keys Boxes -->
            <b-row>
              <b-col
                md="2"
              >
                <b-form-group
                  label=""
                  label-for="is_keys_used"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_keys_used"
                  >
                    <b-form-checkbox
                      id="is_keys_used"
                      v-model="reportData.is_keys_used"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Keys Used
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                v-if="reportData.is_keys_used"
                md="4"
              >
                <b-form-group
                  label="Key# Box#"
                  label-for="keyNumBoxNum"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="keyNumBoxNum"
                  >
                    <b-form-input
                      id="keyNumBoxNum"
                      v-model="reportData.keyNumBoxNum"
                      placeholder="Key#, Box#"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Alarm Type -->
            <b-row
              class="mt-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Alarm Type"
                  label-for="alarm_type_list"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="alarm_type_list"
                  >
                    <div>
                      <b-form-checkbox
                        id="hold_up"
                        v-model="reportData.alarm_type_list.hold_up"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Hold Up
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="panic_duress"
                        v-model="reportData.alarm_type_list.panic_duress"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Panic/Duress
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="improper_open_close"
                        v-model="reportData.alarm_type_list.improper_open_close"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Improper Open/Close
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="day_night_early"
                        v-model="reportData.alarm_type_list.day_night_early"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Day / Night Early
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="day_night_late"
                        v-model="reportData.alarm_type_list.day_night_late"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Day / Night Late
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="safe_vault"
                        v-model="reportData.alarm_type_list.safe_vault"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Safe Vault
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="perimeter"
                        v-model="reportData.alarm_type_list.perimeter"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Perimeter
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="interior"
                        v-model="reportData.alarm_type_list.interior"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Interior
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="critical_function"
                        v-model="reportData.alarm_type_list.critical_function"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Critical Function
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="fire"
                        v-model="reportData.alarm_type_list.fire"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Fire
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="fire_trouble"
                        v-model="reportData.alarm_type_list.fire_trouble"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Fire Trouble
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="glass_break"
                        v-model="reportData.alarm_type_list.glass_break"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Glass Break
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Alarm Type Other -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Alarm Type Other"
                  label-for="alarm_type_list_other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="alarm_type_list_other"
                  >
                    <b-form-input
                      id="alarm_type_list_other"
                      v-model="reportData.alarm_type_list_other"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Dispatch Request Type -->
            <b-row
              class="mt-2 mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Dispatch Request Type"
                  label-for="dispatch_request_type_list"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="dispatch_request_type_list"
                  >
                    <div>
                      <b-form-checkbox
                        id="crisis"
                        v-model="reportData.dispatch_request_type_list.crisis"
                        name="dispatch_request_type_list"
                        checked="true"
                        switch
                        inline
                        class="mt-2"
                      >
                        Crisis
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="elevator_entrapment"
                        v-model="reportData.dispatch_request_type_list.elevator_entrapment"
                        name="dispatch_request_type_list"
                        checked="true"
                        switch
                        inline
                        class="mt-2"
                      >
                        Elevator Entrapment
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="general_assist"
                        v-model="reportData.dispatch_request_type_list.general_assist"
                        name="dispatch_request_type_list"
                        checked="true"
                        switch
                        inline
                        class="mt-2"
                      >
                        General Assist
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="general_admit"
                        v-model="reportData.dispatch_request_type_list.general_admit"
                        name="dispatch_request_type_list"
                        checked="true"
                        switch
                        inline
                        class="mt-2"
                      >
                        General Admit
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="secure_transport_delivery"
                        v-model="reportData.dispatch_request_type_list.secure_transport_delivery"
                        name="dispatch_request_type_list"
                        checked="true"
                        switch
                        inline
                        class="mt-2"
                      >
                        Secure Transport/Delivery
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Requested By -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Requested By"
                  label-for="requested_by"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="requested_by"
                    rules="required"
                  >
                    <b-form-input
                      id="requested_by"
                      v-model="reportData.requested_by"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Alarm Zone & Description -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Alarm Zone & Description"
                  label-for="alarm_zone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="alarm_zone"
                  >
                    <b-form-input
                      id="alarm_zone"
                      v-model="reportData.alarm_zone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Service Dispatched -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="2"
              >
                <b-form-group
                  label="Service Dispatched"
                  label-for="is_service_dispatched"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_service_dispatched"
                  >
                    <b-form-checkbox
                      id="is_service_dispatched"
                      v-model="reportData.is_service_dispatched"
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      class="mt-1"
                    >
                      (No / Yes)
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Site Address -->
              <b-col
                md="2"
              >
                <b-form-group
                  label="Service Call #"
                  label-for="service_call_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="service_call_number"
                  >
                    <b-form-input
                      id="service_call_number"
                      v-model="reportData.service_call_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Action Taken -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Action Taken"
                  label-for="action_taken"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="action_taken"
                    rules="required"
                  >
                    <b-form-textarea
                      id="action_taken"
                      v-model="reportData.action_taken"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Comments / Recommendations -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Comments / Recommendations"
                  label-for="comments"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="comments"
                    rules="required"
                  >
                    <b-form-textarea
                      id="comments"
                      v-model="reportData.comments"
                      rows="3"
                      max-rows="8"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Result -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Result"
                  label-for="alarm_status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="result"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="reportData.alarm_status"
                      :options="resultOptions"
                      name="radios-stacked"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Subscriber -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="2"
              >
                <b-form-group
                  label="Subscriber"
                  label-for="is_subscriber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_subscriber"
                  >
                    <b-form-checkbox
                      id="is_subscriber"
                      v-model="reportData.is_subscriber"
                      checked="true"
                      name="check-button"
                      switch
                      inline
                      class="mt-1"
                    >
                      (Yes / No)
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Name -->
              <b-col
                md="4"
              >
                <b-form-group
                  label="Name"
                  label-for="subscriber_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="subscriber_name"
                  >
                    <b-form-input
                      id="subscriber_name"
                      v-model="reportData.subscriber_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Fire Dept -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Fire Department"
                  label-for="fire_department"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fire_department"
                  >
                    <b-form-input
                      id="fire_department"
                      v-model="reportData.fire_department"
                      placeholder="Toronto Pumper #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Name -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Police Service Badge #'S -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Police Service Badge #'S"
                  label-for="police_department"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="police_department"
                  >
                    <b-form-input
                      id="police_department"
                      v-model="reportData.police_department"
                      placeholder="Service Name, Badge #'s"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Name -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Other Names -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Other Names"
                  label-for="other_names"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="other_names"
                  >
                    <b-form-input
                      id="other_names"
                      v-model="reportData.other_names"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Name -->
              <b-col
                md="6"
              >
                <!--  -->
              </b-col>
            </b-row>
            <!-- Field: Report To Client -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Report To Client"
                  label-for="report_to_client_list"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="report_to_client_list"
                  >
                    <div>
                      <b-form-checkbox
                        id="report_to_client_list_mail"
                        v-model="reportData.report_to_client_list.mail"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Mail
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="report_to_client_list_email"
                        v-model="reportData.report_to_client_list.email"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Email
                      </b-form-checkbox>
                    </div>
                    <div>
                      <b-form-checkbox
                        id="report_to_client_list_left_on_site"
                        v-model="reportData.report_to_client_list.left_on_site"
                        checked="true"
                        name="check-button"
                        switch
                        inline
                        class="mt-2"
                      >
                        Left On Site
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Submit Button -->
            <div
              v-if="reportID === null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Add Report
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Buttons -->
            <div
              v-if="reportID !== null"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="reportData && clientOptions && $can('update', abilityRequired) && reportData.username === userData.username"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <!-- Print Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="printReport"
                  >
                    Print Report
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="$can('delete', abilityRequired)"
                class="mt-4"
              >
                <b-col
                  v-if="!reportData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Photos and Images -->
    <b-card v-if="reportData && reportID !== null && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Photos / Images
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('update', abilityRequired)"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openCamera"
              >
                Take Picture
              </b-button>
            </b-col>
            <b-col
              v-if="$can('update', abilityRequired)"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openImageSelector"
              >
                Add Image
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- Images List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <div
              v-if="mediaItem.filename.slice(-3) === 'jpg'"
              class="item-img text-center"
            >
              <thumbs-lazy-image-component
                class="card-img-top"
                :src="mediaItem.access_url"
                src-placeholder="@/assets/images/noImage.png"
              />
            </div>

            <b-card-footer
              v-if="mediaItem.filename.slice(-3) === 'jpg'"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteImage(mediaItem)"
                  >
                    Delete Image
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style="display: none;"
          @change="handleImageChange"
        >
        <input
          ref="imageInput"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="handleImageChange"
        >
      </b-card-body>
    </b-card>
    <!-- Files -->
    <b-card v-if="reportData && reportID !== null && ($can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Files
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('update', abilityRequired)"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openFileSelector"
              >
                Add File
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- File List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <b-card-footer
              v-if="mediaItem.filename.slice(-3) !== 'jpg'"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="downloadFile(mediaItem)"
                  >
                    Download File
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteFile(mediaItem)"
                  >
                    Delete File
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="fileInput"
          type="file"
          accept="*/*"
          style="display: none;"
          @change="handleFileChange"
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required, email } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTimepicker, BFormCheckbox, BFormTextarea, BFormRadioGroup, BCardFooter,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { getUserData } from '@/auth/utils'
import reportsStoreModule from '../reportsStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import ThumbsLazyImageComponent from '../thumbsLazyImageComponent'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormTimepicker,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    ThumbsLazyImageComponent,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      preventDoubleClick: false,
    }
  },
  setup() {
    const userData = getUserData()
    const abilityRequired = 'reports'
    let theReportID = null
    let theSK = null
    let theReportType = null
    if (router.currentRoute.params.reportID !== undefined && router.currentRoute.params.reportID !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null
      && router.currentRoute.params.report_type !== undefined && router.currentRoute.params.report_type !== null) {
      theReportID = router.currentRoute.params.reportID
      theSK = router.currentRoute.params.SK
      theReportType = router.currentRoute.params.report_type
    }
    const reportID = ref(theReportID)
    const SK = ref(theSK)
    const reportType = ref(theReportType)
    const reportData = ref(null)
    const clientsData = ref([])
    // TODO: Get a list of clients available to the current user
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    const resultOptions = store.state.appSettings.alarm_response_report_result_options
    const isSubscriberOptions = [{ text: 'Yes', value: 'Yes' }, { text: 'No', value: 'No' }]

    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    if (ability.can('read', 'clients')) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Client List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 0,
        page: 1,
        sortBy: 'display_name',
        sortDesc: true,
        status: null,
        service: null,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          clientsData.value = response.data
          clientsData.value.clients.forEach(item => {
            clientOptions.value.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsData')
          console.log(clientsData)
          console.log('clientOptions')
          console.log(clientOptions)
        })
        .catch(error => {
          console.log(error) // No current user
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientsData.value = undefined
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function getClientSites(clientname) {
      console.log(clientname)
      console.log('fetchClientSites')
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Site List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      if (ability.can('read', 'clients')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          perPage: 0,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            console.log(response)
            console.log(sitesData.value)
            sitesData.value = response.data
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            siteOptions.value.unshift({ label: '<select>', value: '' })
            console.log('this.sitesData')
            console.log(sitesData)
            console.log('this.siteOptions')
            console.log(siteOptions)
          })
          .catch(error => {
            console.log(error)
            store.state.clients.fetchingClientSitesList = false
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Client Sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    return {
      userData,
      abilityRequired,

      reportID,
      SK,
      reportType,
      reportData,
      clientsData,
      clientOptions,
      siteOptions,
      sitesData,
      resultOptions,
      isSubscriberOptions,
      getClientSites,
    }
  },
  created() {
    if (this.reportID === null) {
      this.reportData = {}
      this.reportData.report_type = 'alarm_response'
      this.reportData.time_zone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      this.reportData.client_display_name = ''
      this.reportData.received_date = null
      this.reportData.attachment_list = []
      this.reportData.alarm_type_list = {}
      this.reportData.dispatch_request_type_list = {}
      this.reportData.report_to_client_list = {}
      this.reportData.sitename = ''
    } else {
      this.getExistingReport()
    }

    // watch(this.siteOptions, newValue => {
    //   console.log('siteOptions changed:', newValue)
    // }, { deep: true })

    // watch(() => this.reportData.sitename, newValue => {
    //   console.log('Selected sitename:', newValue)
    // })
  },
  methods: {
    goBack() {
      console.log('goback')
      window.history.back()
    },
    handleSelectClient(val) {
      console.log('handleSelectClient')
      this.clientsData.clients.forEach(item => {
        if (item.clientname === val) {
          this.reportData.client_display_name = item.display_name
        }
      })
      // Get a list of sites for the selected client
      this.getClientSites(val)
    },
    handleSelectSite(val) {
      console.log(val)
      // TODO: Get a list of Sites for the selected Client
      this.sitesData.forEach(item => {
        if (item.sitename === val) {
          this.reportData.site_address = `${item.sitename}, ${item.province}, ${item.country}`
        }
      })
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getExistingReport() {
      console.log('getExistingReport')
      if (this.reportID !== null && this.SK !== null && this.reportType !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting report data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Report Data',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          reportID: this.reportID,
          SK: this.SK,
          reportType: this.reportType,
        }
        store.dispatch('app-reports/fetchReport', qvars)
          .then(response => {
            console.log(response)
            this.reportData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Getting Report.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      if (this.preventDoubleClick === false) {
        if (this.reportID === null) {
          if (ability.can('create', this.abilityRequired)) {
            console.log('adding report')
            let proceedWithSave = true
            // Manual Validation Items
            this.reportData.received_date = moment(this.reportData.received_date).format('YYYY-MM-DD HH:mm:ss')
            if (this.reportData.is_keys_used === undefined) {
              this.reportData.is_keys_used = false
            }
            if (this.reportData.keyNumBoxNum === undefined) {
              this.reportData.keyNumBoxNum = ''
            }
            if (this.reportData.alarm_type_list === undefined) {
              this.reportData.alarm_type_list = {}
            }
            if (this.reportData.alarm_type_list_other === undefined) {
              this.reportData.alarm_type_list_other = ''
            }
            if (this.reportData.dispatch_request_type_list === undefined) {
              this.reportData.dispatch_request_type_list = {}
            }
            if (this.reportData.alarm_zone === undefined) {
              this.reportData.alarm_zone = ''
            }
            if (this.reportData.is_service_dispatched === undefined) {
              this.reportData.is_service_dispatched = false
            }
            if (this.reportData.service_call_number === undefined) {
              this.reportData.service_call_number = ''
            }
            if (this.reportData.is_subscriber === undefined) {
              this.reportData.is_subscriber = false
            }
            if (this.reportData.subscriber_name === undefined) {
              this.reportData.subscriber_name = ''
            }
            if (this.reportData.fire_department === undefined) {
              this.reportData.fire_department = ''
            }
            if (this.reportData.police_department === undefined) {
              this.reportData.police_department = ''
            }
            if (this.reportData.other_names === undefined) {
              this.reportData.other_names = ''
            }
            if (this.reportData.report_to_client_list === undefined) {
              this.reportData.report_to_client_list = {}
            }
            proceedWithSave = true
            console.log(this.reportData)
            console.log(JSON.parse(JSON.stringify(this.reportData)))
            if (proceedWithSave) {
              this.$refs.observer.validate().then(success => {
                if (success) {
                  this.preventDoubleClick = true
                  console.log('form validated')
                  console.log(this.reportData)
                  // Create a clean dataset with only the data being updated
                  const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                  // delete cleanReportData.abilities
                  // delete cleanReportData.authorized_clients
                  console.log(cleanReportData)
                  // Call backend
                  store.dispatch('app-reports/addReport', cleanReportData)
                    .then(response => {
                      this.preventDoubleClick = false
                      console.log(response)
                      // TODO Need to set the reportID based on the response
                      this.reportID = response.report_id
                      this.reportData.report_id = response.report_id
                      this.reportData.SK = response.SK
                      this.reportData.approved = response.approved
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Report Added',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(error => {
                      console.log(error)
                      this.preventDoubleClick = false
                      let theErrorStr = 'The following error(s) where received:\n'
                      if (typeof error.response.data.errors !== 'undefined') {
                        error.response.data.errors.forEach(item => {
                          theErrorStr += `${item.message}\n`
                        })
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error Adding Report.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                          text: theErrorStr,
                        },
                      })
                    })
                }
              })
            }
          }
        } else if (ability.can('update', this.abilityRequired)) {
          console.log('updating report')
          let proceedWithSave = true
          // Manual Validation Items
          this.reportData.received_date = moment(this.reportData.received_date).format('YYYY-MM-DDTHH:mm:ss')
          if (this.reportData.is_keys_used === undefined) {
            this.reportData.is_keys_used = false
          }
          if (this.reportData.keyNumBoxNum === undefined) {
            this.reportData.keyNumBoxNum = 'false'
          }
          if (this.reportData.alarm_type_list === undefined) {
            this.reportData.alarm_type_list = {}
          }
          if (this.reportData.alarm_type_list_other === undefined) {
            this.reportData.alarm_type_list_other = ''
          }
          if (this.reportData.dispatch_request_type_list === undefined) {
            this.reportData.dispatch_request_type_list = {}
          }
          if (this.reportData.alarm_zone === undefined) {
            this.reportData.alarm_zone = ''
          }
          if (this.reportData.is_service_dispatched === undefined) {
            this.reportData.is_service_dispatched = 'false'
          }
          if (this.reportData.service_call_number === undefined) {
            this.reportData.service_call_number = 'false'
          }
          if (this.reportData.is_subscriber === undefined) {
            this.reportData.is_subscriber = 'false'
          }
          if (this.reportData.subscriber_name === undefined) {
            this.reportData.subscriber_name = ''
          }
          if (this.reportData.fire_department === undefined) {
            this.reportData.fire_department = ''
          }
          if (this.reportData.police_department === undefined) {
            this.reportData.police_department = ''
          }
          if (this.reportData.other_names === undefined) {
            this.reportData.other_names = ''
          }
          if (this.reportData.report_to_client_list === undefined) {
            this.reportData.report_to_client_list = {}
          }
          proceedWithSave = true
          console.log(this.reportData)
          console.log(JSON.parse(JSON.stringify(this.reportData)))
          if (proceedWithSave) {
            this.$refs.observer.validate().then(success => {
              if (success) {
                this.preventDoubleClick = true
                console.log('form validated')
                console.log(this.reportData)
                // Create a clean dataset with only the data being updated
                const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                // delete cleanReportData.abilities
                // delete cleanReportData.authorized_clients
                console.log(cleanReportData)
                // Call backend
                store.dispatch('app-reports/updateReport', cleanReportData)
                  .then(response => {
                    console.log(response)
                    this.preventDoubleClick = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Report Updated',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.preventDoubleClick = false
                    let theErrorStr = 'The following error(s) where received:\n'
                    if (typeof error.response.data.errors !== 'undefined') {
                      error.response.data.errors.forEach(item => {
                        theErrorStr += `${item.message}\n`
                      })
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error Updating Report.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: theErrorStr,
                      },
                    })
                  })
              }
            })
          }
        }
      }
    },
    async printReport() {
      const printContent = await this.generatePrintContent()
      console.log('printContent: ')
      console.log(printContent)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(printContent)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 2000)
    },
    async getImageForPrint() {
      // Check if there are any attachments
      if (!this.reportData.attachment_list.length) {
        return ''
      }

      // Prepare an array of promises for fetching the images
      const imagePromises = this.reportData.attachment_list.map(async item => {
        const result1 = await Storage.get(item.access_url, {
          download: false,
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })

        console.log(result1)
        return `<div><img src='${result1}'/><br><br></div>`
      })

      // Wait for all image promises to resolve
      const imageHTMLArray = await Promise.all(imagePromises)

      // Join the HTML snippets into a single string
      const imageHTML = imageHTMLArray.join('')

      console.log(imageHTML)
      return imageHTML
    },
    async generatePrintContent() {
      const imageHTML = await this.getImageForPrint()
      return `
      <html>
        <head>
          <title>Alarm Response Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            h2 {
              text-align: center;
            }
            .logo {
              width: 300px;
              margin-bottom: 20px;
            }
            .section {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 20px;
            }
            .section .label {
              width: 30%;
              font-weight: bold;
            }
            .section .value {
              width: 70%;
            }
            .section ul {
              list-style: none;
              padding: 0;
            }
            .section ul li {
              margin: 5px 0;
            }
          </style>
        </head>
        <body>
          <img src="/img/email-logo.png" class="logo" alt="Cancom">
          <h2>Alarm Response Report</h2>
          <div class="section">
            <div class="label">Client:</div>
            <div class="value">${this.getClientDisplayName(this.reportData.clientname)}</div>
          </div>
          <div class="section">
            <div class="label">Site:</div>
            <div class="value">${this.reportData.sitename}</div>
          </div>
          <div class="section">
            <div class="label">Site Address:</div>
            <div class="value">${this.reportData.site_address}</div>
          </div>
          <div class="section">
            <div class="label">Date and Time Received:</div>
            <div class="value">${this.reportData.received_date}</div>
          </div>
          <div class="section">
            <div class="label">Arrival Time:</div>
            <div class="value">${this.reportData.arrival_time}</div>
          </div>
          <div class="section">
            <div class="label">Departure Time:</div>
            <div class="value">${this.reportData.departure_time}</div>
          </div>
          ${this.reportData.is_keys_used ? `
            <div class="section">
              <div class="label">Keys Used:</div>
              <div class="value">Yes</div>
            </div>
            <div class="section">
              <div class="label">Key# Box#:</div>
              <div class="value">${this.reportData.keyNumBoxNum}</div>
            </div>` : ''}
          <div class="section">
            <div class="label">Alarm Types:</div>
            <div class="value">
              <ul>
                ${this.reportData.alarm_type_list.hold_up ? '<li>Hold Up</li>' : ''}
                ${this.reportData.alarm_type_list.panic_duress ? '<li>Panic/Duress</li>' : ''}
                ${this.reportData.alarm_type_list.improper_open_close ? '<li>Improper Open/Close</li>' : ''}
                ${this.reportData.alarm_type_list.day_night_early ? '<li>Day/Night Early</li>' : ''}
                ${this.reportData.alarm_type_list.day_night_late ? '<li>Day/Night Late</li>' : ''}
                ${this.reportData.alarm_type_list.safe_vault ? '<li>Safe Vault</li>' : ''}
                ${this.reportData.alarm_type_list.perimeter ? '<li>Perimeter</li>' : ''}
                ${this.reportData.alarm_type_list.interior ? '<li>Interior</li>' : ''}
                ${this.reportData.alarm_type_list.critical_function ? '<li>Critical Function</li>' : ''}
                ${this.reportData.alarm_type_list.fire ? '<li>Fire</li>' : ''}
                ${this.reportData.alarm_type_list.fire_trouble ? '<li>Fire Trouble</li>' : ''}
                ${this.reportData.alarm_type_list.glass_break ? '<li>Glass Break</li>' : ''}
              </ul>
            </div>
          </div>
          <div class="section">
            <div class="label">Dispatch Request Types:</div>
            <div class="value">
              <ul>
                ${this.reportData.dispatch_request_type_list.crisis ? '<li>Crisis</li>' : ''}
                ${this.reportData.dispatch_request_type_list.elevator_entrapment ? '<li>Elevator Entrapment</li>' : ''}
                ${this.reportData.dispatch_request_type_list.general_assist ? '<li>General Assist</li>' : ''}
                ${this.reportData.dispatch_request_type_list.general_admit ? '<li>General Admit</li>' : ''}
                ${this.reportData.dispatch_request_type_list.secure_transport_delivery ? '<li>Secure Transport/Delivery</li>' : ''}
              </ul>
            </div>
          </div>
          <div class="section">
            <div class="label">Requested By:</div>
            <div class="value">${this.reportData.requested_by}</div>
          </div>
          <div class="section">
            <div class="label">Alarm Zone & Description:</div>
            <div class="value">${this.reportData.alarm_zone}</div>
          </div>
          <div class="section">
            <div class="label">Action Taken:</div>
            <div class="value">${this.reportData.action_taken}</div>
          </div>
          <div class="section">
            <div class="label">Comments / Recommendations:</div>
            <div class="value">${this.reportData.comments}</div>
          </div>
          <div class="section">
            <div class="label">Result:</div>
            <div class="value">${this.getResultDisplay(this.reportData.alarm_status)}</div>
          </div>
          ${this.reportData.is_subscriber ? `
            <div class="section">
              <div class="label">Subscriber:</div>
              <div class="value">Yes</div>
            </div>
            <div class="section">
              <div class="label">Subscriber Name:</div>
              <div class="value">${this.reportData.subscriber_name}</div>
            </div>` : ''}
          <div class="section">
            <div class="label">Fire Department:</div>
            <div class="value">${this.reportData.fire_department}</div>
          </div>
          <div class="section">
            <div class="label">Police Service Info:</div>
            <div class="value">${this.reportData.police_department}</div>
          </div>
          <div class="section">
            <div class="label">Other Names:</div>
            <div class="value">${this.reportData.other_names}</div>
          </div>
          <div class="section">
            <div class="label">Report To Client:</div>
            <div class="value">
              <ul>
                ${this.reportData.report_to_client_list.mail ? '<li>Mail</li>' : ''}
                ${this.reportData.report_to_client_list.email ? '<li>Email</li>' : ''}
                ${this.reportData.report_to_client_list.left_on_site ? '<li>Left On Site</li>' : ''}
              </ul>
            </div>
          </div>
          <div class="section">
            ${imageHTML}
          </div>
        </body>
      </html>
    `
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.reportData.approved) {
        this.reportData.approved = false
      } else {
        this.reportData.approved = true
      }
      this.validateForm()
    },
    getClientDisplayName(clientname) {
      const client = this.clientOptions.find(option => option.value === clientname)
      return client ? client.label : ''
    },
    getResultDisplay(result) {
      const resultOption = this.resultOptions.find(option => option.value === result)
      return resultOption ? resultOption.text : ''
    },
    openCamera() {
      // Trigger the file input click event
      this.$refs.cameraInput.click()
    },
    openImageSelector() {
      // Trigger the file input click event
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.jpg`
          const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              this.reportData.attachment_list.push({
                filename: theFileName,
                access_url: result.key,
                original_ext: fileExtension,
              })
              this.validateForm()
            })
            .catch(err => {
              console.log(err)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(item) {
      console.log('deleteimage')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            this.reportData.attachment_list.push({
              filename: theFileName,
              access_url: result.key,
              original_ext: fileExtension,
            })
            this.validateForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.download = item.filename || 'download' // you can set a default filename if item.filename is not available
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item) {
      console.log('deletefile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
