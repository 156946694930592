var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemData &&
      (_vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [
                _c("h5", [_vm._v(" Patrol Template Details ")])
              ]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clientname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.clientOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "clientname"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectClient
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .clientname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "clientname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.clientname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.siteOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "sitename"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectSite
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Address",
                                                "label-for": "site_address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "site_address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "site_address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Patrol Name",
                                                "label-for": "patrolname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "patrolname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "patrolname"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .patrolname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "patrolname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.patrolname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Submitted Email List",
                                                "label-for":
                                                  "submitted_email_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "submitted_email_list",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "submitted_email_list"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .submitted_email_list,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "submitted_email_list",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.submitted_email_list"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Flagged Email List",
                                                "label-for":
                                                  "flagged_email_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "flagged_email_list",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "flagged_email_list"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .flagged_email_list,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "flagged_email_list",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.flagged_email_list"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "",
                                                "label-for": "patrol_in_order"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "patrol_in_order"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                id:
                                                                  "patrol_in_order",
                                                                checked: "true",
                                                                "unchecked-value":
                                                                  "false",
                                                                name:
                                                                  "check-button",
                                                                switch: "",
                                                                inline: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData
                                                                    .patrol_in_order,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.itemData,
                                                                    "patrol_in_order",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "itemData.patrol_in_order"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Patrol In Order "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 pt-1 border-top-secondary"
                                    },
                                    [
                                      _c("b-col", { attrs: { md: "12" } }, [
                                        _c("h4", [_vm._v("Event Contact List")])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Name",
                                                "label-for":
                                                  "vmsnewEventPersonName"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "vmsnewEventPersonName"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vmsnewEventPersonName"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.vmsNewEventPersonName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.vmsNewEventPersonName = $$v
                                                              },
                                                              expression:
                                                                "vmsNewEventPersonName"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Phone",
                                                "label-for":
                                                  "vmsnewEventPersonPhone"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "vmsnewEventPersonPhone"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c("cleave", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "vmsnewEventPersonPhone",
                                                                  raw: false,
                                                                  options:
                                                                    _vm.options
                                                                      .phone,
                                                                  placeholder:
                                                                    "123 123 1234"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.vmsNewEventPersonPhone,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.vmsNewEventPersonPhone = $$v
                                                                  },
                                                                  expression:
                                                                    "vmsNewEventPersonPhone"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Email",
                                                "label-for":
                                                  "vmsnewEventPersonEmail"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "vmsnewEventPersonEmail",
                                                  rules: "email"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vmsnewEventPersonEmail",
                                                              type: "email"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.vmsNewEventPersonEmail,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.vmsNewEventPersonEmail = $$v
                                                              },
                                                              expression:
                                                                "vmsNewEventPersonEmail"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-1" },
                                    [
                                      _vm.$can("update", "clients")
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                  attrs: {
                                                    variant: "primary",
                                                    block: "",
                                                    type: "button"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleAddEventPerson()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.vmsAddEventPersonBtnLable
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.$can("update", "clients")
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                  attrs: {
                                                    variant: "secondary",
                                                    block: "",
                                                    type: "button"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.resetVMSEventTypeForm()
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Cancel ")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-1" },
                                    [
                                      _vm.$can("update", "clients")
                                        ? _c("b-col", { attrs: { md: "12" } }, [
                                            _c("span", [
                                              _vm._v(
                                                "Items in the table below will be save when you click on the Save Changes button at the bottom of the form."
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-table",
                                    {
                                      ref: "eventPersonTable",
                                      staticClass: "position-relative",
                                      staticStyle: { "min-height": "10rem" },
                                      attrs: {
                                        items:
                                          _vm.clientSiteData
                                            .videomonitoringservices
                                            .eventPersons,
                                        responsive: "",
                                        fields: _vm.vmsEventPersonstableColumns,
                                        "primary-key": "itemID",
                                        "show-empty": "",
                                        "empty-text": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "cell(itemID)",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-nowrap"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.itemID
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "cell(eventPersonName)",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-nowrap"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item
                                                            .eventPersonName
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "cell(eventPersonPhone)",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-nowrap"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatPhoneNumber(
                                                            data.item
                                                              .eventPersonPhone
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "cell(eventPersonEmail)",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-nowrap"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item
                                                            .eventPersonEmail
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "cell(actions)",
                                            fn: function(data) {
                                              return [
                                                _c(
                                                  "b-dropdown",
                                                  {
                                                    attrs: {
                                                      variant: "link",
                                                      "no-caret": "",
                                                      right:
                                                        _vm.$store.state
                                                          .appConfig.isRTL
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "button-content",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  staticClass:
                                                                    "align-middle text-body",
                                                                  attrs: {
                                                                    icon:
                                                                      "MoreVerticalIcon",
                                                                    size: "16"
                                                                  }
                                                                }
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm.$can(
                                                      "update",
                                                      "clients"
                                                    )
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.handleEditEventPersonFillForm(
                                                                  data.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "EditIcon"
                                                              }
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "align-middle ml-50"
                                                              },
                                                              [_vm._v("Edit")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.$can(
                                                      "delete",
                                                      "clients"
                                                    )
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.handleDeleteEventPerson(
                                                                  data.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon:
                                                                  "TrashIcon"
                                                              }
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "align-middle ml-50"
                                                              },
                                                              [_vm._v("Delete")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      [
                                        _vm._v(
                                          " No Site found for this client "
                                        )
                                      ]
                                    ],
                                    2
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center justify-content-sm-start",
                                        attrs: { cols: "12", sm: "6" }
                                      }),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-right justify-content-right justify-content-sm-end",
                                          attrs: { cols: "12", sm: "6" }
                                        },
                                        [
                                          _c("b-pagination", {
                                            staticClass: "mb-0 mt-1 mt-sm-0",
                                            attrs: {
                                              "total-rows":
                                                _vm.vmstotalEventPersons,
                                              "per-page": _vm.vmsperPage,
                                              "first-number": "",
                                              "last-number": "",
                                              "prev-class": "prev-item",
                                              "next-class": "next-item"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "prev-text",
                                                  fn: function() {
                                                    return [
                                                      _c("feather-icon", {
                                                        attrs: {
                                                          icon:
                                                            "ChevronLeftIcon",
                                                          size: "18"
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                },
                                                {
                                                  key: "next-text",
                                                  fn: function() {
                                                    return [
                                                      _c("feather-icon", {
                                                        attrs: {
                                                          icon:
                                                            "ChevronRightIcon",
                                                          size: "18"
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.vmsEventPersonsCurrentPage,
                                              callback: function($$v) {
                                                _vm.vmsEventPersonsCurrentPage = $$v
                                              },
                                              expression:
                                                "vmsEventPersonsCurrentPage"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.thePatrolname === null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Add " +
                                                              _vm._s(
                                                                _vm.itemTypeName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.thePatrolname !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.allowPrinting &&
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click: _vm.printItem
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print " +
                                                              _vm._s(
                                                                _vm.itemTypeName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.allowApproval &&
                                          _vm.$can(
                                            "delete",
                                            _vm.abilityRequired
                                          )
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.itemData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3477231458
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemData &&
      _vm.thePatrolname !== null &&
      _vm.maxFiles > 0 &&
      (_vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.$can("create", _vm.abilityRequired)
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.itemData.attachment
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      [
                        _c(
                          "b-card",
                          [
                            _vm.itemData.attachment &&
                            _vm.itemData.attachment.access_url &&
                            _vm.itemData.attachment.filename
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _vm.$can(
                                              "read",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.downloadFile(
                                                          _vm.itemData
                                                            .attachment
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Download File ")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _vm.$can(
                                              "delete",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteFile(
                                                          _vm.itemData
                                                            .attachment
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Delete File ")]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }