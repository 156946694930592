var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "inherit" } },
    [
      _vm.theAlerts !== null && _vm.theAlerts.length > 0
        ? _c(
            "swiper",
            {
              staticClass: "swiper-paginations",
              attrs: {
                options: _vm.swiperOptions,
                dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr"
              }
            },
            [
              _vm._l(_vm.theAlerts, function(item, index) {
                return _c(
                  "swiper-slide",
                  { key: index },
                  [
                    item.is_shown === true
                      ? _c(
                          "b-card",
                          [
                            _c(
                              "b-card-body",
                              { staticClass: "ml-3 mr-3 mb-2" },
                              [
                                _vm._v(" " + _vm._s(item.detail)),
                                _c("br"),
                                _c("br"),
                                _vm._v(
                                  " Created: " +
                                    _vm._s(item.creation_date) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c("div", {
                staticClass: "swiper-button-next mr-1",
                attrs: { slot: "button-next" },
                slot: "button-next"
              }),
              _c("div", {
                staticClass: "swiper-button-prev ml-1",
                attrs: { slot: "button-prev" },
                slot: "button-prev"
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "section",
        { class: _vm.itemView },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "b-card",
            {
              key: item.id,
              staticClass: "ecommerce-card",
              attrs: { "no-body": "" }
            },
            [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-link",
                    {
                      on: {
                        click: function($event) {
                          return _vm.addNewReport(item)
                        }
                      }
                    },
                    [
                      _c("b-img", {
                        staticClass: "card-img-top",
                        attrs: {
                          alt: "" + item.label,
                          fluid: "",
                          src: _vm.imageIcons[index]
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-card-body", [
                _c(
                  "h6",
                  {
                    staticClass: "item-name",
                    staticStyle: { "margin-bottom": "0.75rem" }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              ]),
              _c(
                "div",
                { staticClass: "item-options text-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-wishlist",
                      attrs: { variant: "light", tag: "a" },
                      on: {
                        click: function($event) {
                          return _vm.listReportsByType(item)
                        }
                      }
                    },
                    [_c("span", [_vm._v("List")])]
                  ),
                  _vm.$can("create", "reports")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-cart",
                          attrs: { variant: "primary", tag: "a" },
                          on: {
                            click: function($event) {
                              return _vm.addNewReport(item)
                            }
                          }
                        },
                        [_c("span", [_vm._v("Add Report")])]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }