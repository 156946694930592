<template>

  <div v-if="$can('read', abilityRequired)">

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="this.$store.state.onboarding.fetchingList"
              @click="refreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              :to="{ name: 'apps-onboarding-manage' }"
            >
              <feather-icon icon="PlusIcon" /><span class="text-nowrap ml-1">Add User</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mt-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchListItems"
        responsive
        :fields="tableColumns"
        primary-key="username"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        :force-refresh.sync="forceRefresh"
      >

        <!-- Column: User-->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-onboarding-manage', params: { username: data.item.username } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-onboarding-manage', params: { username: data.item.username } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          <div class="text-nowrap">
            {{ formatPhoneNumber(data.item.phone) }}
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BPagination, BMedia, BAvatar, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'
// import { ref, watch, onUnmounted } from '@vue/composition-api'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import { useRouter } from '@core/utils/utils'
import useList from './useList'
import storeModule from '../storeModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BMedia,
    BAvatar,
    BBadge,

    vSelect,
  },
  setup() {
    const abilityRequired = 'onboarding'
    const forceRefresh = false

    // Get the current user's data
    const userData = getUserData()

    // Register module
    const REPORT_APP_STORE_MODULE_NAME = 'app-onboarding'
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.registerModule(REPORT_APP_STORE_MODULE_NAME, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
    })

    // Setup other vars
    const isAddNewSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Unapproved', value: false },
      { label: 'Approved', value: true },
    ]

    const {
      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      formatPhoneNumber,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      clientList,
    } = useList(abilityRequired, REPORT_APP_STORE_MODULE_NAME)

    return {
      abilityRequired,
      forceRefresh,
      userData,
      // Sidebar
      isAddNewSidebarActive,

      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      formatPhoneNumber,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      avatarText,

      statusOptions,
      clientList,
    }
  },
  methods: {
    refreshList() {
      this.forceRefresh = true
      this.$store.state.onboarding.fetchingList = true
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
