<template>
  <div v-if="clientList">
    <b-card-header class="pb-50">
      <h4>
        Clients List
      </h4>
      <b-form-checkbox
        v-model="showFilters"
        name="check-button"
        switch
        inline
      >
        <h5>
          Filters
        </h5>
      </b-form-checkbox>
    </b-card-header>
    <b-card-body
      v-if="showFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="9"
          class="mb-md-1 mb-1"
        >
          <label>Client</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="clientFilter"
            :options="clientList"
            class="w-100"
            :reduce="val => val.value"
            @input="val => handleSelectClient(val)"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="siteOptions !== null"
      >
        <b-col
          cols="12"
          md="9"
          class="mb-md-1 mb-1"
        >
          <label>Site</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="siteFilter"
            :options="siteOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="val => handleSelectClientSite(val)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="7"
          class="mb-md-1 mb-1"
        >
          <label>Start Date (optional): If provided, Patrols created on the date and those created within 30 days after will be listed.</label>
          <flat-pickr
            id="received_date"
            v-model="localStartDate"
            class="form-control"
            :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
            @input="(val) => handleUpdateStartDate(val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import ability from '../../../../libs/acl/ability'
import clientStoreModule from '../../clients/clientStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    clientFilter: {
      type: [String, null],
      default: null,
    },
    siteFilter: {
      type: [String, null],
      default: null,
    },
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    preventRefresh: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Get the current user's data
    const userData = getUserData()

    const toast = useToast()
    const sitesData = ref([])
    const siteOptions = ref([])
    const clientList = ref(null)

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    if (ability.can('read', 'clients')) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Client List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 0,
        page: 1,
        sortBy: 'display_name',
        sortDesc: true,
        status: null,
        service: null,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          clientList.value = []
          response.data.clients.forEach(item => {
            clientList.value.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientList')
          console.log(clientList)
        })
        .catch(error => {
          console.log(error) // No current user
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientList.value = null
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function getClientSites(clientname) {
      console.log(clientname)
      console.log('fetchClientSites')
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Site List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      if (ability.can('read', 'clients')) {
        const qvars = {
          forceRefresh: false,
          q: null,
          perPage: 0,
          page: 1,
          sortBy: 'sitename',
          sortDesc: true,
          status: null,
          clientname,
        }
        console.log(qvars)
        store
          .dispatch('app-client/fetchClientSites', qvars)
          .then(response => {
            console.log(response)
            console.log(sitesData.value)
            sitesData.value = response.data
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            siteOptions.value.unshift({ label: '<select>', value: '' })
            console.log('this.sitesData')
            console.log(sitesData)
            console.log('this.siteOptions')
            console.log(siteOptions)
          })
          .catch(error => {
            console.log(error)
            store.state.clients.fetchingClientSitesList = false
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Client Sites list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    if (props.clientFilter) {
      getClientSites(props.clientFilter)
    }

    return {
      getClientSites,
      clientList,
      siteOptions,
      sitesData,
      userData,
    }
  },
  data() {
    return {
      showFilters: true,
      localStartDate: null,
    }
  },
  created() {
    if (this.startDateFilter !== null) {
      this.localStartDate = this.startDateFilter.value
    }
  },
  methods: {
    handleSelectClient(val) {
      console.log('handleSelectClient')
      console.log(val)
      console.log(typeof val)
      this.siteFilter = null
      this.$emit('update:siteFilter', null)
      // @input="(val) => $emit('update:clientFilter', val)"
      this.$emit('update:clientFilter', val)
      // Get a list of sites for the selected client
      this.getClientSites(val)
    },
    handleSelectClientSite(val) {
      console.log('handleSelectClientSite')
      console.log(val)
      console.log(typeof val)
      // @input="(val) => $emit('update:siteFilter', val)"
      this.$emit('update:siteFilter', val)
    },
    handleUpdateStartDate(val) {
      console.log('updateStartDate')
      console.log(val)
      console.log(typeof val)
      this.$emit('update:startDateFilter', Math.floor(new Date(val).getTime() / 1000).toString())
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
