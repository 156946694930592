import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userPreferences from '@/store/userPreferences'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useUsersList(clientName) {
  console.log('clientName')
  console.log(clientName)
  // Use toast
  const toast = useToast()

  const userPrefs = userPreferences()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'client_display_name', label: 'client name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'phone', sortable: false },
    { key: 'role', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const perPage = ref(10)
  if (userPrefs.getUserPref('usersPerPage') !== null) {
    // get the current value for per page
    perPage.value = userPrefs.getUserPref('usersPerPage')
  }
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('last_name')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const regionFilter = ref(null)
  const onboardingFilter = ref(false)
  const statusFilter = ref('active')

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, regionFilter, onboardingFilter, statusFilter], () => {
    const tmp = { usersPerPage: perPage.value }
    userPrefs.saveUserPrefs(tmp)
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    console.log('fetchUsers')
    if (ability.can('read', 'users')) {
      const qvars = {
        forceRefresh: store.state.users.fetchingUserList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        region: regionFilter.value,
        onboarding: onboardingFilter.value,
        status: statusFilter.value,
        client_name: clientName,
      }
      console.log(qvars)
      store
        .dispatch('app-user/fetchUsers', qvars)
        .then(response => {
          console.log(response)
          const { users } = response.data
          callback(users)
          totalUsers.value = response.data.totalUsers
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const formatPhoneNumber = phone => {
    try {
      if (phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      if (phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
    } catch (e) { console.log(e) }
    return phone
  }

  const resolveUserRoleVariant = role => {
    // if (role === 'subscriber') return 'primary'
    // if (role === 'author') return 'warning'
    // if (role === 'maintainer') return 'success'
    // if (role === 'editor') return 'info'
    if (role === 'Management') return 'danger'
    return 'info'
  }

  const resolveUserRoleIcon = role => {
    // if (role === 'subscriber') return 'UserIcon'
    // if (role === 'author') return 'SettingsIcon'
    // if (role === 'maintainer') return 'DatabaseIcon'
    // if (role === 'editor') return 'Edit2Icon'
    if (role === 'Management') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'disabled') return 'secondary'
    return 'success'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refUserListTable,

    formatPhoneNumber,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    regionFilter,
    statusFilter,
    onboardingFilter,
  }
}
