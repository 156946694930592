/* eslint-disable */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// AWS Amplify
// eslint-disable-next-line no-unused-vars
import Amplify, { Auth } from 'aws-amplify'
// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSignal,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Fontawesome
library.add(
  faSignal,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import custom CSS styles
// require('@/assets/css/customTheme.css')

Vue.config.productionTip = false

// Global Variables
Vue.prototype.$usersList = null
Vue.prototype.$clientsList = null
Vue.prototype.$systemSettings = null

Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    // mandatorySignIn: true,
    // // REQUIRED - Amazon Cognito Identity Pool ID
    // identityPoolId: "ca-central-1:f856d70c-02fd-46b0-900a-aa993387fed3",
    // // REQUIRED - Amazon Cognito Region
    // region: "ca-central-1",
    // // OPTIONAL - Amazon Cognito User Pool ID
    // userPoolId: "ca-central-1_JcQsYhuMV",
    // // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    // userPoolWebClientId: "28lmjnoheu7v7q0l5ksfnpbkcf",

    mandatorySignIn: true,
    // REQUIRED - Amazon Cognito Identity Pool ID
    // identityPoolId: 'ca-central-1:b14bf6af-92be-4d51-9b7b-4908bb8a278f',
    identityPoolId: process.env.VUE_APP_AMPLIFY_AUTH_IDENTITYPOOLID,
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AMPLIFY_AUTH_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_AMPLIFY_AUTH_USERPOOLID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_AMPLIFY_AUTH_USERPOOLWEBCLIENTID,
  },
  API: {
    endpoints: [
      {
        name: 'APIGateway',
        endpoint:
          process.env.VUE_APP_AMPLIFY_API_ENDPOINTS_APIGATEWAY_ENDPOINT,
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_AMPLIFY_STORAGE_AWSS3_BUCKET,
      region: process.env.VUE_APP_AMPLIFY_STORAGE_AWSS3_REGION,
      // identityPoolId: process.env.VUE_APP_AMPLIFY_STORAGE_AWSS3_IDENTITYPOOLID,
      custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
    },
  },
})

// 'https://wp4k0p3wta.execute-api.ca-central-1.amazonaws.com/Stage',

new Vue({
  router,
  store,
  i18n,
  components: { App },
  render: h => h(App),
}).$mount('#app')
