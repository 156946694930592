<template>

  <div v-if="$can('read', abilityRequired) && theItems != null">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Alerts
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/alerts/list/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  Alerts
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div
      v-for="item in theItems.value"
      :key="item.creation_date"
    >
      <b-card
        v-if="item.is_shown === true"
      >
        <b-card-body>
          {{ item.detail }}<br><br>
          Created: {{ item.creation_date }}
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '../storeModule'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
  },
  setup() {
    const toast = useToast()
    const abilityRequired = 'alerts'

    // Get the current user's data
    const userData = getUserData()

    // Register module
    const STORE_MODULE_NAME = 'app-alerts'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    return {
      abilityRequired,
      userData,
      toast,
    }
  },
  data() {
    return {
      isLoading: true, // Set to true to show spinner initially
      theItems: ref(null),
    }
  },
  created() {
    console.log('fetchListItems')
    if (ability.can('read', this.abilityRequired)) {
      console.log('PASSED')
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 1000,
        page: 1,
        sortBy: 'creation_date',
        sortDesc: true,
      }
      console.log(qvars)
      store
        .dispatch('app-alerts/fetchItems', qvars)
        .then(response => {
          console.log(response)
          this.theItems.value = response.data.theItems
          console.log(this.theItems.value)
        })
        .catch(error => {
          console.log(error) // No current user
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
