<template>
  <div v-if="clientData && $can('read', 'clients')">
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Clients
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/cameras/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/clients/list"
                >
                  Clients
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  {{ clientData.display_name }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching client data
      </h4>
      <div class="alert-body">
        No client found with this clientname. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-clients-list'}"
        >
          Client List
        </b-link>
        .
      </div>
    </b-alert>

    <template v-if="clientData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <client-view-client-info-card :client-data="clientData" />
        </b-col>
      </b-row>
      <client-sites-list
        :clientname="clientname"
        :client-display-name="clientDisplayName"
      />
      <users-list
        :client-name-prop="clientname"
        :client-display-name-prop="clientDisplayName"
      />
      <b-row>
        <b-col
          cols="24"
          xl="12"
          lg="12"
          md="12"
        >
          <client-view-client-info-notes :client-data="clientData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'

// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import ClientSitesList from '@/views/apps/clients/clientsites-list/ClientSitesList.vue'
import UsersList from '@/views/apps/user/users-list/UsersList.vue'
import clientStoreModule from '../clientStoreModule'
import ClientViewClientInfoCard from './ClientViewClientInfoCard.vue'
import ClientViewClientInfoNotes from './ClientViewClientInfoNotes.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,

    // Local Components
    ClientViewClientInfoCard,
    ClientViewClientInfoNotes,
    ClientSitesList,
    UsersList,
  },
  setup() {
    const clientData = ref(null)
    const clientname = ref(router.currentRoute.params.clientname)
    const clientDisplayName = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-client'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    if (ability.can('read', 'clients')) {
      store.dispatch('app-client/fetchClient', router.currentRoute.params.clientname)
        .then(response => {
          clientData.value = response.data
          clientDisplayName.value = response.data.display_name
        })
        .catch(error => {
          console.log(error)
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientData.value = undefined
          }
        })
    }

    return {
      clientData,
      clientname,
      clientDisplayName,
    }
  },
}
</script>

<style>

</style>
