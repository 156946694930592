<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Reports
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/type">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  @click="goBack"
                >
                  Reports
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  General Occurrence Report
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-card v-if="reportData && clientOptions && ($can('create', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Report Details
        </h5>
      </b-card-header>
      <b-card-body>
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form @submit.prevent="handleSubmit(validateForm)">
            <!-- Field: Standard Hidden Fields -->
            <input v-model="reportData.report_type" type="hidden" name="report_type">
            <input v-model="reportData.time_zone" type="hidden" name="time_zone">
            <input v-model="reportData.client_display_name" type="hidden" name="client_display_name">
            <input v-model="reportData.site_address" type="hidden" name="site_address">

            <!-- Field: Type Of Occurrence -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Type Of Occurrence" label-for="occurrence_type">
                  <validation-provider #default="{ errors }" name="occurrence_type" rules="required">
                    <v-select
                      v-model="reportData.occurrence_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="occurrenceTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="occurrence_type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Client Selection -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Client" label-for="clientname">
                  <validation-provider #default="{ errors }" name="clientname" rules="required">
                    <v-select
                      v-model="reportData.clientname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="clientname"
                      @input="handleSelectClient"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Site selection -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Site" label-for="sitename">
                  <validation-provider #default="{ errors }" name="sitename" rules="required">
                    <v-select
                      v-model="reportData.sitename"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="siteOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="sitename"
                      @input="handleSelectSite"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Police Division -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Police Division" label-for="police_division">
                  <validation-provider #default="{ errors }" name="police_division" rules="required">
                    <b-form-input id="police_division" v-model="reportData.police_division" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Place Of Occurrence -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Place Of Occurrence" label-for="occurrence_place">
                  <validation-provider #default="{ errors }" name="occurrence_place" rules="required">
                    <b-form-input id="occurrence_place" v-model="reportData.occurrence_place" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Date Of Occurrence -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Date Of Occurrence" label-for="occurrence_date">
                  <validation-provider #default="{ errors }" name="occurrence_date" rules="required">
                    <flat-pickr
                      id="occurrence_date"
                      v-model="reportData.occurrence_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Complaint Received By -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Complaint Received By" label-for="complaint_received_by">
                  <validation-provider #default="{ errors }" name="complaint_received_by" rules="required">
                    <b-form-input id="complaint_received_by" v-model="reportData.complaint_received_by" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Date Received -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Date Received" label-for="complaint_received_date">
                  <validation-provider #default="{ errors }" name="complaint_received_date" rules="required">
                    <flat-pickr
                      id="complaint_received_date"
                      v-model="reportData.complaint_received_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Initial Investigation By -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Initial Investigation By" label-for="initial_investigation_by">
                  <validation-provider #default="{ errors }" name="initial_investigation_by" rules="required">
                    <b-form-input id="initial_investigation_by" v-model="reportData.initial_investigation_by" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Date Investigated -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Date Investigated" label-for="initial_investigation_date">
                  <validation-provider #default="{ errors }" name="initial_investigation_date" rules="required">
                    <flat-pickr
                      id="initial_investigation_date"
                      v-model="reportData.initial_investigation_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Date Made Out -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Date Made Out" label-for="reported_date">
                  <validation-provider #default="{ errors }" name="reported_date" rules="required">
                    <flat-pickr
                      id="reported_date"
                      v-model="reportData.reported_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: SYNOPSIS -->
            <b-row>
              <b-col md="12">
                <b-form-group label="Synopsis" label-for="details">
                  <validation-provider #default="{ errors }" name="details" rules="required">
                    <b-form-textarea id="details" v-model="reportData.details" rows="3" max-rows="8" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Open / Closed -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Open / Closed" label-for="is_open">
                  <validation-provider #default="{ errors }" name="is_open" rules="required">
                    <b-form-radio-group
                      v-model="reportData.is_open"
                      :options="[{ text: 'Open', value: true }, { text: 'Closed', value: false }]"
                      name="radios-stacked"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Diary Date -->
            <b-row>
              <b-col md="6">
                <b-form-group label="Diary Date" label-for="diary_date">
                  <validation-provider #default="{ errors }" name="diary_date">
                    <flat-pickr
                      id="diary_date"
                      v-model="reportData.diary_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d' }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Submit Button -->
            <div v-if="reportID === null">
              <b-row class="mt-3">
                <b-col v-if="$can('create', 'reports')" md="2">
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    type="submit"
                  >
                    Add Report
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <!-- Buttons -->
            <div v-if="reportID !== null">
              <b-row class="mt-3">
                <b-col v-if="$can('update', 'reports')" md="2">
                  <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" block :disabled="preventDoubleClick" type="submit">
                    Save Changes
                  </b-button>
                </b-col>
                <b-col v-if="$can('update', 'reports')" md="2">
                  <b-button variant="secondary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" block :disabled="preventDoubleClick" @click="printReport">
                    Print Report
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="$can('delete', 'reports')"
                class="mt-4"
              >
                <b-col
                  v-if="!reportData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Photos and Images -->
    <b-card v-if="reportData && reportID !== null && ($can('create', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Photos / Images
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('update', 'reports')"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openCamera"
              >
                Take Picture
              </b-button>
            </b-col>
            <b-col
              v-if="$can('update', 'reports')"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openImageSelector"
              >
                Add Image
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- Images List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <div
              v-if="mediaItem.filename.slice(-3) === 'jpg'"
              class="item-img text-center"
            >
              <thumbs-lazy-image-component
                class="card-img-top"
                :src="mediaItem.access_url"
                src-placeholder="@/assets/images/noImage.png"
              />
            </div>

            <b-card-footer
              v-if="mediaItem.filename.slice(-3) === 'jpg'"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteImage(mediaItem)"
                  >
                    Delete Image
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style="display: none;"
          @change="handleImageChange"
        >
        <input
          ref="imageInput"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="handleImageChange"
        >
      </b-card-body>
    </b-card>
    <!-- Files -->
    <b-card v-if="reportData && reportID !== null && ($can('create', 'reports') || $can('update', 'reports'))">
      <b-card-header>
        <h5>
          Files
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('update', 'reports')"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openFileSelector"
              >
                Add File
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- File List -->
        <section
          v-if="reportData.attachment_list"
          class="grid-view"
        >
          <b-card
            v-for="mediaItem in reportData.attachment_list"
            :id="mediaItem.filename"
            :ref="mediaItem.filename"
            :key="mediaItem.filename"
            no-body
          >
            <b-card-footer
              v-if="mediaItem.filename.slice(-3) !== 'jpg'"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="downloadFile(mediaItem)"
                  >
                    Download File
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteFile(mediaItem)"
                  >
                    Delete File
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="fileInput"
          type="file"
          accept="*/*"
          style="display: none;"
          @change="handleFileChange"
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required, email } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormRadioGroup, BCardFooter,
} from 'bootstrap-vue'
import { Storage, Auth, API } from 'aws-amplify'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import reportsStoreModule from '../reportsStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import ThumbsLazyImageComponent from '../thumbsLazyImageComponent'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BFormTextarea,
    BFormRadioGroup,
    ThumbsLazyImageComponent,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      preventDoubleClick: false,
    }
  },
  setup() {
    let theReportID = null
    let theSK = null
    let theReportType = null
    if (router.currentRoute.params.reportID !== undefined && router.currentRoute.params.reportID !== null
      && router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null
      && router.currentRoute.params.report_type !== undefined && router.currentRoute.params.report_type !== null) {
      theReportID = router.currentRoute.params.reportID
      theSK = router.currentRoute.params.SK
      theReportType = router.currentRoute.params.report_type
    }
    const reportID = ref(theReportID)
    const SK = ref(theSK)
    const reportType = ref(theReportType)
    const reportData = ref(null)
    const clientsData = ref([])
    const clientOptions = ref([])
    const sitesData = ref([])
    const siteOptions = ref([])
    const occurrenceTypeOptions = store.state.appSettings.type_of_occurrence_options

    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportsStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()
    if (ability.can('read', 'clients')) {
      toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Getting Client List',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      const qvars = {
        forceRefresh: false,
        q: null,
        perPage: 0,
        page: 1,
        sortBy: 'display_name',
        sortDesc: true,
        status: null,
        service: null,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClients', qvars)
        .then(response => {
          clientsData.value = response.data
          clientsData.value.clients.forEach(item => {
            clientOptions.value.push({ label: item.display_name, value: item.clientname })
          })
          console.log('clientsData')
          console.log(clientsData)
          console.log('clientOptions')
          console.log(clientOptions)
        })
        .catch(error => {
          console.log(error) // No current user
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            clientsData.value = undefined
          }
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching clients list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function getClientSites(val) {
      if (ability.can('read', 'clients')) {
        const apiName = 'APIGateway'
        const path = `/client/${val}/site`
        const myInit = { response: true }

        API.get(apiName, path, myInit)
          .then(response => {
            sitesData.value = response.data
            siteOptions.value = sitesData.value
            sitesData.value.forEach((item, index) => {
              if (item.is_active) {
                siteOptions.value[index].label = item.sitename
                siteOptions.value[index].value = item.sitename
              }
            })
            siteOptions.value.unshift({ label: '<select>', value: '' })
          })
          .catch(error => {
            if (typeof error.response !== 'undefined' && error.response.status === 404) {
              sitesData.value = []
            }
          })
      }
    }

    return {
      reportID,
      SK,
      reportType,
      reportData,
      clientsData,
      clientOptions,
      siteOptions,
      sitesData,
      occurrenceTypeOptions,
      getClientSites,
    }
  },
  created() {
    if (this.reportID === null) {
      this.reportData = {}
      this.reportData.report_type = 'general_occurrence'
      this.reportData.time_zone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      this.reportData.client_display_name = ''
      this.reportData.attachment_list = []
      this.reportData.sitename = ''
      this.reportData.is_open = true
    } else {
      this.getExistingReport()
    }
  },
  methods: {
    goBack() {
      console.log('goback')
      window.history.back()
    },
    handleSelectClient(val) {
      console.log('handleSelectClient')
      this.clientsData.clients.forEach(item => {
        if (item.clientname === val) {
          this.reportData.client_display_name = item.display_name
        }
      })
      // Get a list of sites for the selected client
      this.getClientSites(val)
    },
    handleSelectSite(val) {
      this.sitesData.forEach(item => {
        if (item.sitename === val) {
          this.reportData.site_address = `${item.sitename}, ${item.province}, ${item.country}`
        }
      })
    },
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getExistingReport() {
      console.log('getExistingReport')
      if (this.reportID !== null && this.SK !== null && this.reportType !== null && ability.can('update', 'reports')) {
        console.log('getting report data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Getting Report Data',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          reportID: this.reportID,
          SK: this.SK,
          reportType: this.reportType,
        }
        store.dispatch('app-reports/fetchReport', qvars)
          .then(response => {
            console.log(response)
            this.reportData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Getting Report.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      if (this.preventDoubleClick === false) {
        if (this.reportID === null) {
          if (ability.can('create', 'reports')) {
            console.log('adding report')
            let proceedWithSave = true
            // Manual Validation Items
            console.log(moment(this.reportData.complaint_received_date).format('YYYY-MM-DDTHH:mm:ss'))
            this.reportData.complaint_received_date = moment(this.reportData.complaint_received_date).format('YYYY-MM-DDTHH:mm:ss')
            console.log(this.reportData.complaint_received_date)
            this.reportData.occurrence_date = moment(this.reportData.occurrence_date).format('YYYY-MM-DDTHH:mm:ss')
            this.reportData.initial_investigation_date = moment(this.reportData.initial_investigation_date).format('YYYY-MM-DDTHH:mm:ss')
            this.reportData.reported_date = moment(this.reportData.reported_date).format('YYYY-MM-DDTHH:mm:ss')
            this.reportData.diary_date = moment(this.reportData.diary_date).format('YYYY-MM-DD')
            console.log(this.reportData)
            proceedWithSave = true
            if (proceedWithSave) {
              this.$refs.observer.validate().then(success => {
                if (success) {
                  this.preventDoubleClick = true
                  const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                  store.dispatch('app-reports/addReport', cleanReportData)
                    .then(response => {
                      this.preventDoubleClick = false
                      this.reportID = response.report_id
                      this.reportData.report_id = response.report_id
                      this.reportData.SK = response.SK
                      this.reportData.approved = response.approved
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Report Added',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(error => {
                      this.preventDoubleClick = false
                      let theErrorStr = 'The following error(s) where received:\n'
                      if (typeof error.response.data.errors !== 'undefined') {
                        error.response.data.errors.forEach(item => {
                          theErrorStr += `${item.message}\n`
                        })
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error Adding Report.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                          text: theErrorStr,
                        },
                      })
                    })
                }
              })
            }
          }
        } else if (ability.can('update', 'reports')) {
          let proceedWithSave = true
          this.reportData.complaint_received_date = moment(this.reportData.complaint_received_date).format('YYYY-MM-DDTHH:mm:ss')
          this.reportData.occurrence_date = moment(this.reportData.occurrence_date).format('YYYY-MM-DDTHH:mm:ss')
          this.reportData.initial_investigation_date = moment(this.reportData.initial_investigation_date).format('YYYY-MM-DDTHH:mm:ss')
          this.reportData.reported_date = moment(this.reportData.reported_date).format('YYYY-MM-DDTHH:mm:ss')
          this.reportData.diary_date = moment(this.reportData.diary_date).format('YYYY-MM-DD')
          if (this.reportData.alarm_type_list === undefined) {
            this.reportData.alarm_type_list = {}
          }
          proceedWithSave = true
          if (proceedWithSave) {
            this.$refs.observer.validate().then(success => {
              if (success) {
                this.preventDoubleClick = true
                const cleanReportData = JSON.parse(JSON.stringify(this.reportData))
                store.dispatch('app-reports/updateReport', cleanReportData)
                  .then(response => {
                    console.log(response)
                    this.preventDoubleClick = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Report Updated',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(error => {
                    this.preventDoubleClick = false
                    let theErrorStr = 'The following error(s) where received:\n'
                    if (typeof error.response.data.errors !== 'undefined') {
                      error.response.data.errors.forEach(item => {
                        theErrorStr += `${item.message}\n`
                      })
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error Updating Report.',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: theErrorStr,
                      },
                    })
                  })
              }
            })
          }
        }
      }
    },
    async printReport() {
      const printContent = await this.generatePrintContent()
      console.log('printContent: ')
      console.log(printContent)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(printContent)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 2000)
    },
    async getImageForPrint() {
      // Check if there are any attachments
      if (!this.reportData.attachment_list.length) {
        return ''
      }

      // Prepare an array of promises for fetching the images
      const imagePromises = this.reportData.attachment_list.map(async item => {
        const result1 = await Storage.get(item.access_url, {
          download: false,
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })

        console.log(result1)
        return `<div><img src='${result1}'/><br><br></div>`
      })

      // Wait for all image promises to resolve
      const imageHTMLArray = await Promise.all(imagePromises)

      // Join the HTML snippets into a single string
      const imageHTML = imageHTMLArray.join('')

      console.log(imageHTML)
      return imageHTML
    },
    async generatePrintContent() {
      const imageHTML = await this.getImageForPrint()
      return `
        <html>
          <head>
            <title>General Occurrence Report</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              h2 {
                text-align: center;
              }
              .logo {
                width: 300px;
                margin-bottom: 20px;
              }
              .section {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;
              }
              .section .label {
                width: 30%;
                font-weight: bold;
              }
              .section .value {
                width: 70%;
              }
              .section ul {
                list-style: none;
                padding: 0;
              }
              .section ul li {
                margin: 5px 0;
              }
            </style>
          </head>
          <body>
            <img src="/img/email-logo.png" class="logo" alt="Cancom">
            <h2>General Occurrence Report</h2>
            <div class="section">
              <div class="label">Client:</div>
              <div class="value">${this.getClientDisplayName(this.reportData.clientname)}</div>
            </div>
            <div class="section">
              <div class="label">Site:</div>
              <div class="value">${this.reportData.sitename}</div>
            </div>
            <div class="section">
              <div class="label">Site Address:</div>
              <div class="value">${this.reportData.site_address}</div>
            </div>
            <div class="section">
              <div class="label">Type Of Occurrence:</div>
              <div class="value">${this.reportData.occurrence_type}</div>
            </div>
            <div class="section">
              <div class="label">Police Division:</div>
              <div class="value">${this.reportData.police_division}</div>
            </div>
            <div class="section">
              <div class="label">Place Of Occurrence:</div>
              <div class="value">${this.reportData.occurrence_place}</div>
            </div>
            <div class="section">
              <div class="label">Date Of Occurrence:</div>
              <div class="value">${this.reportData.occurrence_date}</div>
            </div>
            <div class="section">
              <div class="label">Complaint Received By:</div>
              <div class="value">${this.reportData.complaint_received_by}</div>
            </div>
            <div class="section">
              <div class="label">Date Received:</div>
              <div class="value">${this.reportData.complaint_received_date}</div>
            </div>
            <div class="section">
              <div class="label">Initial Investigationn By:</div>
              <div class="value">${this.reportData.initial_investigation_by}</div>
            </div>
            <div class="section">
              <div class="label">Date Investigated:</div>
              <div class="value">${this.reportData.initial_investigation_date}</div>
            </div>
            <div class="section">
              <div class="label">Date Made Out:</div>
              <div class="value">${this.reportData.reported_date}</div>
            </div>
            <div class="section">
              <div class="label">SYNOPSIS:</div>
              <div class="value">${this.reportData.details}</div>
            </div>
            <div class="section">
              <div class="label">Open / Closed:</div>
              <div class="value">${this.reportData.is_open}</div>
            </div>
            <div class="section">
              <div class="label">Diary Date:</div>
              <div class="value">${this.reportData.diary_date}</div>
            </div>
            <div class="section">
              ${imageHTML}
            </div>
          </body>
        </html>
      `
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.reportData.approved) {
        this.reportData.approved = false
      } else {
        this.reportData.approved = true
      }
      this.validateForm()
    },
    getClientDisplayName(clientname) {
      const client = this.clientOptions.find(option => option.value === clientname)
      return client ? client.label : ''
    },
    openCamera() {
      this.$refs.cameraInput.click()
    },
    openImageSelector() {
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.jpg`
          const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              this.reportData.attachment_list.push({
                filename: theFileName,
                access_url: result.key,
                original_ext: fileExtension,
              })
              this.validateForm()
            })
            .catch(err => {
              console.log(err)
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(item) {
      console.log('deleteimage')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const theFileName = `${this.reportData.report_id}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `reports/${this.reportData.report_type}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            this.reportData.attachment_list.push({
              filename: theFileName,
              access_url: result.key,
              original_ext: fileExtension,
            })
            this.validateForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.download = item.filename || 'download' // you can set a default filename if item.filename is not available
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item) {
      console.log('deletefile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.reportData)
          // Update the report data deleting the item from the attachment list
          this.reportData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.reportData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px;
}
</style>
