<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      v-if="itemData"
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Post Orders
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  to="/apps/postorders/list/"
                >
                  Post Orders
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  {{ itemData.title }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card v-if="itemData && ($can('read', abilityRequired) || $can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-body v-if="$can('create', abilityRequired) || $can('update', abilityRequired) || $can('delete', abilityRequired)">
        <!-- Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Title -->
            <b-row
              class="mb-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Title "
                  label-for="title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                  >
                    <b-form-input
                      id="title"
                      v-model="itemData.title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Submit Button -->
            <div
              v-if="itemSK === null && ($can('create', abilityRequired) || $can('update', abilityRequired))"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('create', abilityRequired)"
                  md="3"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Add {{ itemTypeName }}
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- Buttons -->
            <div
              v-if="itemSK !== null && ($can('create', abilityRequired) || $can('update', abilityRequired) || $can('delete', abilityRequired))"
            >
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="$can('delete', abilityRequired)"
                  md="2"
                >
                  <!-- Print Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteItem"
                  >
                    Delete
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
        <div>Created: {{ itemData.creation_date }}</div>
      </b-card-body>
      <b-card-body v-else-if="$can('read', abilityRequired)">
        <div>{{ itemData.title }}</div>
        <div class="mt-2">
          Created: {{ itemData.creation_date }}
        </div>
      </b-card-body>
    </b-card>
    <!-- Files -->
    <b-card v-if="itemData && itemSK !== null && maxFiles > 0 && ($can('read', abilityRequired) || $can('create', abilityRequired) || $can('update', abilityRequired))">
      <b-card-header>
        <h5>
          Files
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- Button to trigger the file input -->
        <div>
          <b-row class="mb-2">
            <b-col
              v-if="$can('create', abilityRequired) && $can('create', abilityRequired)"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                block
                :disabled="preventDoubleClick"
                @click="openFileSelector"
              >
                Add File
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- File List -->
        <section
          v-if="itemData.attachment"
          class="grid-view"
        >
          <b-card>
            <b-card-footer
              v-if="itemData.attachment && itemData.attachment.access_url && itemData.attachment.filename"
              class="pt-1 pb-1 border-dark"
            >
              <b-row>
                <b-col>
                  <b-button
                    v-if="$can('read', abilityRequired)"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="downloadFile(itemData.attachment)"
                  >
                    Download File
                  </b-button>
                </b-col>
                <b-col v-if="$can('update', abilityRequired)">
                  <b-button
                    v-if="$can('delete', abilityRequired)"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="deleteFile(itemData.attachment, true)"
                  >
                    Delete File
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </section>
        <!-- Hidden file input for accessing camera -->
        <input
          ref="fileInput"
          type="file"
          accept="*/*"
          style="display: none;"
          @change="handleFileChange"
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCardFooter,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import storeModule from '../storeModule'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BCardFooter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      itemData: {},
    }
  },
  setup() {
    const abilityRequired = 'postorders'
    const storeModuleName = 'app-postorders'
    const itemTypeName = 'Post Order'
    const printTitle = 'Post Order'
    const allowPrinting = false
    const allowApproval = false
    const filesDir = 'company/postOrders'
    const maxFiles = 1 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 0 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    // Determine if we are creating or editing an item
    let itemSK = null
    if (router.currentRoute.params.SK !== undefined && router.currentRoute.params.SK !== null) {
      // Get the SK for the item - used to get the full details of the item
      itemSK = router.currentRoute.params.SK
    }
    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    return {
      abilityRequired,
      storeModuleName,
      itemSK,
      itemTypeName,
      allowPrinting,
      allowApproval,
      printTitle,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
    }
  },
  created() {
    // Get the item details if SK is passed in
    if (this.itemSK === null) {
      this.itemData.SK = null
      this.itemData.title = ''
      this.itemData.attachment = {}
      this.itemData.creation_date = new Date()
    } else {
      this.getExistingItemData()
    }
  },
  methods: {
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.itemSK !== null && (ability.can('read', this.abilityRequired) || ability.can('create', this.abilityRequired) || ability.can('update', this.abilityRequired))) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          SK: this.itemSK,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (this.itemSK === null && (ability.can('create', this.abilityRequired) || ability.can('update', this.abilityRequired))) {
        console.log('adding policy')
        processType = 'add'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/addItem`
        toastText = 'Add'
      } else if (this.itemSK !== null && ability.can('update', this.abilityRequired)) {
        console.log('editing report')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/updateItem`
        toastText = 'Updat'
      }
      // Additional Manual Validation

      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.itemSK = response.SK
                    this.itemData.SK = response.SK
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.itemTypeName} ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing ${this.itemTypeName}.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    deleteItem() {
      console.log('deleteItem')
      if (this.$ability.can('delete', this.abilityRequired)) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete the Post Order.', {
            title: 'Delete Alert',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              store
                .dispatch(`${this.storeModuleName}/deleteItem`, this.itemData)
                .then(response => {
                  console.log(response)
                  // Delete any uploaded file
                  try {
                    // Delete File
                    this.deleteFile(this.itemData.attachment, false)
                  } catch {
                    // Ignore error
                  }
                  // Route client
                  const nextRout = { name: 'apps-postorders' }
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Item deleted successfully',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error deleting Alert. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      console.log('handleFileChange')
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const origFileName = file.name.slice(0, (file.name.length - fileExtension.length - 1))
        const theFileName = `${origFileName}-${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `${this.filesDir}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            console.log(this.itemData)
            this.itemData.attachment = {}
            this.itemData.attachment.filename = theFileName
            this.itemData.attachment.access_url = result.key
            this.validateForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.target = '_blank'
          // link.download = item.filename || 'download'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item, doUpdate) {
      console.log('deletefile')
      console.log(item)
      if (ability.can('update', this.abilityRequired)) {
        this.preventDoubleClick = true
        Storage.remove(item.access_url, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            console.log(result)
            // Update the item data deleting the item from the attachment list
            this.itemData.attachment = {}
            if (doUpdate) {
              this.validateForm()
            }
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Deleting File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
