var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", _vm.abilityRequired)
    ? _c("div", [
        _c("div", { staticClass: "iframe-container" }, [
          _c("iframe", {
            attrs: {
              src: _vm.externalUrl,
              frameborder: "0",
              width: "100%",
              height: "100%",
              allowfullscreen: ""
            }
          })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }