<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- Breadcrumb -->
    <b-row
      v-if="itemData"
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Onboarding
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/apps/reports/list">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-if="$can('create', abilityRequired) || $can('update', abilityRequired)"
                  to="/apps/onboarding/list"
                >
                  Onboarding
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  active
                >
                  {{ itemData.first_name }} {{ itemData.last_name }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-card
      v-if="itemData !== null && ((currentLoggedInUserData.cognito_group !== 'Client_Viewer' && currentLoggedInUserData.cognito_group !== 'Client_Admin' && currentLoggedInUserData.Is_onboarding === true))"
    >
      <b-card-header>
        Pre-Approval Notes
      </b-card-header>
      <b-card-body>
        <h5
          class="text-warning"
          v-html="itemData.preapproval_notes.replace(/\n/g, '<br>')"
        />
      </b-card-body>
    </b-card>
    <b-card
      v-if="itemData !== null && (($can('create', abilityRequired) || $can('update', abilityRequired))
        || (currentLoggedInUserData.cognito_group !== 'Client_Viewer' && currentLoggedInUserData.cognito_group !== 'Client_Admin' && currentLoggedInUserData.Is_onboarding === true))"
    >
      <b-card-header>
        <h5>
          {{ itemTypeName }} Details - {{ itemData.username }}
        </h5>
      </b-card-header>
      <b-card-body>
        <!-- User Info: Input Fields -->
        <validation-observer
          ref="observer"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- ***** Personal Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>Personal Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Name -->
            <!-- Field: Middle Initial -->
            <!-- Field: Last Name -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="itemData.first_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  label="Middle Initial"
                  label-for="middle_initial"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="middle_initial"
                  >
                    <b-form-input
                      id="middle_initial"
                      v-model="itemData.middle_initial"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="itemData.last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Street -->
            <!-- Field: City -->
            <!-- Field: Province -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Street"
                  label-for="street"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="street"
                    rules="required"
                  >
                    <b-form-input
                      id="street"
                      v-model="itemData.street"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="itemData.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Province"
                  label-for="province"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="province"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.province"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="provinceOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="province"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Country -->
            <!-- Field: Postal Code -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countryOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="country"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  label="Postal Code"
                  label-for="postal_code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="postal_code"
                    rules="required"
                  >
                    <b-form-input
                      id="postal_code"
                      v-model="itemData.postal_code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Email -->
            <!-- Field: Phone Number -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="itemData.email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Phone Number"
                  label-for="phone_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="phone_number"
                        v-model="itemData.phone_number"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Birthdate -->
            <b-row class="mb-2">
              <b-col md="2">
                <b-form-group
                  label="Birthdate"
                  label-for="birth_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birth_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="birth_date"
                      v-model="itemData.birth_date"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Gender -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Gender"
                  label-for="gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                  >
                    <b-form-radio-group
                      v-model="itemData.gender"
                      :options="genderOptions"
                      name="gender"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Are you indigenous? -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Are you indigenous?"
                  label-for="indian_status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_status"
                  >
                    <b-form-radio-group
                      v-model="itemData.indian_status"
                      :options="indianStatusOptions"
                      name="indian_status"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Indian Registration Information ***** -->
            <b-row
              v-if="itemData.indian_status"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Indian Registration Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Indian Registration Number -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="6">
                <b-form-group
                  label="Indian Registration Number"
                  label-for="indian_reg_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_reg_number"
                  >
                    <b-form-input
                      id="indian_reg_number"
                      v-model="itemData.indian_reg_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: INAC Serial Number -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="6">
                <b-form-group
                  label="INAC Serial Number"
                  label-for="indian_inac_serial"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_inac_serial"
                  >
                    <b-form-input
                      id="indian_inac_serial"
                      v-model="itemData.indian_inac_serial"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Indian Expiry Date -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="6">
                <b-form-group
                  label="Indian Expiry Date"
                  label-for="indian_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_exp_date"
                  >
                    <flat-pickr
                      id="indian_exp_date"
                      v-model="itemData.indian_exp_date"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Indian Registration Card Image -->
            <b-row
              v-if="itemData.indian_status"
            >
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('permit')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('permit')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.permit_image && itemData.permit_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.permit_image.access_url"
                  :ref="itemData.permit_image.access_url"
                  :key="itemData.permit_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.permit_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** SIN Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>SIN Information</h4>
              </b-col>
            </b-row>
            <!-- Field: SIN Number -->
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="SIN Number"
                  label-for="sin"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="sin"
                    rules="required"
                  >
                    <b-form-input
                      id="sin"
                      v-model="itemData.sin"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: SIN Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="SIN Expiry Date"
                  label-for="sin_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="sin_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="sin_expiry_date"
                      v-model="itemData.sin_expiry_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: SIN Card Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      id="sin_image"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('sin')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('sin')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.sin_image && itemData.sin_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.sin_image.access_url"
                  :ref="itemData.sin_image.access_url"
                  :key="itemData.sin_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.sin_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Work Permit Information ***** -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Work Permit Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Permit Number -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Permit Number"
                  label-for="permit"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="permit"
                    rules="required"
                  >
                    <b-form-input
                      id="permit"
                      v-model="itemData.permit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Permit Expiry Date -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
            >
              <b-col md="6">
                <b-form-group
                  label="Permit Expiry Date"
                  label-for="permit_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="permit_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="permit_expiry_date"
                      v-model="itemData.permit_expiry_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Banking Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Banking Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Bank Number -->
            <!-- Field: Transit Number -->
            <!-- Field: Account Number -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Bank Number"
                  label-for="bank_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="bank_number"
                    rules="required"
                  >
                    <b-form-input
                      id="bank_number"
                      v-model="itemData.bank_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Transit Number"
                  label-for="transit_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="transit_number"
                    rules="required"
                  >
                    <b-form-input
                      id="transit_number"
                      v-model="itemData.transit_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Account Number"
                  label-for="account_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="account_number"
                    rules="required"
                  >
                    <b-form-input
                      id="account_number"
                      v-model="itemData.account_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Direct Deposit Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('deposit')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('deposit')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.direct_deposit_image && itemData.direct_deposit_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.direct_deposit_image.access_url"
                  :ref="itemData.direct_deposit_image.access_url"
                  :key="itemData.direct_deposit_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.direct_deposit_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Security Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Security Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Security Licence Number"
                  label-for="security_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence"
                      v-model="itemData.security_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Security Licence Expiry Date"
                  label-for="security_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence_exp_date"
                    :rule="dynamicRequired"
                  >
                    <flat-pickr
                      id="security_licence_exp_date"
                      v-model="itemData.security_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="security_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="security_licence_class"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence_class"
                      v-model="itemData.security_licence_class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="Security Licence Image"
                  label-for="security_licence_image"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="security_licence_image"
                    :rule="dynamicRequired"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      id="security_licence_image"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('security_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('security_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.security_licence_image && itemData.security_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.security_licence_image.access_url"
                  :ref="itemData.security_licence_image.access_url"
                  :key="itemData.security_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.security_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** First Aid Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>First Aid Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Aid Agency Name -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Agency Name"
                  label-for="first_aid_agency"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_agency"
                  >
                    <b-form-input
                      id="first_aid_agency"
                      v-model="itemData.first_aid_agency"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Type -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Type"
                  label-for="first_aid_type"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_type"
                  >
                    <v-select
                      v-model="itemData.first_aid_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firstAidTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="first_aid_type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Certificate Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Certificate Number"
                  label-for="first_aid_certificate"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_certificate"
                  >
                    <b-form-input
                      id="first_aid_certificate"
                      v-model="itemData.first_aid_certificate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="First Aid Expiry Date"
                  label-for="first_aid_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_exp_date"
                  >
                    <flat-pickr
                      id="first_aid_exp_date"
                      v-model="itemData.first_aid_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="First Aid Image"
                  label-for="first_aid_image"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_aid_image"
                    :rule="dynamicRequired"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('first_aid')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('first_aid')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.first_aid_image && itemData.first_aid_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.first_aid_image.access_url"
                  :ref="itemData.first_aid_image.access_url"
                  :key="itemData.first_aid_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.first_aid_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Firearms Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Firearms Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Number"
                  label-for="firearms_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence"
                  >
                    <b-form-input
                      id="firearms_licence"
                      v-model="itemData.firearms_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Firearms Licence Expiry Date"
                  label-for="firearms_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence_exp_date"
                  >
                    <flat-pickr
                      id="firearms_licence_exp_date"
                      v-model="itemData.firearms_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Authorization -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Authorization"
                  label-for="firearms_licence_auth"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firearms_licence_auth"
                  >
                    <v-select
                      v-model="itemData.firearms_licence_auth"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firearmsLicenceAuthOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="firearms_licence_auth"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="Firearms Licence Image"
                  label-for="firearms_licence_image"
                />
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('firearms_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('firearms_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.firearms_licence_image && itemData.firearms_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.firearms_licence_image.access_url"
                  :ref="itemData.firearms_licence_image.access_url"
                  :key="itemData.firearms_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.firearms_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Uniform Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Uniform Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Vest Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Vest Size"
                  label-for="uniform_vest"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_vest"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.uniform_vest"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformVestOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_vest"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shirt Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Shirt Size"
                  label-for="uniform_shirt"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shirt"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.uniform_shirt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformShirtOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_shirt"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Waist -->
            <!-- Field: Length -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Waist (inches)"
                  label-for="uniform_pants_waist"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_waist"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_waist"
                      v-model="itemData.uniform_pants_waist"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Pant Length (inches)"
                  label-for="uniform_pants_length"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_length"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_length"
                      v-model="itemData.uniform_pants_length"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Glove Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Glove Size"
                  label-for="uniform_glove"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_glove"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_glove"
                      v-model="itemData.uniform_glove"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Hat Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Hat Size"
                  label-for="uniform_hat"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_hat"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_hat"
                      v-model="itemData.uniform_hat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shoe Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Shoe Size (US Size)"
                  label-for="uniform_shoe"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shoe"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_shoe"
                      v-model="itemData.uniform_shoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Proof of Identification ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Proof of Identification</h4>
                <div>
                  Please provide <strong>TWO (2)</strong> of the following forms of identification:<br>
                  - Drivers Licence<br>
                  - Passport<br>
                  - Provincial Photo ID<br>
                  - PAL Card<br>
                  - Native Status<br>
                  - Permanent Residence Card / Document<br>
                  - Record of Landing with photo<br><br>
                </div>
              </b-col>
            </b-row>
            <!-- ***** Drivers Licence Information ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h4>Drivers Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Drivers Licence Number"
                  label-for="drivers_licence"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence"
                  >
                    <b-form-input
                      id="drivers_licence"
                      v-model="itemData.drivers_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Issue Date -->
            <!-- Field: Drivers Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Drivers Licence Issue Date"
                  label-for="drivers_licence_issue_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_issue_date"
                  >
                    <flat-pickr
                      id="drivers_licence_issue_date"
                      v-model="itemData.drivers_licence_issue_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Drivers Licence Expiry Date"
                  label-for="drivers_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_exp_date"
                  >
                    <flat-pickr
                      id="drivers_licence_exp_date"
                      v-model="itemData.drivers_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="drivers_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_class"
                  >
                    <b-form-input
                      id="drivers_licence_class"
                      v-model="itemData.drivers_licence_class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('drivers_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('drivers_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.drivers_licence_image && itemData.drivers_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.drivers_licence_image.access_url"
                  :ref="itemData.drivers_licence_image.access_url"
                  :key="itemData.drivers_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.drivers_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- ***** Additional Form of Identification 1 ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h5>-- OR --</h5>
                <h4>Additional Form of Identification</h4>
              </b-col>
            </b-row>
            <!-- Field: Additional Form of Identification 1 Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('identification1')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('identification1')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.identification1_image && itemData.identification1_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.identification1_image.access_url"
                  :ref="itemData.identification1_image.access_url"
                  :key="itemData.identification1_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.identification1_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- ***** Additional Form of Identification 2 ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h5>-- OR --</h5>
                <h4>Additional Form of Identification</h4>
              </b-col>
            </b-row>
            <!-- Field: Additional Form of Identification 2 Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('identification2')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('identification2')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.identification2_image && itemData.identification2_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.identification2_image.access_url"
                  :ref="itemData.identification2_image.access_url"
                  :key="itemData.identification2_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.identification2_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- Field: preapproval notes -->
            <b-row
              v-if="itemData !== null && (($can('create', abilityRequired) || $can('update', abilityRequired))) && currentLoggedInUserData.Is_onboarding !== true"
            >
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pre-Approval Notes"
                  label-for="preapproval_notes"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="preapproval_notes"
                  >
                    <b-form-textarea
                      id="preapproval_notes"
                      v-model="itemData.preapproval_notes"
                      rows="3"
                      max-rows="6"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Buttons -->
            <div>
              <b-row
                v-if="allowApproval && $can('delete', abilityRequired)"
                class="mt-4"
              >
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
                <b-col
                  v-if="!itemData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-else-if="$can('update', abilityRequired)"
                class="mt-3"
              >
                <b-col md="3">
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Proceed to Final Step
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- Hidden file input for accessing camera -->
    <input
      ref="cameraInput"
      type="file"
      accept="image/*"
      capture="environment"
      style="display: none;"
      @change="handleImageChange"
    >
    <input
      ref="imageInput"
      type="file"
      accept="image/*"
      style="display: none;"
      @change="handleImageChange"
    >
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
// import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormRadioGroup, BInputGroup, BFormTextarea,
} from 'bootstrap-vue'
import { Storage, Auth } from 'aws-amplify'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
// Phone number imports
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ThumbsLazyImageComponent from './thumbsLazyImageComponent'
import storeModule from '../storeModule'

// import ImageUploader from 'vue-image-upload-resize'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    flatPickr,
    vSelect,
    ThumbsLazyImageComponent,
    Cleave,
    BInputGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      preventDoubleClick: false,
      imageType: '',
      itemData: null,
      isNew: false,
      // Phone number imports
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
    }
  },
  setup() {
    const currentLoggedInUserData = getUserData()

    const abilityRequired = 'onboarding'
    const storeModuleName = 'app-onboarding'
    const itemTypeName = 'Onboarding'
    const printTitle = 'Onboarding'
    const allowPrinting = false
    const allowApproval = true
    const filesDir = 'onboarding'
    const maxFiles = 1 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 10 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    // Determine if we are creating or editing an item
    let username = null
    if (currentLoggedInUserData.Is_onboarding === true) {
      // Current User is onboarding. Get the current user data
      username = currentLoggedInUserData.username
    } else {
      // Get the SK for the item - used to get the full details of the item
      username = router.currentRoute.params.username
    }

    // Register module
    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    // ********** Import options from settings **********
    const indianStatusOptions = store.state.appSettings.indian_status_options
    const genderOptions = store.state.appSettings.gender_options
    const provinceOptions = store.state.appSettings.provinces
    const countryOptions = store.state.appSettings.countries
    const uniformVestOptions = store.state.appSettings.uniform_vest_options
    const uniformShirtOptions = store.state.appSettings.uniform_shirt_options
    const firstAidTypeOptions = store.state.appSettings.first_aid_type_options
    const firearmsLicenceAuthOptions = store.state.appSettings.firearms_licence_auth_options

    const dynamicRequired = ref('')

    return {
      currentLoggedInUserData,
      username,
      abilityRequired,
      storeModuleName,
      itemTypeName,
      allowPrinting,
      allowApproval,
      printTitle,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,

      genderOptions,
      provinceOptions,
      countryOptions,
      uniformVestOptions,
      uniformShirtOptions,
      firstAidTypeOptions,
      firearmsLicenceAuthOptions,
      indianStatusOptions,

      dynamicRequired,
    }
  },
  created() {
    this.getExistingItemData()
  },
  methods: {
    getExistingItemData() {
      console.log('getExistingItemData')
      if (this.username !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          username: this.username,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
            if (this.itemData === null) {
              console.log('No itemData - Use logged in data')
              this.itemData = {}
              if (this.currentLoggedInUserData.Is_onboarding === true) {
                this.itemData.username = this.currentLoggedInUserData.username
                this.itemData.first_name = this.currentLoggedInUserData.first_name
                this.itemData.last_name = this.currentLoggedInUserData.last_name
                this.itemData.phone = this.currentLoggedInUserData.phone
                this.itemData.email = this.currentLoggedInUserData.email
                this.itemData.role = this.currentLoggedInUserData.role
                this.itemData.cognito_group = this.currentLoggedInUserData.role
              } else {
                this.itemData.username = this.username
                this.itemData.first_name = null
                this.itemData.last_name = null
                this.itemData.phone = null
                this.itemData.email = null
                this.itemData.role = null
              }
              this.itemData.sin = null
              this.itemData.province = 'Ontario'
              this.itemData.country = 'Canada'
              this.isNew = true
            } else {
              if (typeof this.itemData.country === 'undefined' || this.itemData.country === null) {
                this.itemData.country = 'Canada'
              }
              if (typeof this.itemData.province === 'undefined' || this.itemData.province === null) {
                this.itemData.country = 'Ontario'
              }
            }
            if (this.itemData.role === 'Guard') {
              this.dynamicRequired = 'required'
            }
            console.log(this.itemData)
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (this.isNew === true && (ability.can('create', this.abilityRequired) || (this.currentLoggedInUserData.cognito_group !== 'Client_Viewer' && this.currentLoggedInUserData.cognito_group !== 'Client_Admin' && this.currentLoggedInUserData.Is_onboarding === true))) {
        console.log('adding report')
        processType = 'add'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/addItem`
        toastText = 'Add'
      } else if (ability.can('update', this.abilityRequired) || (this.currentLoggedInUserData.cognito_group !== 'Client_Viewer' && this.currentLoggedInUserData.cognito_group !== 'Client_Admin' && this.currentLoggedInUserData.Is_onboarding === true)) {
        console.log('editing report')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/updateItem`
        toastText = 'Updat'
      }
      // Additional Manual Validation
      // First Nations Registration Card Image
      if (this.itemData.indian_status
        && (typeof this.itemData.indian_image === 'undefined' || this.itemData.indian_image === null
        || typeof this.itemData.indian_image.access_url === 'undefined' || this.itemData.indian_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your First Nations Registration Card',
          },
        })
      }
      // SIN Card Image
      if (typeof this.itemData.sin_image === 'undefined' || this.itemData.sin_image === null
        || typeof this.itemData.sin_image.access_url === 'undefined' || this.itemData.sin_image.access_url === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your SIN Card',
          },
        })
      }
      // Direct Deposit Image
      if (typeof this.itemData.direct_deposit_image === 'undefined' || this.itemData.direct_deposit_image === null
        || typeof this.itemData.direct_deposit_image.access_url === 'undefined' || this.itemData.direct_deposit_image.access_url === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Direct Deposit Information',
          },
        })
      }
      // Security Licence Image
      if (this.dynamicRequired && (typeof this.itemData.security_licence_image === 'undefined' || this.itemData.security_licence_image === null
        || typeof this.itemData.security_licence_image.access_url === 'undefined' || this.itemData.security_licence_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Security Licence',
          },
        })
      }
      // First Aid Image
      if ((typeof this.itemData.first_aid_type !== 'undefined' && this.itemData.first_aid_type !== null) && (typeof this.itemData.first_aid_image === 'undefined' || this.itemData.first_aid_image === null
        || typeof this.itemData.first_aid_image.access_url === 'undefined' || this.itemData.first_aid_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your First Aid Card',
          },
        })
      }
      // Firearms Licence Image
      if ((typeof this.itemData.firearms_licence !== 'undefined' && this.itemData.firearms_licence !== null)
        && (typeof this.itemData.firearms_licence_image === 'undefined' || this.itemData.firearms_licence_image === null
        || typeof this.itemData.firearms_licence_image.access_url === 'undefined' || this.itemData.firearms_licence_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Firearms Licence',
          },
        })
      }
      // Identification count
      let idCount = 0
      if (typeof this.itemData.drivers_licence_image !== 'undefined' && typeof this.itemData.drivers_licence_image.filename !== 'undefined' && this.itemData.drivers_licence_image.filename !== null) {
        idCount += 1
      }
      if (typeof this.itemData.identification1_image !== 'undefined' && typeof this.itemData.identification1_image.filename !== 'undefined' && this.itemData.identification1_image.filename !== null) {
        idCount += 1
      }
      if (typeof this.itemData.identification2_image !== 'undefined' && typeof this.itemData.identification2_image.filename !== 'undefined' && this.itemData.identification2_image.filename !== null) {
        idCount += 1
      }
      if (idCount < 2) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide at least 2 forms of Identification including images',
          },
        })
        proceedWithSave = false
      }
      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.username = response.username
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.itemTypeName} ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  if (this.currentLoggedInUserData.cognito_group !== 'Client_Viewer' && this.currentLoggedInUserData.cognito_group !== 'Client_Admin' && this.currentLoggedInUserData.Is_onboarding === true) {
                    // TODO: If the current user is onboarding then need to show the messaging discribing the DocuSign process (including a link)
                    this.$bvModal
                      .msgBoxConfirm('By proceeding you will be taken to a DocuSign agreement as the final step in your onboarding.', {
                        title: 'Sign Agreement',
                        size: 'sm',
                        okVariant: 'primary',
                        okTitle: 'Proceed',
                        cancelTitle: 'Cancel',
                        cancelVariant: 'outline-secondary',
                        hideHeaderClose: false,
                        centered: true,
                      })
                      .then(value => {
                        if (value) {
                          store.dispatch(`${this.storeModuleName}/docusign`, this.username)
                            .then(response2 => {
                              console.log(response2)
                              // Open URL in new Tab
                              window.open(`${response2.DocusignLink}`, '_blank')
                            })
                            .catch(error => {
                              console.log(error)
                              this.$toast({
                                component: ToastificationContent,
                                props: {
                                  title: `Error retreiving DocuSign URL. ${error}`,
                                  icon: 'AlertTriangleIcon',
                                  variant: 'danger',
                                },
                              })
                            })
                        }
                      })
                  }
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  const theErrorStr = 'An error occured. Please contact Cancom Security for assistance.'
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing ${this.itemTypeName}.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    async printReport() {
      const printContent = await this.generatePrintContent()
      console.log('printContent: ')
      console.log(printContent)
      const printWindow = window.open('', '_blank')
      printWindow.document.write(printContent)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 2000)
    },
    async getImageForPrint() {
      // Check if there are any attachments
      if (!this.itemData.attachment_list.length) {
        return ''
      }

      // Prepare an array of promises for fetching the images
      const imagePromises = this.itemData.attachment_list.map(async item => {
        const result1 = await Storage.get(item.access_url, {
          download: false,
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })

        console.log(result1)
        return `<div><img src='${result1}'/><br><br></div>`
      })

      // Wait for all image promises to resolve
      const imageHTMLArray = await Promise.all(imagePromises)

      // Join the HTML snippets into a single string
      const imageHTML = imageHTMLArray.join('')

      console.log(imageHTML)
      return imageHTML
    },
    async generatePrintContent() {
      const imageHTML = await this.getImageForPrint()
      return `
      <html>
        <head>
        <title>Employee Onboarding</title>
        <style>
            body {
            font-family: Arial, sans-serif;
            margin: 20px;
            }
            h2 {
            text-align: center;
            }
            .logo {
            width: 300px;
            margin-bottom: 20px;
            }
            .section {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            }
            .section .label {
            width: 30%;
            font-weight: bold;
            }
            .section .value {
            width: 70%;
            }
            .section ul {
            list-style: none;
            padding: 0;
            }
            .section ul li {
            margin: 5px 0;
            }
        </style>
        </head>
        <body>
        <img src="/img/email-logo.png" class="logo" alt="Cancom">
        <h2>Employee Onboarding</h2>
        // ********** Start Customer Print Fields **********
        <div class="section">
            <div class="label">First Name:</div>
            <div class="value">${this.itemData.first_name}</div>
        </div>
        <div class="section">
            <div class="label">Last Name:</div>
            <div class="value">${this.itemData.last_name}</div>
        </div>
        <div class="section">
            <div class="label">Middle Initial:</div>
            <div class="value">${this.itemData.middle_initial}</div>
        </div>
        <div class="section">
            <div class="label">SIN Number:</div>
            <div class="value">${this.itemData.sin}</div>
        </div>
        <div class="section">
            <div class="label">SIN Expiry Date:</div>
            <div class="value">${this.itemData.sin_expiry_date}</div>
        </div>
        <div class="section">
            <div class="label">Permit Number:</div>
            <div class="value">${this.itemData.permit}</div>
        </div>
        <div class="section">
            <div class="label">Permit Expiry Date:</div>
            <div class="value">${this.itemData.permit_expiry_date}</div>
        </div>
        <div class="section">
            <div class="label">Street:</div>
            <div class="value">${this.itemData.street}</div>
        </div>
        <div class="section">
            <div class="label">City:</div>
            <div class="value">${this.itemData.city}</div>
        </div>
        <div class="section">
            <div class="label">Province:</div>
            <div class="value">${this.itemData.province}</div>
        </div>
        <div class="section">
            <div class="label">Country:</div>
            <div class="value">${this.itemData.country}</div>
        </div>
        <div class="section">
            <div class="label">Postal Code:</div>
            <div class="value">${this.itemData.postal_code}</div>
        </div>
        <div class="section">
            <div class="label">Email:</div>
            <div class="value">${this.itemData.email}</div>
        </div>
        <div class="section">
            <div class="label">Birthdate:</div>
            <div class="value">${this.itemData.birth_date}</div>
        </div>
        <div class="section">
            <div class="label">Phone Number:</div>
            <div class="value">${this.itemData.phone_number}</div>
        </div>
        <div class="section">
            <div class="label">Gender:</div>
            <div class="value">${this.itemData.gender}</div>
        </div>
        <div class="section">
            <div class="label">Are you indigenous?:</div>
            <div class="value">${this.itemData.indian_status}</div>
        </div>
        <div class="section">
            <div class="label">Vest Size:</div>
            <div class="value">${this.itemData.uniform_vest}</div>
        </div>
        <div class="section">
            <div class="label">Shirt Size:</div>
            <div class="value">${this.itemData.uniform_shirt}</div>
        </div>
        <div class="section">
            <div class="label">Waist:</div>
            <div class="value">${this.itemData.uniform_pants_waist}</div>
        </div>
        <div class="section">
            <div class="label">Length:</div>
            <div class="value">${this.itemData.uniform_pants_length}</div>
        </div>
        <div class="section">
            <div class="label">Glove Size:</div>
            <div class="value">${this.itemData.uniform_glove}</div>
        </div>
        <div class="section">
            <div class="label">Hat Size:</div>
            <div class="value">${this.itemData.uniform_hat}</div>
        </div>
        <div class="section">
            <div class="label">Shoe Size:</div>
            <div class="value">${this.itemData.uniform_shoe}</div>
        </div>
        <div class="section">
            <div class="label">Bank Number:</div>
            <div class="value">${this.itemData.bank_number}</div>
        </div>
        <div class="section">
            <div class="label">Transit Number:</div>
            <div class="value">${this.itemData.transit_number}</div>
        </div>
        <div class="section">
            <div class="label">Account Number:</div>
            <div class="value">${this.itemData.account_number}</div>
        </div>
        <div class="section">
            <div class="label">Security Licence Number:</div>
            <div class="value">${this.itemData.security_licence}</div>
        </div>
        <div class="section">
            <div class="label">Security Licence Expiry Date:</div>
            <div class="value">${this.itemData.security_licence_exp_date}</div>
        </div>
        <div class="section">
            <div class="label">Security Licence Class:</div>
            <div class="value">${this.itemData.security_licence_class}</div>
        </div>
        <div class="section">
            <div class="label">First Aid Agency Name:</div>
            <div class="value">${this.itemData.first_aid_agency}</div>
        </div>
        <div class="section">
            <div class="label">First Aid Type:</div>
            <div class="value">${this.itemData.first_aid_type}</div>
        </div>
        <div class="section">
            <div class="label">First Aid Certificate Number:</div>
            <div class="value">${this.itemData.first_aid_certificate}</div>
        </div>
        <div class="section">
            <div class="label">First Aid Expiry Date:</div>
            <div class="value">${this.itemData.first_aid_exp_date}</div>
        </div>
        <div class="section">
            <div class="label">Firearms Licence Number:</div>
            <div class="value">${this.itemData.firearms_licence}</div>
        </div>
        <div class="section">
            <div class="label">Firearms Licence Expiry Date:</div>
            <div class="value">${this.itemData.firearms_licence_exp_date}</div>
        </div>
        <div class="section">
            <div class="label">Firearms Licence Authorization:</div>
            <div class="value">${this.itemData.firearms_licence_auth}</div>
        </div>
        <div class="section">
            <div class="label">Indian Registration Number:</div>
            <div class="value">${this.itemData.indian_reg_number}</div>
        </div>
        <div class="section">
            <div class="label">INAC Serial Number:</div>
            <div class="value">${this.itemData.indian_inac_serial}</div>
        </div>
        <div class="section">
            <div class="label">Indian Expiry Date:</div>
            <div class="value">${this.itemData.indian_exp_date}</div>
        </div>
        <div class="section">
            <div class="label">Drivers Licence Number:</div>
            <div class="value">${this.itemData.drivers_licence}</div>
        </div>
        <div class="section">
            <div class="label">Drivers Licence Issue Date:</div>
            <div class="value">${this.itemData.drivers_licence_issue_date}</div>
        </div>
        <div class="section">
            <div class="label">Drivers Licence Expiry Date:</div>
            <div class="value">${this.itemData.drivers_licence_exp_date}</div>
        </div>
        <div class="section">
            <div class="label">Drivers Licence Class:</div>
            <div class="value">${this.itemData.drivers_licence_class}</div>
        </div>
        // ********** End Customer Print Fields **********
        <div class="section">
            ${imageHTML}
        </div>
        </body>
      </html>
    `
    },
    openCamera(imageType) {
      this.imageType = imageType
      this.$refs.cameraInput.click()
    },
    openImageSelector(imageType) {
      this.imageType = imageType
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          let theFileName = ''
          let theFullFileName = ''
          if (this.imageType === 'sin') {
            this.itemData.sin_image = {}
            theFileName = `${this.username}_sin_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'permit') {
            this.itemData.permit_image = {}
            theFileName = `${this.username}_permit_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'deposit') {
            this.itemData.direct_deposit_image = {}
            theFileName = `${this.username}_direct_deposit_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'security_licence') {
            this.itemData.security_licence_image = {}
            theFileName = `${this.username}_security_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'first_aid') {
            this.itemData.first_aid_image = {}
            theFileName = `${this.username}_first_aid_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'firearms_licence') {
            this.itemData.firearms_licence_image = {}
            theFileName = `${this.username}_firearms_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'drivers_licence') {
            this.itemData.drivers_licence_image = {}
            theFileName = `${this.username}_drivers_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'identification1') {
            this.itemData.identification1_image = {}
            theFileName = `${this.username}_identification1_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'identification2') {
            this.itemData.identification2_image = {}
            theFileName = `${this.username}_identification2_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          }
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              if (this.imageType === 'sin') {
                this.itemData.sin_image = {}
                this.itemData.sin_image.filename = theFileName
                this.itemData.sin_image.access_url = result.key
                this.itemData.sin_image.original_ext = fileExtension
              } else if (this.imageType === 'permit') {
                this.itemData.permit_image = {}
                this.itemData.permit_image.filename = theFileName
                this.itemData.permit_image.access_url = result.key
                this.itemData.permit_image.original_ext = fileExtension
              } else if (this.imageType === 'deposit') {
                this.itemData.direct_deposit_image = {}
                this.itemData.direct_deposit_image.filename = theFileName
                this.itemData.direct_deposit_image.access_url = result.key
                this.itemData.direct_deposit_image.original_ext = fileExtension
              } else if (this.imageType === 'security_licence') {
                this.itemData.security_licence_image = {}
                this.itemData.security_licence_image.filename = theFileName
                this.itemData.security_licence_image.access_url = result.key
                this.itemData.security_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'first_aid') {
                this.itemData.first_aid_image = {}
                this.itemData.first_aid_image.filename = theFileName
                this.itemData.first_aid_image.access_url = result.key
                this.itemData.first_aid_image.original_ext = fileExtension
              } else if (this.imageType === 'firearms_licence') {
                this.itemData.firearms_licence_image = {}
                this.itemData.firearms_licence_image.filename = theFileName
                this.itemData.firearms_licence_image.access_url = result.key
                this.itemData.firearms_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'drivers_licence') {
                this.itemData.drivers_licence_image = {}
                this.itemData.drivers_licence_image.filename = theFileName
                this.itemData.drivers_licence_image.access_url = result.key
                this.itemData.drivers_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'identification1') {
                this.itemData.identification1_image = {}
                this.itemData.identification1_image.filename = theFileName
                this.itemData.identification1_image.access_url = result.key
                this.itemData.identification1_image.original_ext = fileExtension
              } else if (this.imageType === 'identification2') {
                this.itemData.identification2_image = {}
                this.itemData.identification2_image.filename = theFileName
                this.itemData.identification2_image.access_url = result.key
                this.itemData.identification2_image.original_ext = fileExtension
              }
              this.imageType = ''
              this.preventDoubleClick = false
              // this.validateForm()
            })
            .catch(err => {
              console.log(err)
              this.imageType = ''
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.imageType = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(imageType) {
      console.log('deleteimage')
      console.log(imageType)
      this.preventDoubleClick = true
      let theURL = ''
      if (imageType === 'sin') {
        theURL = this.itemData.sin_image.access_url
      }
      Storage.remove(theURL, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.itemData)
          // Update the item data deleting the image
          if (imageType === 'sin') {
            console.log('Delete from data')
            delete this.itemData.sin_image
          }
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const origFileName = file.name.slice(0, (file.name.length - fileExtension.length - 1))
        const theFileName = `${origFileName}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `${this.filesDir}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            this.itemData.attachment_list.push({
              filename: theFileName,
              access_url: result.key,
              original_ext: fileExtension,
            })
            this.validateForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.download = item.filename || 'download' // you can set a default filename if item.filename is not available
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item) {
      console.log('deletefile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          // Update the item data deleting the item from the attachment list
          this.itemData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.itemData.attachment_list.splice(index, 1)
            }
          })
          this.validateForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.itemData.approved) {
        this.itemData.approved = false
      } else {
        this.itemData.approved = true
      }
      this.validateForm()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
.radio-spacing .custom-control {
    margin-top: 10px; /* Adjust this value to increase or decrease spacing */
}
</style>
