var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content-header-title float-left pr-1 mb-0"
                      },
                      [_vm._v(" Reports ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/apps/reports/type" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-breadcrumb-item",
                              { on: { click: _vm.goBack } },
                              [_vm._v(" Reports ")]
                            ),
                            _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                              _vm._v(" Shift Log ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.reportData &&
      _vm.clientOptions &&
      (_vm.$can("create", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Report Details ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.report_type,
                                        expression: "reportData.report_type"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "report_type"
                                    },
                                    domProps: {
                                      value: _vm.reportData.report_type
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "report_type",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.time_zone,
                                        expression: "reportData.time_zone"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "time_zone"
                                    },
                                    domProps: {
                                      value: _vm.reportData.time_zone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "time_zone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.reportData.client_display_name,
                                        expression:
                                          "reportData.client_display_name"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "client_display_name"
                                    },
                                    domProps: {
                                      value: _vm.reportData.client_display_name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "client_display_name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.attachment_list,
                                        expression: "reportData.attachment_list"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "attachment_list"
                                    },
                                    domProps: {
                                      value: _vm.reportData.attachment_list
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "attachment_list",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clientname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.clientOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "clientname"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectClient
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .clientname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "clientname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.clientname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.siteOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "sitename"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectSite
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Address",
                                                "label-for": "site_address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "site_address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "site_address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date",
                                                "label-for": "reported_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "reported_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "reported_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .reported_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "reported_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.reported_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Start Time",
                                                "label-for": "start_time"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "start_time",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-timepicker",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id:
                                                                  "start_time",
                                                                locale: "en"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .start_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "start_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.start_time"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "End Time",
                                                "label-for": "end_time"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "end_time",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-timepicker",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                id: "end_time",
                                                                locale: "en"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .end_time,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "end_time",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.end_time"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Details",
                                                "label-for": "details"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "details",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-textarea",
                                                            {
                                                              attrs: {
                                                                id: "details",
                                                                rows: "3",
                                                                "max-rows": "8"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.reportData
                                                                    .details,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.reportData,
                                                                    "details",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "reportData.details"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.reportID === null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("create", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [_vm._v(" Add Report ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportID !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("update", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$can("update", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.printReport
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print Report "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.$can("delete", "reports")
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.reportData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4271912233
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("create", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Photos / Images ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.$can("update", "reports")
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openCamera }
                                  },
                                  [_vm._v(" Take Picture ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$can("update", "reports")
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openImageSelector }
                                  },
                                  [_vm._v(" Add Image ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) === "jpg"
                              ? _c(
                                  "div",
                                  { staticClass: "item-img text-center" },
                                  [
                                    _c("thumbs-lazy-image-component", {
                                      staticClass: "card-img-top",
                                      attrs: {
                                        src: mediaItem.access_url,
                                        "src-placeholder":
                                          "@/assets/images/noImage.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            mediaItem.filename.slice(-3) === "jpg"
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteImage(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Delete Image ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "cameraInput",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    accept: "image/*",
                    capture: "environment"
                  },
                  on: { change: _vm.handleImageChange }
                }),
                _c("input", {
                  ref: "imageInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.handleImageChange }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("create", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.$can("update", "reports")
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) !== "jpg"
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadFile(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Download File ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteFile(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Delete File ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }