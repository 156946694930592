import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  abilityRequired: 'patrols',
  '/patrols': '/patrols',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('fetchTemplates')
        if (ability.can('read', 'patrols')) {
          store.state.patrols.fetchingList = true
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'clientname') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort clientname desc')
                  return b.client_display_name.localeCompare(a.client_display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.client_display_name.localeCompare(b.client_display_name)
                })
              }
            }
            if (qvars.sortBy === 'sitename') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.sitename.localeCompare(a.sitename)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.sitename.localeCompare(b.sitename)
                })
              }
            }
            if (qvars.sortBy === 'patrolname') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.patrolname.localeCompare(a.patrolname)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.patrolname.localeCompare(b.patrolname)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = items
            totalItems = items.length
            // const resultItems = []
            // // first apply filters
            // items.forEach(item => {
            //   let addThisItem = true
            //   if (qvars.q !== null
            //     && !item.title.toUpperCase().includes(qvars.q.toUpperCase())) {
            //     addThisItem = false
            //   }
            //   if (addThisItem) {
            //     resultItems.push(item)
            //   }
            // })
            // totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          console.log(qvars)
          const apiName = 'APIGateway'
          const myInit = { response: true }

          API.get(apiName, `/patrols/admin/?client=${qvars.clientname}&site=${qvars.sitename}`, myInit)
            .then(response => {
              const theResponse = response
              const items = response.data
              theResponse.data.theItems = resortFilterItems(items)
              theResponse.data.totalItems = totalItems
              console.log(theResponse)
              store.state.patrols.fetchingList = false
              resolve(theResponse)
            })
            .catch(error => {
              store.state.patrols.fetchingList = false
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchTemplate(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'patrols')) {
          const apiName = 'APIGateway'
          const path = `/patrols/admin/?clientsite=${encodeURIComponent(qvars.clientsite)}&patrolname=${encodeURIComponent(qvars.patrolname)}`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              theResponse.data = response.data
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchItems(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('fetchItems')
        console.log(qvars.sd)
        if (ability.can('read', 'patrols')) {
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.patrols.fetchingList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = item3 => {
            // sort the items
            if (qvars.sortBy === 'clientname') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort clientname desc')
                  return b.client_display_name.localeCompare(a.client_display_name)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.client_display_name.localeCompare(b.client_display_name)
                })
              }
            }
            if (qvars.sortBy === 'sitename') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.sitename.localeCompare(a.sitename)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.sitename.localeCompare(b.sitename)
                })
              }
            }
            if (qvars.sortBy === 'patrolname') {
              if (qvars.sortDesc) {
                item3.sort((a, b) => {
                  console.log('sort sitename desc')
                  return b.patrolname.localeCompare(a.patrolname)
                })
              } else {
                item3.sort((a, b) => {
                  console.log('sort clientname asc')
                  return a.patrolname.localeCompare(b.patrolname)
                })
              }
            }
          }
          const paginateItems = item2 => {
            let theReturn = item2
            if (qvars.perPage > 0) {
              const start = (qvars.page - 1) * qvars.perPage
              const end = start + qvars.perPage
              theReturn = item2.slice(start, end)
            }
            return theReturn
          }
          const resortFilterItems = items => {
            const resultItems = items
            totalItems = items.length
            // const resultItems = []
            // // first apply filters
            // items.forEach(item => {
            //   let addThisItem = true
            //   if (qvars.q !== null
            //     && !item.title.toUpperCase().includes(qvars.q.toUpperCase())) {
            //     addThisItem = false
            //   }
            //   if (addThisItem) {
            //     resultItems.push(item)
            //   }
            // })
            // totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem(`patrols-${encodeURIComponent(qvars.clientname)}-site-${encodeURIComponent(qvars.sitename)}`) === null
            || parseInt(localStorage.getItem(`patrols-${encodeURIComponent(qvars.clientname)}-site-${encodeURIComponent(qvars.sitename)}_lastRefresh`), 10) + 60000 < timeDiff // cache for 1 hour
          ) {
            console.log('Refreshing Items')
            console.log(qvars)
            const apiName = 'APIGateway'
            const myInit = { response: true }
            let theURL = `/patrols/?client=${encodeURIComponent(qvars.clientname)}&site=${encodeURIComponent(qvars.sitename)}`
            if (qvars.sd !== null) {
              theURL = `/patrols/?client=${encodeURIComponent(qvars.clientname)}&site=${encodeURIComponent(qvars.sitename)}&sd=${encodeURIComponent(qvars.sd)}`
            }

            API.get(apiName, theURL, myInit)
              .then(response => {
                const theResponse = response
                const items = response.data
                // Store data in local storage
                localStorage.setItem(`patrols-${encodeURIComponent(qvars.clientname)}-site-${encodeURIComponent(qvars.sitename)}`, JSON.stringify(items))
                localStorage.setItem(`patrols-${encodeURIComponent(qvars.clientname)}-site-${encodeURIComponent(qvars.sitename)}_lastRefresh`, d1.getTime())
                theResponse.data.theItems = resortFilterItems(items)
                theResponse.data.totalItems = totalItems
                console.log(theResponse)
                store.state.patrols.fetchingList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.patrols.fetchingList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Data')
            const theResponse = { data: {} }
            const itemsData = JSON.parse(localStorage.getItem(`patrols-${encodeURIComponent(qvars.clientname)}-site-${encodeURIComponent(qvars.sitename)}`))
            theResponse.data.theItems = resortFilterItems(itemsData)
            theResponse.data.totalItems = totalItems
            // console.log(theResponse)
            store.state.patrols.fetchingList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchItem(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'patrols')) {
          const apiName = 'APIGateway'
          const path = `/patrols/?client=${encodeURIComponent(qvars.clientname)}&site=${encodeURIComponent(qvars.sitename)}&SK=${encodeURIComponent(qvars.SK)}`
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              // console.log(response)
              const theResponse = response
              theResponse.data = response.data
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addTemplate(ctx, theData) {
      return new Promise((resolve, reject) => {
        console.log('Add Template')
        if (ability.can('create', 'patrols')) {
          const apiName = 'APIGateway'

          API.post(apiName, '/patrols/admin', { body: theData })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
    updateTemplate(ctx, theData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'patrols')) {
          const apiName = 'APIGateway'

          API.patch(apiName, '/patrols/admin', { body: theData })
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
    submitPatrol(ctx, theData) {
      return new Promise((resolve, reject) => {
        console.log('submit Patrol')
        if (ability.can('read', 'patrols')) {
          const apiName = 'APIGateway'

          API.post(apiName, '/patrols', { body: theData })
            .then(response => {
              // Add new template to the local storage to avoid call to backend
              const newItems = JSON.parse(localStorage.getItem(`patrols-${encodeURIComponent(theData.clientname)}-site-${encodeURIComponent(theData.sitename)}`))
              const tmp = {
                clientsite: theData.clientsite,
                SK: theData.SK,
                patrolname: theData.patrolname,
                time_zone: theData.time_zone,
                start_date_time: theData.start_date_time,
                end_date_time: theData.end_date_time,
                username: theData.username,
                user_display_name: theData.user_display_name,
                clientname: theData.clientname,
                client_display_name: theData.client_display_name,
                sitename: theData.sitename,
                contains_flag: theData.contains_flag,
                unfinished_checkpoints: theData.unfinished_checkpoints,
              }
              newItems.push(tmp)
              localStorage.setItem(`patrols-${encodeURIComponent(theData.clientname)}-site-${encodeURIComponent(theData.sitename)}`, JSON.stringify(newItems))
              resolve(response)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          console.error('Not Allowed')
          reject()
        }
      })
    },
  },
}
