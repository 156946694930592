import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userPreferences from '@/store/userPreferences'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useClientSitesList(clientname) {
  // Use toast
  const toast = useToast()

  const userPrefs = userPreferences()

  const refClientSitesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'sitename', label: 'site name', sortable: true },
    { key: 'country', sortable: true },
    { key: 'province', sortable: true },
    { key: 'phone', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  if (userPrefs.getUserPref('clientSitesPerPage') !== null) {
    // get the current value for per page
    perPage.value = userPrefs.getUserPref('clientSitesPerPage')
  }
  const totalClientSites = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('sitename')
  const isBusy = ref(false)
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const clientSitesList = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientSitesListTable.value ? refClientSitesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClientSites.value,
    }
  })

  const refetchData = () => {
    refClientSitesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    const tmp = { clientsPerPage: perPage.value }
    userPrefs.saveUserPrefs(tmp)
    refetchData()
  })

  const fetchClientSites = (ctx, callback) => {
    console.log('fetchClientSites')
    if (ability.can('read', 'clients')) {
      const qvars = {
        forceRefresh: store.state.clients.fetchingClientSitesList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        clientname,
      }
      console.log(qvars)
      store
        .dispatch('app-client/fetchClientSites', qvars)
        .then(response => {
          const { clientSites } = response.data
          callback(clientSites)
          totalClientSites.value = response.data.totalClientSites
          store.state.clients.fetchingClientSitesList = false
        })
        .catch(error => {
          console.log(error)
          store.state.clients.fetchingClientSitesList = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching client sites list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const formatPhoneNumber = phone => {
    try {
      if (phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      if (phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
    } catch (e) { console.log(e) }
    return phone
  }

  const resolveClientSitesStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'disabled') return 'secondary'
    return 'success'
  }

  return {
    fetchClientSites,
    tableColumns,
    perPage,
    currentPage,
    totalClientSites,
    clientSitesList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refClientSitesListTable,
    formatPhoneNumber,
    resolveClientSitesStatusVariant,
    refetchData,

    // Extra Filters
    statusFilter,
  }
}
