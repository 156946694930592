<template>
  <div v-if="$can('update', 'users')">
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <image-uploader
          :class-name="'fileinput'"
          :debug="2"
          :max-width="100"
          :max-height="100"
          :quality="0.8"
          output-format="string"
          :preview="false"
          :capture="false"
          accept=".jpg"
          @input="inputAvatar"
        >
          <label
            slot="upload-label"
            for="fileInput"
          >
            <b-avatar
              ref="previewEl"
              :src="theAvatar"
              :text="avatarText(userData.fullName)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="90px"
              rounded
            />
          </label>
        </image-uploader>
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-tabs>
        <!-- General Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>General Information</span>
          </template>
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- Field: Username -->
            <b-row>
              <b-col
                md="6"
              >
                <!-- Field: Username -->
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="userData.username"
                      :state="errors.length > 0 ? false:null"
                      :readonly="true"
                      name="username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Client Name -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Client Name"
                  label-for="client_display_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="client_display_name"
                    rules="required"
                  >
                    <b-form-input
                      id="client_display_name"
                      v-model="userData.client_display_name"
                      :readonly="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- name -->
            <b-row>
              <!-- first name -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="userData.first_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Last Name -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="LastName"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="userData.last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Phone -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    :size="10"
                  >
                    <b-input-group>
                      <cleave
                        id="phone"
                        v-model="userData.phone"
                        class="form-control"
                        :raw="true"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <!-- <b-form-input
                      id="phone"
                      v-model="userData.phone"
                      :raw="false"
                      :options="options.phone"
                      placeholder="416 123 1234"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: Email -->
              <b-col
                md="6"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                      type="email"
                      :readonly="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Access Information -->
            <b-row class="mt-2 mb-1 pt-1 border-top-secondary">
              <b-col
                md="12"
              >
                <h4>Access Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Role -->
            <b-row
              v-if="userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin'"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="User Role"
                  label-for="cognito_group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cognito_group"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.cognito_group"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="cognito_group"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Region -->
              <b-col
                v-if="userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin'"
                md="6"
              >
                <b-form-group
                  label="Region"
                  label-for="region_loc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="region_loc"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.region_loc"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="regionOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="region_loc"
                      rules="required"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Role - Client User Accounts -->
            <b-row
              v-if="userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin'"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="User Role"
                  label-for="cognito_group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cognito_group"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.cognito_group"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="roleOptionsClient"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="cognito_group"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Force Onboarding -->
            <b-row
              v-if="userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin'"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Force Onboarding"
                  label-for="is_onboarding"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_onboarding"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.is_onboarding"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="onboardingOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="is_onboarding"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: Options -->
            <b-row
              v-if="userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin'"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="User Duties"
                  label-for="user_duties"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="duties"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="userData.duties.security_scheduling"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Include user in Security Scheduling
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <b-form-checkbox
                      v-model="userData.duties.solutions_installer"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      <h5>
                        Include user as Solutions Installer
                      </h5>
                    </b-form-checkbox>
                    <br>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: is_send_email -->
            <b-row
              v-if="userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin'"
            >
              <b-col
                md="4"
              >
                <b-form-group
                  label=""
                  label-for="is_send_email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_send_email"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_send_email"
                      v-model="userData.is_send_email"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Send Emails
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.alarm_response -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_alarm_response"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_alarm_response"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_alarm_response"
                      v-model="userData.send_email.alarm_response"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Alarm Response
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.general_occurrence -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_general_occurrence"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_general_occurrence"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_general_occurrence"
                      v-model="userData.send_email.general_occurrence"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      General Occurrence Report
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.internal_useof_force -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_internal_useof_force"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_internal_useof_force"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_internal_useof_force"
                      v-model="userData.send_email.internal_useof_force"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Internal Use Of Force Report
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.parking_enforcement -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_parking_enforcement"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_parking_enforcement"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_parking_enforcement"
                      v-model="userData.send_email.parking_enforcement"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Parking Enforcement Report
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.parking_permits -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_parking_permits"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_parking_permits"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_parking_permits"
                      v-model="userData.send_email.parking_permits"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Parking Permit
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: send_email.trespass_notice -->
            <b-row
              v-if="(userData.cognito_group === 'Client_Viewer' || userData.cognito_group === 'Client_Admin') && userData.is_send_email === true"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label=""
                  label-for="is_trespass_notice"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_trespass_notice"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="is_trespass_notice"
                      v-model="userData.send_email.trespass_notice"
                      checked="true"
                      unchecked-value="false"
                      name="check-button"
                      switch
                      inline
                      class="mt-2"
                    >
                      Trespass Notice
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Field: Status -->
            <b-row
              class="mt-2"
            >
              <b-col
                md="6"
              >
                <b-form-group
                  label="Status"
                  label-for="is_active"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="is_active"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.is_active"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="is_active"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-row
              class="mt-2"
            >
              <b-col
                md="2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  type="submit"
                >
                  Save Changes
                </b-button>
              </b-col>
              <b-col
                md="2"
              >
                <b-button
                  v-if="$can('delete', 'users')"
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  @click="deleteUser"
                >
                  Delete User
                </b-button>
              </b-col>
              <b-col md="1" />
              <b-col
                md="3"
              >
                <b-button
                  v-if="$can('delete', 'users')"
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  block
                  @click="sendTemporaryPassword"
                >
                  Send Temporary Passord
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <!-- Onboarding Details -->
        <b-tab
          v-if="itemData && ((userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin') && currentLoggedInUserData.group === 'Management')"
        >
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Onboarding Details</span>
          </template>
          <b-form
            @submit.prevent="handleSubmit(validateDetailsForm)"
          >
            <!-- ***** Personal Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>Personal Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Name -->
            <!-- Field: Middle Initial -->
            <!-- Field: Last Name -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="itemData.first_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Middle Initial"
                  label-for="middle_initial"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="middle_initial"
                  >
                    <b-form-input
                      id="middle_initial"
                      v-model="itemData.middle_initial"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="itemData.last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Street -->
            <!-- Field: City -->
            <!-- Field: Province -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Street"
                  label-for="street"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="street"
                    rules="required"
                  >
                    <b-form-input
                      id="street"
                      v-model="itemData.street"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="itemData.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Province"
                  label-for="province"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="province"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.province"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="provinceOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="province"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Country -->
            <!-- Field: Postal Code -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countryOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="country"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Postal Code"
                  label-for="postal_code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="postal_code"
                    rules="required"
                  >
                    <b-form-input
                      id="postal_code"
                      v-model="itemData.postal_code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Email -->
            <!-- Field: Phone Number -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="itemData.email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Phone Number"
                  label-for="phone_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="phone_number"
                        v-model="itemData.phone_number"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Birthdate -->
            <b-row class="mb-2">
              <b-col md="2">
                <b-form-group
                  label="Birthdate"
                  label-for="birth_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birth_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="birth_date"
                      v-model="itemData.birth_date"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Gender -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Gender"
                  label-for="gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                  >
                    <b-form-radio-group
                      v-model="itemData.gender"
                      :options="genderOptions"
                      name="gender"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Are you indigenous? -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Are you indigenous?"
                  label-for="indian_status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_status"
                  >
                    <b-form-radio-group
                      v-model="itemData.indian_status"
                      :options="indianStatusOptions"
                      name="indian_status"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Indian Registration Information ***** -->
            <b-row
              v-if="itemData.indian_status"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Indian Registration Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Indian Registration Number -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="6">
                <b-form-group
                  label="Indian Registration Number"
                  label-for="indian_reg_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_reg_number"
                  >
                    <b-form-input
                      id="indian_reg_number"
                      v-model="itemData.indian_reg_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: INAC Serial Number -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="6">
                <b-form-group
                  label="INAC Serial Number"
                  label-for="indian_inac_serial"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_inac_serial"
                  >
                    <b-form-input
                      id="indian_inac_serial"
                      v-model="itemData.indian_inac_serial"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Indian Expiry Date -->
            <b-row
              v-if="itemData.indian_status"
            >
              <b-col md="2">
                <b-form-group
                  label="Indian Expiry Date"
                  label-for="indian_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_exp_date"
                  >
                    <flat-pickr
                      id="indian_exp_date"
                      v-model="itemData.indian_exp_date"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Indian Registration Card Image -->
            <b-row
              v-if="itemData.indian_status"
            >
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('permit')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('permit')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.permit_image && itemData.permit_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.permit_image.access_url"
                  :ref="itemData.permit_image.access_url"
                  :key="itemData.permit_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.permit_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** SIN Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>SIN Information</h4>
              </b-col>
            </b-row>
            <!-- Field: SIN Number -->
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="SIN Number"
                  label-for="sin"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="sin"
                    rules="required"
                  >
                    <b-form-input
                      id="sin"
                      v-model="itemData.sin"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: SIN Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="SIN Expiry Date"
                  label-for="sin_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="sin_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="sin_expiry_date"
                      v-model="itemData.sin_expiry_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: SIN Card Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      id="sin_image"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('sin')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('sin')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.sin_image && itemData.sin_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.sin_image.access_url"
                  :ref="itemData.sin_image.access_url"
                  :key="itemData.sin_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.sin_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Work Permit Information ***** -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Work Permit Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Permit Number -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
              class="mb-2"
            >
              <b-col md="6">
                <b-form-group
                  label="Permit Number"
                  label-for="permit"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="permit"
                    rules="required"
                  >
                    <b-form-input
                      id="permit"
                      v-model="itemData.permit"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Permit Expiry Date -->
            <b-row
              v-if="typeof itemData.sin !== undefined && itemData.sin !== null && itemData.sin !== '' && itemData.sin.length > 0 && itemData.sin.slice(0, 1) === '9'"
            >
              <b-col md="2">
                <b-form-group
                  label="Permit Expiry Date"
                  label-for="permit_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="permit_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="permit_expiry_date"
                      v-model="itemData.permit_expiry_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Banking Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Banking Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Bank Number -->
            <!-- Field: Transit Number -->
            <!-- Field: Account Number -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Bank Number"
                  label-for="bank_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="bank_number"
                    rules="required"
                  >
                    <b-form-input
                      id="bank_number"
                      v-model="itemData.bank_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Transit Number"
                  label-for="transit_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="transit_number"
                    rules="required"
                  >
                    <b-form-input
                      id="transit_number"
                      v-model="itemData.transit_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Account Number"
                  label-for="account_number"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="account_number"
                    rules="required"
                  >
                    <b-form-input
                      id="account_number"
                      v-model="itemData.account_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Direct Deposit Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('deposit')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('deposit')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.direct_deposit_image && itemData.direct_deposit_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.direct_deposit_image.access_url"
                  :ref="itemData.direct_deposit_image.access_url"
                  :key="itemData.direct_deposit_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.direct_deposit_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Security Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Security Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Security Licence Number"
                  label-for="security_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence"
                      v-model="itemData.security_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Security Licence Expiry Date"
                  label-for="security_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence_exp_date"
                    :rule="dynamicRequired"
                  >
                    <flat-pickr
                      id="security_licence_exp_date"
                      v-model="itemData.security_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="security_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="security_licence_class"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence_class"
                      v-model="itemData.security_licence_class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="Security Licence Image"
                  label-for="security_licence_image"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="security_licence_image"
                    :rule="dynamicRequired"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      id="security_licence_image"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('security_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('security_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.security_licence_image && itemData.security_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.security_licence_image.access_url"
                  :ref="itemData.security_licence_image.access_url"
                  :key="itemData.security_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.security_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** First Aid Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>First Aid Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Aid Agency Name -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Agency Name"
                  label-for="first_aid_agency"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_agency"
                  >
                    <b-form-input
                      id="first_aid_agency"
                      v-model="itemData.first_aid_agency"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Type -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Type"
                  label-for="first_aid_type"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_type"
                  >
                    <v-select
                      v-model="itemData.first_aid_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firstAidTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="first_aid_type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Certificate Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Certificate Number"
                  label-for="first_aid_certificate"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_certificate"
                  >
                    <b-form-input
                      id="first_aid_certificate"
                      v-model="itemData.first_aid_certificate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="First Aid Expiry Date"
                  label-for="first_aid_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_exp_date"
                  >
                    <flat-pickr
                      id="first_aid_exp_date"
                      v-model="itemData.first_aid_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="First Aid Image"
                  label-for="first_aid_image"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_aid_image"
                    :rule="dynamicRequired"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('first_aid')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('first_aid')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.first_aid_image && itemData.first_aid_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.first_aid_image.access_url"
                  :ref="itemData.first_aid_image.access_url"
                  :key="itemData.first_aid_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.first_aid_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Firearms Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Firearms Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Number"
                  label-for="firearms_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence"
                  >
                    <b-form-input
                      id="firearms_licence"
                      v-model="itemData.firearms_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Firearms Licence Expiry Date"
                  label-for="firearms_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence_exp_date"
                  >
                    <flat-pickr
                      id="firearms_licence_exp_date"
                      v-model="itemData.firearms_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Authorization -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Authorization"
                  label-for="firearms_licence_auth"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firearms_licence_auth"
                  >
                    <v-select
                      v-model="itemData.firearms_licence_auth"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firearmsLicenceAuthOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="firearms_licence_auth"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-form-group
                  class="mb-1 ml-1 mt-2"
                  label="Firearms Licence Image"
                  label-for="firearms_licence_image"
                />
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('firearms_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('firearms_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.firearms_licence_image && itemData.firearms_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.firearms_licence_image.access_url"
                  :ref="itemData.firearms_licence_image.access_url"
                  :key="itemData.firearms_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.firearms_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** Uniform Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Uniform Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Vest Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Vest Size"
                  label-for="uniform_vest"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_vest"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.uniform_vest"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformVestOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_vest"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shirt Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Shirt Size"
                  label-for="uniform_shirt"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shirt"
                    rules="required"
                  >
                    <v-select
                      v-model="itemData.uniform_shirt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformShirtOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_shirt"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Waist -->
            <!-- Field: Length -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Waist (inches)"
                  label-for="uniform_pants_waist"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_waist"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_waist"
                      v-model="itemData.uniform_pants_waist"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Pant Length (inches)"
                  label-for="uniform_pants_length"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_length"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_length"
                      v-model="itemData.uniform_pants_length"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Glove Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Glove Size"
                  label-for="uniform_glove"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_glove"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_glove"
                      v-model="itemData.uniform_glove"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Hat Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Hat Size"
                  label-for="uniform_hat"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_hat"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_hat"
                      v-model="itemData.uniform_hat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shoe Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Shoe Size (US Size)"
                  label-for="uniform_shoe"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shoe"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_shoe"
                      v-model="itemData.uniform_shoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Proof of Identification ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Proof of Identification</h4>
                <div>
                  Please provide <strong>TWO (2)</strong> of the following forms of identification:<br>
                  - Drivers Licence<br>
                  - Passport<br>
                  - Provincial Photo ID<br>
                  - PAL Card<br>
                  - Native Status<br>
                  - Permanent Residence Card / Document<br>
                  - Record of Landing with photo<br><br>
                </div>
              </b-col>
            </b-row>
            <!-- ***** Drivers Licence Information ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h4>Drivers Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Drivers Licence Number"
                  label-for="drivers_licence"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence"
                  >
                    <b-form-input
                      id="drivers_licence"
                      v-model="itemData.drivers_licence"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Issue Date -->
            <!-- Field: Drivers Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Drivers Licence Issue Date"
                  label-for="drivers_licence_issue_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_issue_date"
                  >
                    <flat-pickr
                      id="drivers_licence_issue_date"
                      v-model="itemData.drivers_licence_issue_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Drivers Licence Expiry Date"
                  label-for="drivers_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_exp_date"
                  >
                    <flat-pickr
                      id="drivers_licence_exp_date"
                      v-model="itemData.drivers_licence_exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="drivers_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_class"
                  >
                    <b-form-input
                      id="drivers_licence_class"
                      v-model="itemData.drivers_licence_class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('drivers_licence')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('drivers_licence')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.drivers_licence_image && itemData.drivers_licence_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.drivers_licence_image.access_url"
                  :ref="itemData.drivers_licence_image.access_url"
                  :key="itemData.drivers_licence_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.drivers_licence_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- ***** Additional Form of Identification 1 ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h5>-- OR --</h5>
                <h4>Additional Form of Identification</h4>
              </b-col>
            </b-row>
            <!-- Field: Additional Form of Identification 1 Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('identification1')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('identification1')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.identification1_image && itemData.identification1_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.identification1_image.access_url"
                  :ref="itemData.identification1_image.access_url"
                  :key="itemData.identification1_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.identification1_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- ***** Additional Form of Identification 2 ***** -->
            <b-row
              class="mt-2 mb-1"
            >
              <b-col>
                <h5>-- OR --</h5>
                <h4>Additional Form of Identification</h4>
              </b-col>
            </b-row>
            <!-- Field: Additional Form of Identification 2 Image -->
            <b-row>
              <!-- Button to trigger the file input -->
              <section>
                <b-row class="mb-2 ml-0 mt-2">
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openCamera('identification2')"
                    >
                      Take Picture
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="$can('update', abilityRequired)"
                    md="6"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      block
                      :disabled="preventDoubleClick"
                      @click="openImageSelector('identification2')"
                    >
                      Add Image
                    </b-button>
                  </b-col>
                </b-row>
              </section>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="itemData.identification2_image && itemData.identification2_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="itemData.identification2_image.access_url"
                  :ref="itemData.identification2_image.access_url"
                  :key="itemData.identification2_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="itemData.identification2_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>
            <!-- Buttons -->
            <div>
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="allowApproval && $can('delete', abilityRequired)"
                class="mt-4"
              >
                <b-col
                  v-if="!itemData.approved"
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Approve
                  </b-button>
                </b-col>
                <b-col
                  v-else
                  md="2"
                >
                  <!-- Approve Button -->
                  <b-button
                    variant="secondary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    :disabled="preventDoubleClick"
                    @click="handleApprovalToggle"
                  >
                    Unapprove
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </b-tab>
        <!-- Post Onboarding Details -->
        <b-tab
          v-else-if="!userData.is_onboarding && ((userData.cognito_group !== 'Client_Viewer' && userData.cognito_group !== 'Client_Admin') && currentLoggedInUserData.group === 'Management')"
        >
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Additional Details</span>
          </template>
          <b-form
            @submit.prevent="handleSubmit(validateForm)"
          >
            <!-- ***** Personal Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>Personal Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Name -->
            <!-- Field: Middle Initial -->
            <!-- Field: Last Name -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="userData.first_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Middle Initial"
                  label-for="middle_initial"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="middle_initial"
                  >
                    <b-form-input
                      id="middle_initial"
                      v-model="userData.middle_initial"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="userData.last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Street -->
            <!-- Field: City -->
            <!-- Field: Province -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Street"
                  label-for="street"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="street"
                    rules="required"
                  >
                    <b-form-input
                      id="street"
                      v-model="userData.address.street"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <b-form-input
                      id="city"
                      v-model="userData.address.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Province"
                  label-for="province"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="province"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.address.province"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="provinceOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="province"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Country -->
            <!-- Field: Postal Code -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Country"
                  label-for="country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.address.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countryOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="country"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Postal Code"
                  label-for="postal_code"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="postal_code"
                    rules="required"
                  >
                    <b-form-input
                      id="postal_code"
                      v-model="userData.address.postal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Email -->
            <!-- Field: Phone Number -->
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Phone Number"
                  label-for="phone_number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="phone_number"
                        v-model="userData.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="123 123 1234"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Birthdate -->
            <b-row class="mb-2">
              <b-col md="2">
                <b-form-group
                  label="Birthdate"
                  label-for="birth_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="birth_date"
                    rules="required"
                  >
                    <b-form-input
                      id="email"
                      v-model="userData.dob"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Gender -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Gender"
                  label-for="gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="gender"
                  >
                    <b-form-radio-group
                      v-model="userData.gender"
                      :options="genderOptions"
                      name="gender"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Are you indigenous? -->
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group
                  label="Are you indigenous?"
                  label-for="indian_status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_status"
                  >
                    <b-form-radio-group
                      v-model="userData.indian.status"
                      :options="indianStatusOptions"
                      name="indian_status"
                      stacked
                      class="radio-spacing"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Indian Registration Information ***** -->
            <b-row
              v-if="userData.indian.status"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Indian Registration Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Indian Expiry Date -->
            <b-row
              v-if="userData.indian.status"
            >
              <b-col md="2">
                <b-form-group
                  label="Indian Expiry Date"
                  label-for="indian_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="indian_exp_date"
                  >
                    <flat-pickr
                      id="indian_exp_date"
                      v-model="userData.indian.exp_date"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Images List -->
              <section
                v-if="userData.permit_image && userData.permit_image.access_url"
                class="grid-view ml-1 mt-2"
              >
                <b-card
                  :id="userData.permit_image.access_url"
                  :ref="userData.permit_image.access_url"
                  :key="userData.permit_image.access_url"
                  no-body
                >
                  <div class="item-img text-center">
                    <thumbs-lazy-image-component
                      class="card-img-top"
                      :src="userData.permit_image.access_url"
                      src-placeholder="@/assets/images/noImage.png"
                    />
                  </div>
                </b-card>
              </section>
            </b-row>

            <!-- ***** SIN Information ***** -->
            <b-row class="mt-2 mb-1 border-bottom-secondary">
              <b-col>
                <h4>SIN Information</h4>
              </b-col>
            </b-row>
            <!-- Field: SIN Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="SIN Expiry Date"
                  label-for="sin_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="sin_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="sin_expiry_date"
                      v-model="userData.sin.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Work Permit Information ***** -->
            <b-row
              v-if="typeof userData.permit !== undefined && userData.permit !== null"
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Work Permit Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Permit Expiry Date -->
            <b-row
              v-if="typeof userData.permit !== undefined && userData.permit !== null"
            >
              <b-col md="2">
                <b-form-group
                  label="Permit Expiry Date"
                  label-for="permit_expiry_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="permit_expiry_date"
                    rules="required"
                  >
                    <flat-pickr
                      id="permit_expiry_date"
                      v-model="userData.permit.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Security Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Security Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Security Licence Number"
                  label-for="security_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence"
                      v-model="userData.security_licence.number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Security Licence Expiry Date"
                  label-for="security_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="security_licence_exp_date"
                    :rule="dynamicRequired"
                  >
                    <flat-pickr
                      id="security_licence_exp_date"
                      v-model="userData.security_licence.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Security Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="security_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="security_licence_class"
                    :rule="dynamicRequired"
                  >
                    <b-form-input
                      id="security_licence_class"
                      v-model="userData.security_licence.class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** First Aid Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>First Aid Information</h4>
              </b-col>
            </b-row>
            <!-- Field: First Aid Agency Name -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Agency Name"
                  label-for="first_aid_agency"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_agency"
                  >
                    <b-form-input
                      id="first_aid_agency"
                      v-model="userData.first_aid.agency"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Type -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Type"
                  label-for="first_aid_type"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_type"
                  >
                    <v-select
                      v-model="userData.first_aid.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firstAidTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="first_aid_type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Certificate Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="First Aid Certificate Number"
                  label-for="first_aid_certificate"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_certificate"
                  >
                    <b-form-input
                      id="first_aid_certificate"
                      v-model="userData.first_aid.certificate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: First Aid Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="First Aid Expiry Date"
                  label-for="first_aid_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="first_aid_exp_date"
                  >
                    <flat-pickr
                      id="first_aid_exp_date"
                      v-model="userData.first_aid.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Firearms Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Firearms Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Number -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Number"
                  label-for="firearms_licence"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence"
                  >
                    <b-form-input
                      id="firearms_licence"
                      v-model="userData.firearms_licence.number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Firearms Licence Expiry Date"
                  label-for="firearms_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="firearms_licence_exp_date"
                  >
                    <flat-pickr
                      id="firearms_licence_exp_date"
                      v-model="userData.firearms_licence.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Firearms Licence Authorization -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Firearms Licence Authorization"
                  label-for="firearms_licence_auth"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firearms_licence_auth"
                  >
                    <v-select
                      v-model="userData.firearms_licence.auth"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="firearmsLicenceAuthOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="firearms_licence_auth"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Uniform Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Uniform Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Vest Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Vest Size"
                  label-for="uniform_vest"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_vest"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.uniform.vest"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformVestOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_vest"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shirt Size -->
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Shirt Size"
                  label-for="uniform_shirt"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shirt"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.uniform.shirt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="uniformShirtOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="uniform_shirt"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Waist -->
            <!-- Field: Length -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Waist (inches)"
                  label-for="uniform_pants_waist"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_waist"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_waist"
                      v-model="userData.uniform.pants_waist"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Pant Length (inches)"
                  label-for="uniform_pants_length"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_pants_length"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_pants_length"
                      v-model="userData.uniform.pants_length"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Glove Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Glove Size"
                  label-for="uniform_glove"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_glove"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_glove"
                      v-model="userData.uniform.glove"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Hat Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Hat Size"
                  label-for="uniform_hat"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_hat"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_hat"
                      v-model="userData.uniform.hat"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Shoe Size -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Shoe Size (US Size)"
                  label-for="uniform_shoe"
                >
                  <validation-provider
                    #default="{ errors }"

                    name="uniform_shoe"
                    rules="required"
                  >
                    <b-form-input
                      id="uniform_shoe"
                      v-model="userData.uniform.shoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- ***** Drivers Licence Information ***** -->
            <b-row
              class="mt-2 mb-1 border-bottom-secondary"
            >
              <b-col>
                <h4>Drivers Licence Information</h4>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Issue Date -->
            <!-- Field: Drivers Licence Expiry Date -->
            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Drivers Licence Expiry Date"
                  label-for="drivers_licence_exp_date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_exp_date"
                  >
                    <flat-pickr
                      id="drivers_licence_exp_date"
                      v-model="userData.drivers_licence.exp_date"
                      class="form-control"
                      :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Field: Drivers Licence Class -->
            <b-row>
              <b-col md="1">
                <b-form-group
                  label="Licence Class"
                  label-for="drivers_licence_class"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="drivers_licence_class"
                  >
                    <b-form-input
                      id="drivers_licence_class"
                      v-model="userData.drivers_licence.class"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Buttons -->
            <div>
              <b-row class="mt-3">
                <b-col
                  v-if="$can('update', abilityRequired)"
                  md="2"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    type="submit"
                    :disabled="preventDoubleClick"
                  >
                    Save Changes
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import { Storage, Auth } from 'aws-amplify'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { required, email } from '@validations'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox, BTabs, BTab, BInputGroup, BFormRadioGroup, BCard,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// Phone number imports
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ImageUploader from 'vue-image-upload-resize'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import useUsersList from '../users-list/useUsersList'
import ability from '../../../../libs/acl/ability'

import ThumbsLazyImageComponent from '../../onboarding/manage/thumbsLazyImageComponent'
import storeModule from '../../onboarding/storeModule'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    flatPickr,
    Cleave,
    BFormRadioGroup,
    BCard,
    ThumbsLazyImageComponent,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: null,
    },
    clientNameProp: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
      clients: [],
      clientsList: [],
      avatarImageUpload: {},
      preventDoubleClick: false,
      // Phone number imports
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      itemData: null,
    }
  },
  setup(props) {
    console.log('Setup Edit User Tab Account')
    const currentLoggedInUserData = getUserData()

    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = store.state.appSettings.cognito_group

    const roleOptionsClient = store.state.appSettings.cognito_group_clients

    const regionOptions = store.state.appSettings.regions_loc

    const statusOptions = store.state.appSettings.is_active

    const onboardingOptions = store.state.appSettings.is_onboarding

    const cellProviderOptions = store.state.appSettings.cell_phone_carrier

    const genderOptions = store.state.appSettings.gender_options
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const theAvatar = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const { username } = props.userData

    const dynamicRequired = ref('')
    const indianStatusOptions = store.state.appSettings.indian_status_options
    const provinceOptions = store.state.appSettings.provinces
    const countryOptions = store.state.appSettings.countries
    const uniformVestOptions = store.state.appSettings.uniform_vest_options
    const uniformShirtOptions = store.state.appSettings.uniform_shirt_options
    const firstAidTypeOptions = store.state.appSettings.first_aid_type_options
    const firearmsLicenceAuthOptions = store.state.appSettings.firearms_licence_auth_options

    const abilityRequired = 'onboarding'
    const storeModuleName = 'app-onboarding'
    const itemTypeName = 'Onboarding'
    const printTitle = 'Onboarding'
    const allowPrinting = false
    const allowApproval = true
    const filesDir = 'onboarding'
    const maxFiles = 1 // Use 0 for no files otherwise specify max number
    const supportedFileTypes = ['*'] // Use * for all types, otherwise use an array of file extensions
    const maxImages = 10 // Use 0 for no images otherwise specify max number
    const supportedImageTypes = ['jpg']

    if (!store.hasModule(storeModuleName)) store.registerModule(storeModuleName, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(storeModuleName)) store.unregisterModule(storeModuleName)
    })

    return {
      currentLoggedInUserData,
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      roleOptionsClient,
      regionOptions,
      statusOptions,
      onboardingOptions,
      cellProviderOptions,
      genderOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
      theAvatar,
      dynamicRequired,
      indianStatusOptions,
      provinceOptions,
      countryOptions,
      uniformVestOptions,
      uniformShirtOptions,
      firstAidTypeOptions,
      firearmsLicenceAuthOptions,

      abilityRequired,
      storeModuleName,
      itemTypeName,
      printTitle,
      allowPrinting,
      allowApproval,
      filesDir,
      maxFiles,
      supportedFileTypes,
      maxImages,
      supportedImageTypes,
      username,
    }
  },
  created() {
    if (this.clientNameProp !== '') {
      this.userData.client_name = this.clientNameProp
    }

    if (typeof this.userData.region_loc === 'undefined') {
      this.userData.region_loc = 'All'
    }

    if (typeof this.userData.duties === 'undefined') {
      this.userData.duties = {
        security_scheduling: false, solutions_installer: false,
      }
    }

    if (typeof this.userData.indian === 'undefined') {
      this.userData.indian = {
        status: false,
      }
    }

    if (typeof this.userData.is_send_email === 'undefined') {
      this.userData.is_send_email = false
    }
    if (typeof this.userData.send_email === 'undefined') {
      this.userData.send_email = {}
    }
    if (this.userData.role === 'Guard') {
      this.dynamicRequired = 'required'
    }

    this.clientsList = []

    this.clients = JSON.parse(localStorage.getItem('clients'))
    this.clients.forEach(item => {
      this.clientsList.push({ label: item.display_name, value: item.clientname })
    })
    console.log('clientsList')
    console.log(this.clientsList)

    if (this.userData.is_onboarding) {
      this.getExistingOnboardingData()
    }
  },
  methods: {
    isNumeric(str) {
      if (typeof str !== 'string') return false // we only process strings!
      return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getExistingOnboardingData() {
      console.log('getExistingOnboardingData')
      if (this.username !== null && ability.can('update', this.abilityRequired)) {
        console.log('getting item data')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Getting ${this.itemTypeName} Data`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        const qvars = {
          abilityRequired: this.abilityRequired,
          username: this.username,
        }
        store.dispatch(`${this.storeModuleName}/fetchItem`, qvars)
          .then(response => {
            console.log(response)
            this.itemData = response.data
            if (this.itemData === null) {
              console.log('No itemData - Use logged in data')
              this.itemData = {}
              if (this.currentLoggedInUserData.Is_onboarding === true) {
                this.itemData.username = this.currentLoggedInUserData.username
                this.itemData.first_name = this.currentLoggedInUserData.first_name
                this.itemData.last_name = this.currentLoggedInUserData.last_name
                this.itemData.phone = this.currentLoggedInUserData.phone
                this.itemData.email = this.currentLoggedInUserData.email
                this.itemData.role = this.currentLoggedInUserData.role
                this.itemData.cognito_group = this.currentLoggedInUserData.role
              } else {
                this.itemData.username = this.username
                this.itemData.first_name = null
                this.itemData.last_name = null
                this.itemData.phone = null
                this.itemData.email = null
                this.itemData.role = null
              }
              this.itemData.sin = null
              this.itemData.province = 'Ontario'
              this.itemData.country = 'Canada'
              this.isNew = true
            } else {
              if (typeof this.itemData.country === 'undefined' || this.itemData.country === null) {
                this.itemData.country = 'Canada'
              }
              if (typeof this.itemData.province === 'undefined' || this.itemData.province === null) {
                this.itemData.country = 'Ontario'
              }
            }
            if (this.itemData.role === 'Guard') {
              this.dynamicRequired = 'required'
            }
            console.log(this.itemData)
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error Getting ${this.itemTypeName}.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    validateForm() {
      console.log('validateForm')
      console.log(ability.can('update', 'users'))
      if (ability.can('update', 'users')) {
        // Temporary Add: Adding additional fields to data set that will be included in for at later date
        const proceedWithSave = true
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            this.userData.fullName = `${this.userData.first_name} ${this.userData.last_name}`
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.userData)
              // Create a clean dataset with only the data being updated
              const cleanUserData = JSON.parse(JSON.stringify(this.userData))
              cleanUserData.phone = cleanUserData.phone.replaceAll(' ', '')
              delete cleanUserData.abilities
              delete cleanUserData.authorized_clients
              delete cleanUserData.region
              console.log(cleanUserData)
              // Call backend
              store.dispatch('app-user/updateUser', cleanUserData)
                .then(response => {
                  console.log(response)
                  this.preventDoubleClick = false
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'User Updated',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error updating user information.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    validateDetailsForm() {
      console.log('validateDetailsForm')
      let proceedWithSave = false
      let dispatchLocation = ''
      let toastText = ''
      let processType = null
      if (ability.can('update', this.abilityRequired) || (this.currentLoggedInUserData.cognito_group !== 'Client_Viewer' && this.currentLoggedInUserData.cognito_group !== 'Client_Admin' && this.currentLoggedInUserData.Is_onboarding === true)) {
        console.log('editing report')
        processType = 'update'
        proceedWithSave = true
        dispatchLocation = `${this.storeModuleName}/updateItem`
        toastText = 'Updat'
      }
      // Additional Manual Validation
      // First Nations Registration Card Image
      if (this.itemData.indian_status
        && (typeof this.itemData.indian_image === 'undefined' || this.itemData.indian_image === null
        || typeof this.itemData.indian_image.access_url === 'undefined' || this.itemData.indian_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your First Nations Registration Card',
          },
        })
      }
      // SIN Card Image
      if (typeof this.itemData.sin_image === 'undefined' || this.itemData.sin_image === null
        || typeof this.itemData.sin_image.access_url === 'undefined' || this.itemData.sin_image.access_url === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your SIN Card',
          },
        })
      }
      // Direct Deposit Image
      if (typeof this.itemData.direct_deposit_image === 'undefined' || this.itemData.direct_deposit_image === null
        || typeof this.itemData.direct_deposit_image.access_url === 'undefined' || this.itemData.direct_deposit_image.access_url === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Direct Deposit Information',
          },
        })
      }
      // Security Licence Image
      if (this.dynamicRequired && (typeof this.itemData.security_licence_image === 'undefined' || this.itemData.security_licence_image === null
        || typeof this.itemData.security_licence_image.access_url === 'undefined' || this.itemData.security_licence_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Security Licence',
          },
        })
      }
      // First Aid Image
      if ((typeof this.itemData.first_aid_type !== 'undefined' && this.itemData.first_aid_type !== null) && (typeof this.itemData.first_aid_image === 'undefined' || this.itemData.first_aid_image === null
        || typeof this.itemData.first_aid_image.access_url === 'undefined' || this.itemData.first_aid_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your First Aid Card',
          },
        })
      }
      // Firearms Licence Image
      if ((typeof this.itemData.firearms_licence !== 'undefined' && this.itemData.firearms_licence !== null)
        && (typeof this.itemData.firearms_licence_image === 'undefined' || this.itemData.firearms_licence_image === null
        || typeof this.itemData.firearms_licence_image.access_url === 'undefined' || this.itemData.firearms_licence_image.access_url === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide an Image of your Firearms Licence',
          },
        })
      }
      // Identification count
      let idCount = 0
      if (typeof this.itemData.drivers_licence_image !== 'undefined' && typeof this.itemData.drivers_licence_image.filename !== 'undefined' && this.itemData.drivers_licence_image.filename !== null) {
        idCount += 1
      }
      if (typeof this.itemData.identification1_image !== 'undefined' && typeof this.itemData.identification1_image.filename !== 'undefined' && this.itemData.identification1_image.filename !== null) {
        idCount += 1
      }
      if (typeof this.itemData.identification2_image !== 'undefined' && typeof this.itemData.identification2_image.filename !== 'undefined' && this.itemData.identification2_image.filename !== null) {
        idCount += 1
      }
      if (idCount < 2) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error ${toastText}ing ${this.itemTypeName}.`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'You must provide at least 2 forms of Identification including images',
          },
        })
        proceedWithSave = false
      }
      if (proceedWithSave) {
        console.log(this.itemData)
        console.log(JSON.parse(JSON.stringify(this.itemData)))
        if (proceedWithSave) {
          this.$refs.observer.validate().then(success => {
            if (success) {
              this.preventDoubleClick = true
              console.log('form validated')
              console.log(this.itemData)
              // Create a clean dataset with only the data being updated
              const cleanData = JSON.parse(JSON.stringify(this.itemData))
              // delete cleanData.abilities
              console.log(cleanData)
              // Call backend
              store.dispatch(`${dispatchLocation}`, cleanData)
                .then(response => {
                  this.preventDoubleClick = false
                  console.log(response)
                  if (processType === 'add') {
                    this.username = response.username
                  }
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.itemTypeName} ${toastText}ed`,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  // TODO: If the current user is onboarding then need to show the messaging discribing the DocuSign process (including a link)
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error ${toastText}ing ${this.itemTypeName}.`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
        }
      }
    },
    deleteUser() {
      if (ability.can('delete', 'users')) {
        this.preventDoubleClick = true
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete ${this.userData.first_name} ${this.userData.last_name}'s user account.`, {
            title: 'Delete User Account',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                username: this.userData.username,
                clientname: this.userData.client_name,
              }
              store
                .dispatch('app-user/deleteUser', qvars)
                .then(response => {
                  console.log(response)
                  // Route user
                  const nextRout = { name: 'apps-users-list' }
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'User Account deleted successfully.',
                          icon: 'CheckSquareIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting User Account',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    sendTemporaryPassword() {
      if (ability.can('update', 'users')) {
        this.preventDoubleClick = true
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to reset ${this.userData.first_name} ${this.userData.last_name}'s password. ${this.userData.first_name} ${this.userData.last_name} will be emailed a temporary password at the email address above and must login and change the password within 48 hours.`, {
            title: 'Password Reset',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                username: this.userData.username,
              }
              store
                .dispatch('app-user/sendTemporaryPassword', qvars)
                .then(response => {
                  console.log(response)
                  this.preventDoubleClick = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Password reset and emailed successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.preventDoubleClick = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error resetting password.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    inputAvatar(imgFile) {
      console.log('inputAvatar')
      console.log(imgFile)
      this.uploadAvatar(imgFile)
    },
    uploadAvatar(imgFile) {
      console.log(this.firmwareData)
      if (ability.can('update', 'users')) {
        const filename = `useravatars/${this.userData.username}.jpg`

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Avatar is being uploaded. Please do not leave this screen.',
            icon: 'FilePlusIcon',
            variant: 'success',
          },
        })

        Storage.put(filename, imgFile, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: process.env.VUE_APP_AMPLIFY_STORAGE_AWSS3_IDENTITYPOOLID,
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            console.log(result)
            this.userData.avatar = filename
            this.theAvatar = imgFile
          })
          .catch(err => {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading Avatar Image.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    openCamera(imageType) {
      this.imageType = imageType
      this.$refs.cameraInput.click()
    },
    openImageSelector(imageType) {
      this.imageType = imageType
      this.$refs.imageInput.click()
    },
    handleImageChange(event) {
      const file = event.target.files[0]
      const supportedExtensions = ['jpg', 'png', 'tiff', 'gif']
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        if (supportedExtensions.includes(fileExtension)) {
          let theFileName = ''
          let theFullFileName = ''
          if (this.imageType === 'sin') {
            this.itemData.sin_image = {}
            theFileName = `${this.username}_sin_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'permit') {
            this.itemData.permit_image = {}
            theFileName = `${this.username}_permit_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'deposit') {
            this.itemData.direct_deposit_image = {}
            theFileName = `${this.username}_direct_deposit_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'security_licence') {
            this.itemData.security_licence_image = {}
            theFileName = `${this.username}_security_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'first_aid') {
            this.itemData.first_aid_image = {}
            theFileName = `${this.username}_first_aid_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'firearms_licence') {
            this.itemData.firearms_licence_image = {}
            theFileName = `${this.username}_firearms_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'drivers_licence') {
            this.itemData.drivers_licence_image = {}
            theFileName = `${this.username}_drivers_licence_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'identification1') {
            this.itemData.identification1_image = {}
            theFileName = `${this.username}_identification1_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          } else if (this.imageType === 'identification2') {
            this.itemData.identification2_image = {}
            theFileName = `${this.username}_identification2_image.jpg`
            theFullFileName = `onboarding/${this.itemData.username}/${theFileName}`
          }
          this.preventDoubleClick = true
          Storage.put(theFullFileName, file, {
            bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
            region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
            identityPoolId: 'ca-central-1_Ao5uWjpYj',
            custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
          })
            .then(result => {
              if (this.imageType === 'sin') {
                this.itemData.sin_image = {}
                this.itemData.sin_image.filename = theFileName
                this.itemData.sin_image.access_url = result.key
                this.itemData.sin_image.original_ext = fileExtension
              } else if (this.imageType === 'permit') {
                this.itemData.permit_image = {}
                this.itemData.permit_image.filename = theFileName
                this.itemData.permit_image.access_url = result.key
                this.itemData.permit_image.original_ext = fileExtension
              } else if (this.imageType === 'deposit') {
                this.itemData.direct_deposit_image = {}
                this.itemData.direct_deposit_image.filename = theFileName
                this.itemData.direct_deposit_image.access_url = result.key
                this.itemData.direct_deposit_image.original_ext = fileExtension
              } else if (this.imageType === 'security_licence') {
                this.itemData.security_licence_image = {}
                this.itemData.security_licence_image.filename = theFileName
                this.itemData.security_licence_image.access_url = result.key
                this.itemData.security_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'first_aid') {
                this.itemData.first_aid_image = {}
                this.itemData.first_aid_image.filename = theFileName
                this.itemData.first_aid_image.access_url = result.key
                this.itemData.first_aid_image.original_ext = fileExtension
              } else if (this.imageType === 'firearms_licence') {
                this.itemData.firearms_licence_image = {}
                this.itemData.firearms_licence_image.filename = theFileName
                this.itemData.firearms_licence_image.access_url = result.key
                this.itemData.firearms_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'drivers_licence') {
                this.itemData.drivers_licence_image = {}
                this.itemData.drivers_licence_image.filename = theFileName
                this.itemData.drivers_licence_image.access_url = result.key
                this.itemData.drivers_licence_image.original_ext = fileExtension
              } else if (this.imageType === 'identification1') {
                this.itemData.identification1_image = {}
                this.itemData.identification1_image.filename = theFileName
                this.itemData.identification1_image.access_url = result.key
                this.itemData.identification1_image.original_ext = fileExtension
              } else if (this.imageType === 'identification2') {
                this.itemData.identification2_image = {}
                this.itemData.identification2_image.filename = theFileName
                this.itemData.identification2_image.access_url = result.key
                this.itemData.identification2_image.original_ext = fileExtension
              }
              this.imageType = ''
              this.preventDoubleClick = false
            })
            .catch(err => {
              console.log(err)
              this.imageType = ''
              this.preventDoubleClick = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Uploading File.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.imageType = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Invalid File Type. Must be JPG, TIFF, PNG, or GIF.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    deleteImage(imageType) {
      console.log('deleteimage')
      console.log(imageType)
      this.preventDoubleClick = true
      let theURL = ''
      if (imageType === 'sin') {
        theURL = this.itemData.sin_image.access_url
      }
      Storage.remove(theURL, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          console.log(this.itemData)
          // Update the item data deleting the image
          if (imageType === 'sin') {
            console.log('Delete from data')
            delete this.itemData.sin_image
          }
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    openFileSelector() {
      // Trigger the file input click event
      this.$refs.fileInput.click()
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        const parts = file.name.split('.')
        if (parts.length === 1) {
          throw new Error('File not valid')
        }
        const fileExtension = parts.pop().toLowerCase()
        const origFileName = file.name.slice(0, (file.name.length - fileExtension.length - 1))
        const theFileName = `${origFileName}_${moment(Date()).format('YYYYMMDDTHH-mm-ss')}.${fileExtension}`
        const theFullFileName = `${this.filesDir}/${theFileName}`
        this.preventDoubleClick = true
        Storage.put(theFullFileName, file, {
          bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
          region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
          identityPoolId: 'ca-central-1_Ao5uWjpYj',
          custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
        })
          .then(result => {
            this.itemData.attachment_list.push({
              filename: theFileName,
              access_url: result.key,
              original_ext: fileExtension,
            })
            this.validateDetailsForm()
          })
          .catch(err => {
            console.log(err)
            this.preventDoubleClick = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error Uploading File.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    downloadFile(item) {
      console.log('downloadFile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.get(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(url => {
          const link = document.createElement('a')
          link.href = url
          link.download = item.filename || 'download' // you can set a default filename if item.filename is not available
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.preventDoubleClick = false
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Downloading File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    deleteFile(item) {
      console.log('deletefile')
      console.log(item)
      this.preventDoubleClick = true
      Storage.remove(item.access_url, {
        bucket: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_BUCKET,
        region: process.env.VUE_APP_AMPLIFY_CANCOM_FILES_AWSS3_REGION,
        identityPoolId: 'ca-central-1_Ao5uWjpYj',
        custom_header: async () => ({ Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }),
      })
        .then(result => {
          console.log(result)
          // Update the item data deleting the item from the attachment list
          this.itemData.attachment_list.forEach((item1, index) => {
            if (item1.access_url === item.access_url) {
              this.itemData.attachment_list.splice(index, 1)
            }
          })
          this.validateDetailsForm()
        })
        .catch(err => {
          console.log(err)
          this.preventDoubleClick = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error Deleting File.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    handleApprovalToggle() {
      console.log('handleApprovalToggle')
      if (this.itemData.approved) {
        this.itemData.approved = false
      } else {
        this.itemData.approved = true
      }
      this.validateDetailsForm()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
#fileInput {
  display: none;
}
</style>
