export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      resource: 'developer',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      resource: 'developer',
      action: 'read',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      resource: 'developer',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
      resource: 'developer',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Abilities Templates ---- ---------------*
  // *===============================================---*
  {
    path: '/apps/abilitiestemplates/list',
    name: 'apps-abilities-list',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-list/AbilitiesTemplatesList.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'create',
    },
  },
  {
    path: '/apps/abilitiestemplates/add/',
    name: 'apps-abilities-add',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-add/AbilitiesTemplatesAdd.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          to: { name: 'apps-abilities-list' },
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'create',
    },
  },
  {
    path: '/apps/abilitiestemplates/edit/',
    name: 'apps-abilities-edit',
    component: () => import('@/views/apps/abilitiestemplates/abilitiestemplates-edit/AbilitiesTemplatesEdit.vue'),
    meta: {
      pageTitle: 'Abilities Templates',
      breadcrumb: [
        {
          text: 'Abilities Templates',
          to: { name: 'apps-abilities-list' },
        },
        {
          text: 'Edit Template',
          active: true,
        },
      ],
      resource: 'abilitiestemplates',
      action: 'update',
    },
  },
  // *===============================================---*
  // *--------- Firmware Management ---- ---------------*
  // *===============================================---*
  {
    path: '/apps/firmware/list',
    name: 'apps-firmware-list',
    component: () => import('@/views/apps/firmware/firmware-list/FirmwareList.vue'),
    meta: {
      pageTitle: 'Firmware Management',
      breadcrumb: [
        {
          text: 'Firmware',
          active: true,
        },
      ],
      resource: 'firmwaremanagement',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- USER ---- ------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/users/view/:username',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/users/edit/:username',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'users',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- Clients ---- ---------------------------*
  // *===============================================---*
  {
    path: '/apps/clients/list',
    name: 'apps-clients-list',
    component: () => import('@/views/apps/clients/clients-list/ClientsList.vue'),
    meta: {
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/view/:clientname',
    name: 'apps-clients-view',
    component: () => import('@/views/apps/clients/clients-view/ClientsView.vue'),
    meta: {
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/edit/:clientname',
    name: 'apps-clients-edit',
    component: () => import('@/views/apps/clients/clients-edit/ClientsEdit.vue'),
    meta: {
      resource: 'clients',
      action: 'update',
    },
  },
  {
    path: '/apps/clients/clientsites/view/:clientname/:sitename',
    name: 'apps-client-sites-view',
    component: () => import('@/views/apps/clients/clientsites-view/ClientSitesView.vue'),
    meta: {
      resource: 'clients',
      action: 'read',
    },
  },
  {
    path: '/apps/clients/clientsites/edit/:clientname/:sitename',
    name: 'apps-client-sites-edit',
    component: () => import('@/views/apps/clients/clientsites-edit/ClientSitesEdit.vue'),
    meta: {
      resource: 'clients',
      action: 'update',
    },
  },

  // *===============================================---*
  // *--------- Camera Console ---- --------------------*
  // *===============================================---*
  {
    path: '/apps/cameras/list',
    name: 'apps-cameraconsole-list',
    component: () => import('@/views/apps/cameraconsole/cameraconsole-list/CameraconsoleList.vue'),
    meta: {
      pageTitle: 'Cameras',
      breadcrumb: [
        {
          text: 'Cameras',
          active: true,
        },
      ],
      resource: 'cameras',
      action: 'read',
    },
  },
  {
    path: '/apps/cameras/edit/:cameraid',
    name: 'apps-cameraconsole-edit',
    component: () => import('@/views/apps/cameraconsole/cameraconsole-cameraview/CamerasManagementEdit.vue'),
    meta: {
      pageTitle: 'Cameras',
      breadcrumb: [
        {
          text: 'Cameras',
          to: { name: 'apps-cameraconsole-list' },
        },
        {
          text: 'Camera Details',
          active: true,
        },
      ],
      resource: 'cameras',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Cameras Management ---- ----------------*
  // *===============================================---*
  {
    path: '/apps/camerasmanagement/list',
    name: 'apps-cameras-management-list',
    component: () => import('@/views/apps/cameras/cameras-management-list/CamerasManagementList.vue'),
    meta: {
      pageTitle: 'Cameras Management',
      breadcrumb: [
        {
          text: 'Cameras',
          active: true,
        },
      ],
      resource: 'camerasmanagement',
      action: 'read',
    },
  },
  {
    path: '/apps/camerasmanagement/edit/:cameraid',
    name: 'apps-cameras-management-edit',
    component: () => import('@/views/apps/cameras/cameras-management-edit/CamerasManagementEdit.vue'),
    meta: {
      pageTitle: 'Cameras Management',
      breadcrumb: [
        {
          text: 'Cameras',
          to: { name: 'apps-cameras-management-list' },
        },
        {
          text: 'Edit Camera',
          active: true,
        },
      ],
      resource: 'camerasmanagement',
      action: 'update',
    },
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      resource: 'users',
      action: 'read',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      resource: 'Auth',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      resource: 'Auth',
    },
  },

  // *===============================================---*
  // *--------- Reports                 ----------------*
  // *===============================================---*
  // Reports Types Selection
  {
    path: '/apps/reports/type',
    name: 'apps-reports-type',
    component: () => import('@/views/apps/reports/reports-list/ReportTypeSelection.vue'),
    meta: {
      pageTitle: 'Reports Type',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Reports Type',
          active: true,
        },
      ],
      resource: 'reports',
      action: 'read',
    },
  },
  // Reports List
  {
    path: '/apps/reports/list/:currentReportType',
    name: 'apps-reports-list',
    component: () => import('@/views/apps/reports/reports-list/ReportsList.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
      ],
      resource: 'reports',
      action: 'read',
    },
  },
  // Alarm Response Report - Not complete
  {
    path: '/apps/reports/alarmresponse',
    name: 'apps-reports-alarm_response',
    component: () => import('@/views/apps/reports/alarmresponse/alarmResponseEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // General Occurrence Report - Not completed
  {
    path: '/apps/reports/generaloccurrence',
    name: 'apps-reports-general_occurrence',
    component: () => import('@/views/apps/reports/generaloccurrence/GeneralOccurrenceEditReport.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Internal Use Of Force Report Not complete
  {
    path: '/apps/reports/internaluseofforce',
    name: 'apps-reports-internal_useof_force',
    component: () => import('@/views/apps/reports/internaluseofforce/InternalUseOfForceEditReport.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Parking Enforcement Report - Not complete
  {
    path: '/apps/reports/parkingenforcement',
    name: 'apps-reports-parking_enforcement',
    component: () => import('@/views/apps/reports/parkingenforcement/ParkingEnforcementReportEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Parking Permit Report - Not complete
  {
    path: '/apps/reports/parkingpermits',
    name: 'apps-reports-parking_permits',
    component: () => import('@/views/apps/reports/parkingpermits/parkingPermitsEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Shift Log Not complete
  {
    path: '/apps/reports/shiftLog',
    name: 'apps-reports-security_log',
    component: () => import('@/views/apps/reports/shiftlogs/ShiftLogEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Trespass Notice - Not complete
  {
    path: '/apps/reports/trespassnotice',
    name: 'apps-reports-trespass_notice',
    component: () => import('@/views/apps/reports/trespassnotice/trespassnoticeEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // Vehicle Log - Not complete
  {
    path: '/apps/reports/vehiclelog',
    name: 'apps-reports-vehicle_log',
    component: () => import('@/views/apps/reports/vehiclelog/vehicleLogEdit.vue'),
    meta: {
      resource: 'reports',
      action: 'create',
    },
  },
  // *===============================================---*
  // *--------- Post Orders             ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/postorders/list/',
    name: 'apps-postorders',
    component: () => import('@/views/apps/postorders/list/itemsList.vue'),
    meta: {
      pageTitle: 'Post Orders',
      breadcrumb: [
        {
          text: 'Post Orders',
          active: true,
        },
      ],
      resource: 'postorders',
      action: 'read',
    },
  },
  // Add / Edit Form
  {
    path: '/apps/postorders/manage',
    name: 'apps-postorders-manage',
    component: () => import('@/views/apps/postorders/manage/itemEdit.vue'),
    meta: {
      resource: 'postorders',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Corporate Policies      ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/corporatepolicy/list/',
    name: 'apps-corporatepolicy',
    component: () => import('@/views/apps/corporatepolicies/list/itemsList.vue'),
    meta: {
      pageTitle: 'Corporate Policies',
      breadcrumb: [
        {
          text: 'Corporate Policies',
          active: true,
        },
      ],
      resource: 'corporatepolicy',
      action: 'read',
    },
  },
  // Add / Edit Form
  {
    path: '/apps/corporatepolicies/manage',
    name: 'apps-corporatepolicy-manage',
    component: () => import('@/views/apps/corporatepolicies/manage/itemEdit.vue'),
    meta: {
      resource: 'corporatepolicy',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Alerts      ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/alerts/list/',
    name: 'apps-alerts',
    component: () => import('@/views/apps/alerts/list/itemsList.vue'),
    meta: {
      pageTitle: 'Alerts',
      breadcrumb: [
        {
          text: 'Alerts',
          active: true,
        },
      ],
      resource: 'alerts',
      action: 'create',
    },
  },
  // List - View Only
  {
    path: '/apps/alerts/listview/',
    name: 'apps-alerts-listview',
    component: () => import('@/views/apps/alerts/list/itemsListView.vue'),
    meta: {
      resource: 'alerts',
      action: 'read',
    },
  },
  // Add / Edit Form
  {
    path: '/apps/alerts/manage',
    name: 'apps-alerts-manage',
    component: () => import('@/views/apps/alerts/manage/itemEdit.vue'),
    meta: {
      resource: 'alerts',
      action: 'create',
    },
  },
  // *===============================================---*
  // *--------- Patrols      ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/patrols/list/',
    name: 'apps-patrols',
    component: () => import('@/views/apps/patrols/list/itemsList.vue'),
    meta: {
      pageTitle: 'Patrols',
      breadcrumb: [
        {
          text: 'Patrols',
          active: true,
        },
      ],
      resource: 'patrols',
      action: 'read',
    },
  },
  // Templates List
  {
    path: '/apps/patrols/admin/',
    name: 'apps-patrols-admin',
    component: () => import('@/views/apps/patrols/admin/itemsList.vue'),
    meta: {
      pageTitle: 'Patrol Templates',
      breadcrumb: [
        {
          text: 'Patrol Templates',
          active: true,
        },
      ],
      resource: 'patrols',
      action: 'create',
    },
  },
  // Add / Edit Template Form
  {
    path: '/apps/patrols/manage',
    name: 'apps-patrols-manage',
    component: () => import('@/views/apps/patrols/manage/itemEdit.vue'),
    meta: {
      pageTitle: 'Patrols - Manage',
      breadcrumb: [
        {
          text: 'Patrols - Manage',
          active: true,
        },
      ],
      resource: 'patrols',
      action: 'create',
    },
  },
  // Record Patrol Form
  {
    path: '/apps/patrols/record',
    name: 'apps-patrols-record',
    component: () => import('@/views/apps/patrols/record/itemEdit.vue'),
    meta: {
      pageTitle: 'Patrol - Record',
      breadcrumb: [
        {
          text: 'Patrol - Record',
          active: true,
        },
      ],
      resource: 'patrols',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Onboarding      ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/onboarding/list/',
    name: 'apps-onboarding',
    component: () => import('@/views/apps/onboarding/list/itemsList.vue'),
    meta: {
      pageTitle: 'Onboarding',
      breadcrumb: [
        {
          text: 'Onboarding',
          active: true,
        },
      ],
      resource: 'onboarding',
      action: 'read',
    },
  },
  // Add / Edit Form
  {
    path: '/apps/onboarding/manage',
    name: 'apps-onboarding-manage',
    component: () => import('@/views/apps/onboarding/manage/itemEdit.vue'),
    meta: {
      resource: 'onboarding',
      action: 'update',
    },
  },
  // *===============================================---*
  // *--------- Parking Permits      ----------------*
  // *===============================================---*
  // List
  {
    path: '/apps/parkingpermit/list/parking_permits',
    name: 'apps-parkingpermit',
    component: () => import('@/views/apps/parkingpermit/list/itemsList.vue'),
    meta: {
      pageTitle: 'Parking Permits',
      breadcrumb: [
        {
          text: 'Parking Permits',
          active: true,
        },
      ],
      resource: 'parkingpermit',
      action: 'read',
    },
  },
  // Add / Edit Form
  {
    path: '/apps/parkingpermit/manage',
    name: 'apps-parkingpermit-manage',
    component: () => import('@/views/apps/parkingpermit/manage/itemEdit.vue'),
    meta: {
      pageTitle: 'Parking Permit - Manage',
      breadcrumb: [
        {
          text: 'Parking Permit - Manage',
          active: true,
        },
      ],
      resource: 'parkingpermit',
      action: 'create',
    },
  },
  // *===============================================---*
  // *--------- E-Learning      ----------------*
  // *===============================================---*
  // iFrame
  {
    path: '/apps/elearning/app',
    name: 'apps-elearning',
    component: () => import('@/views/apps/elearning/iframe.vue'),
    meta: {
      pageTitle: 'E-Learning',
      breadcrumb: [
        {
          text: 'E-Learning',
          active: true,
        },
      ],
      resource: 'elearning',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Submissions      ----------------*
  // *===============================================---*
  // Add Form
  {
    path: '/apps/submissions',
    name: 'apps-submissions',
    component: () => import('@/views/apps/submissions/manage/itemEdit.vue'),
    meta: {
      resource: 'submissions',
      action: 'create',
    },
  },
]
