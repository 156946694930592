var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("update", "users")
    ? _c(
        "div",
        [
          _c(
            "b-media",
            {
              staticClass: "mb-2",
              scopedSlots: _vm._u(
                [
                  {
                    key: "aside",
                    fn: function() {
                      return [
                        _c(
                          "image-uploader",
                          {
                            attrs: {
                              "class-name": "fileinput",
                              debug: 2,
                              "max-width": 100,
                              "max-height": 100,
                              quality: 0.8,
                              "output-format": "string",
                              preview: false,
                              capture: false,
                              accept: ".jpg"
                            },
                            on: { input: _vm.inputAvatar }
                          },
                          [
                            _c(
                              "label",
                              {
                                attrs: {
                                  slot: "upload-label",
                                  for: "fileInput"
                                },
                                slot: "upload-label"
                              },
                              [
                                _c("b-avatar", {
                                  ref: "previewEl",
                                  attrs: {
                                    src: _vm.theAvatar,
                                    text: _vm.avatarText(_vm.userData.fullName),
                                    variant:
                                      "light-" +
                                      _vm.resolveUserRoleVariant(
                                        _vm.userData.role
                                      ),
                                    size: "90px",
                                    rounded: ""
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3321422128
              )
            },
            [
              _c("h4", { staticClass: "mb-1" }, [
                _vm._v(" " + _vm._s(_vm.userData.fullName) + " ")
              ])
            ]
          ),
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "b-tabs",
                        [
                          _c(
                            "b-tab",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          attrs: { icon: "UserIcon" }
                                        }),
                                        _c("span", [
                                          _vm._v("General Information")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Username",
                                                "label-for": "username"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "username",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "username",
                                                              state:
                                                                errors.length >
                                                                0
                                                                  ? false
                                                                  : null,
                                                              readonly: true,
                                                              name: "username"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .username,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "username",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.username"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client Name",
                                                "label-for":
                                                  "client_display_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "client_display_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "client_display_name",
                                                              readonly: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .client_display_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "client_display_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.client_display_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Name",
                                                "label-for": "first_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "first_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .first_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "first_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.first_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "LastName",
                                                "label-for": "last_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "last_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "last_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .last_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "last_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.last_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Phone",
                                                "label-for": "phone"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "phone",
                                                  size: 10
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c("cleave", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id: "phone",
                                                                  raw: true,
                                                                  options:
                                                                    _vm.options
                                                                      .phone,
                                                                  placeholder:
                                                                    "123 123 1234"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .phone,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.userData,
                                                                      "phone",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.phone"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Email",
                                                "label-for": "email"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "email",
                                                  rules: "required|email"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "email",
                                                              type: "email",
                                                              readonly: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .email,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "email",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.email"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 pt-1 border-top-secondary"
                                    },
                                    [
                                      _c("b-col", { attrs: { md: "12" } }, [
                                        _c("h4", [_vm._v("Access Information")])
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm.userData.cognito_group !==
                                    "Client_Viewer" &&
                                  _vm.userData.cognito_group !== "Client_Admin"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "User Role",
                                                    "label-for": "cognito_group"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "cognito_group",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.roleOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "cognito_group"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .cognito_group,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.userData,
                                                                      "cognito_group",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.cognito_group"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.userData.cognito_group !==
                                            "Client_Viewer" &&
                                          _vm.userData.cognito_group !==
                                            "Client_Admin"
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Region",
                                                        "label-for":
                                                          "region_loc"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "region_loc",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          dir: _vm
                                                                            .$store
                                                                            .state
                                                                            .appConfig
                                                                            .isRTL
                                                                            ? "rtl"
                                                                            : "ltr",
                                                                          options:
                                                                            _vm.regionOptions,
                                                                          reduce: function(
                                                                            val
                                                                          ) {
                                                                            return val.value
                                                                          },
                                                                          clearable: false,
                                                                          "input-id":
                                                                            "region_loc",
                                                                          rules:
                                                                            "required"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .userData
                                                                              .region_loc,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.userData,
                                                                              "region_loc",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "userData.region_loc"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                  _vm.userData.cognito_group === "Client_Admin"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "User Role",
                                                    "label-for": "cognito_group"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "cognito_group",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.roleOptionsClient,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "cognito_group"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .cognito_group,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.userData,
                                                                      "cognito_group",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.cognito_group"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userData.cognito_group !==
                                    "Client_Viewer" &&
                                  _vm.userData.cognito_group !== "Client_Admin"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Force Onboarding",
                                                    "label-for": "is_onboarding"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "is_onboarding",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.onboardingOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "is_onboarding"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .is_onboarding,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.userData,
                                                                      "is_onboarding",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.is_onboarding"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userData.cognito_group !==
                                    "Client_Viewer" &&
                                  _vm.userData.cognito_group !== "Client_Admin"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "User Duties",
                                                    "label-for": "user_duties"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "duties",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .duties
                                                                        .security_scheduling,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .duties,
                                                                        "security_scheduling",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.duties.security_scheduling"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Include user in Security Scheduling "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .duties
                                                                        .solutions_installer,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .duties,
                                                                        "solutions_installer",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.duties.solutions_installer"
                                                                  }
                                                                },
                                                                [
                                                                  _c("h5", [
                                                                    _vm._v(
                                                                      " Include user as Solutions Installer "
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                  _vm.userData.cognito_group === "Client_Admin"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "4" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for": "is_send_email"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "is_send_email",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_send_email",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .is_send_email,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "is_send_email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.is_send_email"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Send Emails "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_alarm_response"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "is_alarm_response",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_alarm_response",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .alarm_response,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "alarm_response",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.alarm_response"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Alarm Response "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_general_occurrence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_general_occurrence",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_general_occurrence",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .general_occurrence,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "general_occurrence",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.general_occurrence"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " General Occurrence Report "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_internal_useof_force"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_internal_useof_force",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_internal_useof_force",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .internal_useof_force,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "internal_useof_force",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.internal_useof_force"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Internal Use Of Force Report "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_parking_enforcement"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_parking_enforcement",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_parking_enforcement",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .parking_enforcement,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "parking_enforcement",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.parking_enforcement"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Parking Enforcement Report "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_parking_permits"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_parking_permits",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_parking_permits",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .parking_permits,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "parking_permits",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.parking_permits"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Parking Permit "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (_vm.userData.cognito_group ===
                                    "Client_Viewer" ||
                                    _vm.userData.cognito_group ===
                                      "Client_Admin") &&
                                  _vm.userData.is_send_email === true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-for":
                                                      "is_trespass_notice"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "is_trespass_notice",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "is_trespass_notice",
                                                                    checked:
                                                                      "true",
                                                                    "unchecked-value":
                                                                      "false",
                                                                    name:
                                                                      "check-button",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .send_email
                                                                        .trespass_notice,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .send_email,
                                                                        "trespass_notice",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.send_email.trespass_notice"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Trespass Notice "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Status",
                                                "label-for": "is_active"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "is_active",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.statusOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "is_active"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userData
                                                                  .is_active,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.userData,
                                                                  "is_active",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "userData.is_active"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                              attrs: {
                                                variant: "primary",
                                                block: "",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Save Changes ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _vm.$can("delete", "users")
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                  attrs: {
                                                    variant: "danger",
                                                    block: ""
                                                  },
                                                  on: { click: _vm.deleteUser }
                                                },
                                                [_vm._v(" Delete User ")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { md: "1" } }),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _vm.$can("delete", "users")
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                  attrs: {
                                                    variant: "secondary",
                                                    block: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.sendTemporaryPassword
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Send Temporary Passord "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.itemData &&
                          _vm.userData.cognito_group !== "Client_Viewer" &&
                            _vm.userData.cognito_group !== "Client_Admin" &&
                            _vm.currentLoggedInUserData.group === "Management"
                            ? _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c("feather-icon", {
                                              attrs: { icon: "ListIcon" }
                                            }),
                                            _c("span", [
                                              _vm._v("Onboarding Details")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return handleSubmit(
                                            _vm.validateDetailsForm
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Personal Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "First Name",
                                                    "label-for": "first_name"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_name",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .first_name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "first_name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.first_name"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Middle Initial",
                                                    "label-for":
                                                      "middle_initial"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "middle_initial"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "middle_initial"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .middle_initial,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "middle_initial",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.middle_initial"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Last Name",
                                                    "label-for": "last_name"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "last_name",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "last_name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .last_name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "last_name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.last_name"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Street",
                                                    "label-for": "street"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "street",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "street"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .street,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "street",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.street"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "City",
                                                    "label-for": "city"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "city",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "city"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .city,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "city",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.city"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Province",
                                                    "label-for": "province"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "province",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.provinceOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "province"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .province,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "province",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.province"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Country",
                                                    "label-for": "country"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "country",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.countryOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "country"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .country,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "country",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.country"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Postal Code",
                                                    "label-for": "postal_code"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "postal_code",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "postal_code"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .postal_code,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "postal_code",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.postal_code"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Email",
                                                    "label-for": "email"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "email",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "email"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .email,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.email"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Phone Number",
                                                    "label-for": "phone_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "phone_number",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c("cleave", {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "phone_number",
                                                                      raw: false,
                                                                      options:
                                                                        _vm
                                                                          .options
                                                                          .phone,
                                                                      placeholder:
                                                                        "123 123 1234"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .itemData
                                                                          .phone_number,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.itemData,
                                                                          "phone_number",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "itemData.phone_number"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Birthdate",
                                                    "label-for": "birth_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "birth_date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "birth_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .birth_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "birth_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.birth_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Gender",
                                                    "label-for": "gender"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: { name: "gender" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "radio-spacing",
                                                                  attrs: {
                                                                    options:
                                                                      _vm.genderOptions,
                                                                    name:
                                                                      "gender",
                                                                    stacked: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .gender,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "gender",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.gender"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Are you indigenous?",
                                                    "label-for": "indian_status"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "indian_status"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "radio-spacing",
                                                                  attrs: {
                                                                    options:
                                                                      _vm.indianStatusOptions,
                                                                    name:
                                                                      "indian_status",
                                                                    stacked: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .indian_status,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "indian_status",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.indian_status"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.itemData.indian_status
                                        ? _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "mt-2 mb-1 border-bottom-secondary"
                                            },
                                            [
                                              _c("b-col", [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Indian Registration Information"
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.itemData.indian_status
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Indian Registration Number",
                                                        "label-for":
                                                          "indian_reg_number"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "indian_reg_number"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "indian_reg_number"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .itemData
                                                                              .indian_reg_number,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.itemData,
                                                                              "indian_reg_number",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "itemData.indian_reg_number"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.itemData.indian_status
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "INAC Serial Number",
                                                        "label-for":
                                                          "indian_inac_serial"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "indian_inac_serial"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "indian_inac_serial"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .itemData
                                                                              .indian_inac_serial,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.itemData,
                                                                              "indian_inac_serial",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "itemData.indian_inac_serial"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.itemData.indian_status
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Indian Expiry Date",
                                                        "label-for":
                                                          "indian_exp_date"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "indian_exp_date"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "flat-pickr",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            "indian_exp_date",
                                                                          config: {
                                                                            enableTime: true,
                                                                            dateFormat:
                                                                              "Y-m-d H:i:ss"
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .itemData
                                                                              .indian_exp_date,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.itemData,
                                                                              "indian_exp_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "itemData.indian_exp_date"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.itemData.indian_status
                                        ? _c("b-row", [
                                            _c(
                                              "section",
                                              [
                                                _c(
                                                  "b-row",
                                                  {
                                                    staticClass:
                                                      "mb-2 ml-0 mt-2"
                                                  },
                                                  [
                                                    _vm.$can(
                                                      "update",
                                                      _vm.abilityRequired
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { md: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                                attrs: {
                                                                  variant:
                                                                    "primary",
                                                                  block: "",
                                                                  disabled:
                                                                    _vm.preventDoubleClick
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openCamera(
                                                                      "permit"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Take Picture "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.$can(
                                                      "update",
                                                      _vm.abilityRequired
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: { md: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                                attrs: {
                                                                  variant:
                                                                    "primary",
                                                                  block: "",
                                                                  disabled:
                                                                    _vm.preventDoubleClick
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openImageSelector(
                                                                      "permit"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Add Image "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        : _vm._e(),
                                      _c("b-row", [
                                        _vm.itemData.permit_image &&
                                        _vm.itemData.permit_image.access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData.permit_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData.permit_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .permit_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .permit_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("SIN Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "SIN Number",
                                                    "label-for": "sin"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "sin",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "sin"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .sin,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "sin",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.sin"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "SIN Expiry Date",
                                                    "label-for":
                                                      "sin_expiry_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "sin_expiry_date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "sin_expiry_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .sin_expiry_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "sin_expiry_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.sin_expiry_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              id: "sin_image",
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "sin"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "sin"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.sin_image &&
                                        _vm.itemData.sin_image.access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData.sin_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData.sin_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData.sin_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .sin_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      typeof _vm.itemData.sin !== undefined &&
                                      _vm.itemData.sin !== null &&
                                      _vm.itemData.sin !== "" &&
                                      _vm.itemData.sin.length > 0 &&
                                      _vm.itemData.sin.slice(0, 1) === "9"
                                        ? _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "mt-2 mb-1 border-bottom-secondary"
                                            },
                                            [
                                              _c("b-col", [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Work Permit Information"
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      typeof _vm.itemData.sin !== undefined &&
                                      _vm.itemData.sin !== null &&
                                      _vm.itemData.sin !== "" &&
                                      _vm.itemData.sin.length > 0 &&
                                      _vm.itemData.sin.slice(0, 1) === "9"
                                        ? _c(
                                            "b-row",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Permit Number",
                                                        "label-for": "permit"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "permit",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "permit"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .itemData
                                                                              .permit,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.itemData,
                                                                              "permit",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "itemData.permit"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      typeof _vm.itemData.sin !== undefined &&
                                      _vm.itemData.sin !== null &&
                                      _vm.itemData.sin !== "" &&
                                      _vm.itemData.sin.length > 0 &&
                                      _vm.itemData.sin.slice(0, 1) === "9"
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Permit Expiry Date",
                                                        "label-for":
                                                          "permit_expiry_date"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "permit_expiry_date",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "flat-pickr",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            "permit_expiry_date",
                                                                          config: {
                                                                            enableTime: false,
                                                                            dateFormat:
                                                                              "Y-m-d"
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .itemData
                                                                              .permit_expiry_date,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.itemData,
                                                                              "permit_expiry_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "itemData.permit_expiry_date"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Banking Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Bank Number",
                                                    "label-for": "bank_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "bank_number",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "bank_number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .bank_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "bank_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.bank_number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Transit Number",
                                                    "label-for":
                                                      "transit_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "transit_number",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "transit_number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .transit_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "transit_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.transit_number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Account Number",
                                                    "label-for":
                                                      "account_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "account_number",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "account_number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .account_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "account_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.account_number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "deposit"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "deposit"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.direct_deposit_image &&
                                        _vm.itemData.direct_deposit_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .direct_deposit_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .direct_deposit_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .direct_deposit_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .direct_deposit_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Security Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Security Licence Number",
                                                    "label-for":
                                                      "security_licence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "security_licence",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "security_licence"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .security_licence,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "security_licence",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.security_licence"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Security Licence Expiry Date",
                                                    "label-for":
                                                      "security_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "security_licence_exp_date",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "security_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .security_licence_exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "security_licence_exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.security_licence_exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "1" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Licence Class",
                                                    "label-for":
                                                      "security_licence_class"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "security_licence_class",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "security_licence_class"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .security_licence_class,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "security_licence_class",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.security_licence_class"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                staticClass: "mb-1 ml-1 mt-2",
                                                attrs: {
                                                  label:
                                                    "Security Licence Image",
                                                  "label-for":
                                                    "security_licence_image"
                                                }
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name:
                                                      "security_licence_image",
                                                    rule: _vm.dynamicRequired
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              id:
                                                                "security_licence_image",
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "security_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "security_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.security_licence_image &&
                                        _vm.itemData.security_licence_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .security_licence_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .security_licence_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .security_licence_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .security_licence_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("First Aid Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Agency Name",
                                                    "label-for":
                                                      "first_aid_agency"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_agency"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_aid_agency"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .first_aid_agency,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "first_aid_agency",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.first_aid_agency"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "First Aid Type",
                                                    "label-for":
                                                      "first_aid_type"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_type"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.firstAidTypeOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "first_aid_type"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .first_aid_type,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "first_aid_type",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.first_aid_type"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Certificate Number",
                                                    "label-for":
                                                      "first_aid_certificate"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "first_aid_certificate"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_aid_certificate"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .first_aid_certificate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "first_aid_certificate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.first_aid_certificate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Expiry Date",
                                                    "label-for":
                                                      "first_aid_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "first_aid_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .first_aid_exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "first_aid_exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.first_aid_exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                staticClass: "mb-1 ml-1 mt-2",
                                                attrs: {
                                                  label: "First Aid Image",
                                                  "label-for": "first_aid_image"
                                                }
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "first_aid_image",
                                                    rule: _vm.dynamicRequired
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "first_aid"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "first_aid"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.first_aid_image &&
                                        _vm.itemData.first_aid_image.access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .first_aid_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .first_aid_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .first_aid_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .first_aid_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Firearms Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Number",
                                                    "label-for":
                                                      "firearms_licence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "firearms_licence"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "firearms_licence"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .firearms_licence,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "firearms_licence",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.firearms_licence"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Expiry Date",
                                                    "label-for":
                                                      "firearms_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "firearms_licence_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "firearms_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .firearms_licence_exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "firearms_licence_exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.firearms_licence_exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Authorization",
                                                    "label-for":
                                                      "firearms_licence_auth"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "firearms_licence_auth"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.firearmsLicenceAuthOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "firearms_licence_auth"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .firearms_licence_auth,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "firearms_licence_auth",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.firearms_licence_auth"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c("b-form-group", {
                                              staticClass: "mb-1 ml-1 mt-2",
                                              attrs: {
                                                label: "Firearms Licence Image",
                                                "label-for":
                                                  "firearms_licence_image"
                                              }
                                            }),
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "firearms_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "firearms_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.firearms_licence_image &&
                                        _vm.itemData.firearms_licence_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .firearms_licence_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .firearms_licence_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .firearms_licence_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .firearms_licence_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Uniform Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Vest Size",
                                                    "label-for": "uniform_vest"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_vest",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.uniformVestOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "uniform_vest"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .uniform_vest,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "uniform_vest",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.uniform_vest"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Shirt Size",
                                                    "label-for": "uniform_shirt"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_shirt",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.uniformShirtOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "uniform_shirt"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .uniform_shirt,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "uniform_shirt",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.uniform_shirt"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Waist (inches)",
                                                    "label-for":
                                                      "uniform_pants_waist"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "uniform_pants_waist",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_pants_waist"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .uniform_pants_waist,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "uniform_pants_waist",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.uniform_pants_waist"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Pant Length (inches)",
                                                    "label-for":
                                                      "uniform_pants_length"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "uniform_pants_length",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_pants_length"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .uniform_pants_length,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "uniform_pants_length",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.uniform_pants_length"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Glove Size",
                                                    "label-for": "uniform_glove"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_glove",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_glove"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .uniform_glove,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "uniform_glove",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.uniform_glove"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Hat Size",
                                                    "label-for": "uniform_hat"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_hat",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_hat"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .uniform_hat,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "uniform_hat",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.uniform_hat"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Shoe Size (US Size)",
                                                    "label-for": "uniform_shoe"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_shoe",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_shoe"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .uniform_shoe,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "uniform_shoe",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.uniform_shoe"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Proof of Identification")
                                            ]),
                                            _c("div", [
                                              _vm._v(" Please provide "),
                                              _c("strong", [_vm._v("TWO (2)")]),
                                              _vm._v(
                                                " of the following forms of identification:"
                                              ),
                                              _c("br"),
                                              _vm._v(" - Drivers Licence"),
                                              _c("br"),
                                              _vm._v(" - Passport"),
                                              _c("br"),
                                              _vm._v(" - Provincial Photo ID"),
                                              _c("br"),
                                              _vm._v(" - PAL Card"),
                                              _c("br"),
                                              _vm._v(" - Native Status"),
                                              _c("br"),
                                              _vm._v(
                                                " - Permanent Residence Card / Document"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " - Record of Landing with photo"
                                              ),
                                              _c("br"),
                                              _c("br")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1" },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Drivers Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Drivers Licence Number",
                                                    "label-for":
                                                      "drivers_licence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "drivers_licence"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "drivers_licence"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .drivers_licence,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "drivers_licence",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.drivers_licence"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Drivers Licence Issue Date",
                                                    "label-for":
                                                      "drivers_licence_issue_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "drivers_licence_issue_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "drivers_licence_issue_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .drivers_licence_issue_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "drivers_licence_issue_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.drivers_licence_issue_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Drivers Licence Expiry Date",
                                                    "label-for":
                                                      "drivers_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "drivers_licence_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "drivers_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .drivers_licence_exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "drivers_licence_exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.drivers_licence_exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "1" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Licence Class",
                                                    "label-for":
                                                      "drivers_licence_class"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "drivers_licence_class"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "drivers_licence_class"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .drivers_licence_class,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "drivers_licence_class",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.drivers_licence_class"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "drivers_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "drivers_licence"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.drivers_licence_image &&
                                        _vm.itemData.drivers_licence_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .drivers_licence_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .drivers_licence_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .drivers_licence_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .drivers_licence_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1" },
                                        [
                                          _c("b-col", [
                                            _c("h5", [_vm._v("-- OR --")]),
                                            _c("h4", [
                                              _vm._v(
                                                "Additional Form of Identification"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "identification1"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "identification1"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.identification1_image &&
                                        _vm.itemData.identification1_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .identification1_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .identification1_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .identification1_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .identification1_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1" },
                                        [
                                          _c("b-col", [
                                            _c("h5", [_vm._v("-- OR --")]),
                                            _c("h4", [
                                              _vm._v(
                                                "Additional Form of Identification"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "identification2"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "identification2"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-row", [
                                        _vm.itemData.identification2_image &&
                                        _vm.itemData.identification2_image
                                          .access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData
                                                        .identification2_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData
                                                        .identification2_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .identification2_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .identification2_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.allowApproval &&
                                          _vm.$can(
                                            "delete",
                                            _vm.abilityRequired
                                          )
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.itemData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : !_vm.userData.is_onboarding &&
                              _vm.userData.cognito_group !== "Client_Viewer" &&
                                _vm.userData.cognito_group !== "Client_Admin" &&
                                _vm.currentLoggedInUserData.group ===
                                  "Management"
                            ? _c(
                                "b-tab",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function() {
                                          return [
                                            _c("feather-icon", {
                                              attrs: { icon: "ListIcon" }
                                            }),
                                            _c("span", [
                                              _vm._v("Additional Details")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return handleSubmit(_vm.validateForm)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Personal Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "First Name",
                                                    "label-for": "first_name"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_name",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .first_name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "first_name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.first_name"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Middle Initial",
                                                    "label-for":
                                                      "middle_initial"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "middle_initial"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "middle_initial"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .middle_initial,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "middle_initial",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.middle_initial"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Last Name",
                                                    "label-for": "last_name"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "last_name",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "last_name"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .last_name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "last_name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.last_name"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Street",
                                                    "label-for": "street"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "street",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "street"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .address
                                                                        .street,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .address,
                                                                        "street",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.address.street"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "City",
                                                    "label-for": "city"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "city",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "city"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .address
                                                                        .city,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .address,
                                                                        "city",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.address.city"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Province",
                                                    "label-for": "province"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "province",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.provinceOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "province"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .address
                                                                      .province,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .address,
                                                                      "province",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.address.province"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Country",
                                                    "label-for": "country"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "country",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.countryOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "country"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .address
                                                                      .country,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .address,
                                                                      "country",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.address.country"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Postal Code",
                                                    "label-for": "postal_code"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "postal_code",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "postal_code"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .address
                                                                        .postal,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .address,
                                                                        "postal",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.address.postal"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Email",
                                                    "label-for": "email"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "email",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "email"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .email,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.email"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Phone Number",
                                                    "label-for": "phone_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "phone_number",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                [
                                                                  _c("cleave", {
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "phone_number",
                                                                      raw: false,
                                                                      options:
                                                                        _vm
                                                                          .options
                                                                          .phone,
                                                                      placeholder:
                                                                        "123 123 1234"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .userData
                                                                          .phone,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.userData,
                                                                          "phone",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "userData.phone"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Birthdate",
                                                    "label-for": "birth_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "birth_date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "email"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .dob,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "dob",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.dob"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Gender",
                                                    "label-for": "gender"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: { name: "gender" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "radio-spacing",
                                                                  attrs: {
                                                                    options:
                                                                      _vm.genderOptions,
                                                                    name:
                                                                      "gender",
                                                                    stacked: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .gender,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.userData,
                                                                        "gender",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.gender"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Are you indigenous?",
                                                    "label-for": "indian_status"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "indian_status"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-radio-group",
                                                                {
                                                                  staticClass:
                                                                    "radio-spacing",
                                                                  attrs: {
                                                                    options:
                                                                      _vm.indianStatusOptions,
                                                                    name:
                                                                      "indian_status",
                                                                    stacked: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .indian
                                                                        .status,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .indian,
                                                                        "status",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.indian.status"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.userData.indian.status
                                        ? _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "mt-2 mb-1 border-bottom-secondary"
                                            },
                                            [
                                              _c("b-col", [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Indian Registration Information"
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.userData.indian.status
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Indian Expiry Date",
                                                        "label-for":
                                                          "indian_exp_date"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "indian_exp_date"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "flat-pickr",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            "indian_exp_date",
                                                                          config: {
                                                                            enableTime: true,
                                                                            dateFormat:
                                                                              "Y-m-d"
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .userData
                                                                              .indian
                                                                              .exp_date,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .userData
                                                                                .indian,
                                                                              "exp_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "userData.indian.exp_date"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("b-row", [
                                        _vm.userData.permit_image &&
                                        _vm.userData.permit_image.access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.userData.permit_image
                                                        .access_url,
                                                    ref:
                                                      _vm.userData.permit_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.userData
                                                          .permit_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.userData
                                                                  .permit_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("SIN Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "SIN Expiry Date",
                                                    "label-for":
                                                      "sin_expiry_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "sin_expiry_date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "sin_expiry_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .sin
                                                                      .exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .sin,
                                                                      "exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.sin.exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      typeof _vm.userData.permit !==
                                        undefined &&
                                      _vm.userData.permit !== null
                                        ? _c(
                                            "b-row",
                                            {
                                              staticClass:
                                                "mt-2 mb-1 border-bottom-secondary"
                                            },
                                            [
                                              _c("b-col", [
                                                _c("h4", [
                                                  _vm._v(
                                                    "Work Permit Information"
                                                  )
                                                ])
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      typeof _vm.userData.permit !==
                                        undefined &&
                                      _vm.userData.permit !== null
                                        ? _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label:
                                                          "Permit Expiry Date",
                                                        "label-for":
                                                          "permit_expiry_date"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name:
                                                              "permit_expiry_date",
                                                            rules: "required"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "flat-pickr",
                                                                      {
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          id:
                                                                            "permit_expiry_date",
                                                                          config: {
                                                                            enableTime: false,
                                                                            dateFormat:
                                                                              "Y-m-d"
                                                                          }
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .userData
                                                                              .permit
                                                                              .exp_date,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .userData
                                                                                .permit,
                                                                              "exp_date",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "userData.permit.exp_date"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Security Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Security Licence Number",
                                                    "label-for":
                                                      "security_licence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "security_licence",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "security_licence"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .security_licence
                                                                        .number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .security_licence,
                                                                        "number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.security_licence.number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Security Licence Expiry Date",
                                                    "label-for":
                                                      "security_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "security_licence_exp_date",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "security_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .security_licence
                                                                      .exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .security_licence,
                                                                      "exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.security_licence.exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "1" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Licence Class",
                                                    "label-for":
                                                      "security_licence_class"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "security_licence_class",
                                                      rule: _vm.dynamicRequired
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "security_licence_class"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .security_licence
                                                                        .class,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .security_licence,
                                                                        "class",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.security_licence.class"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("First Aid Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Agency Name",
                                                    "label-for":
                                                      "first_aid_agency"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_agency"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_aid_agency"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .first_aid
                                                                        .agency,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .first_aid,
                                                                        "agency",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.first_aid.agency"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "First Aid Type",
                                                    "label-for":
                                                      "first_aid_type"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_type"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.firstAidTypeOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "first_aid_type"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .first_aid
                                                                      .type,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .first_aid,
                                                                      "type",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.first_aid.type"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Certificate Number",
                                                    "label-for":
                                                      "first_aid_certificate"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "first_aid_certificate"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "first_aid_certificate"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .first_aid
                                                                        .certificate,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .first_aid,
                                                                        "certificate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.first_aid.certificate"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "First Aid Expiry Date",
                                                    "label-for":
                                                      "first_aid_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "first_aid_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "first_aid_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .first_aid
                                                                      .exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .first_aid,
                                                                      "exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.first_aid.exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Firearms Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Number",
                                                    "label-for":
                                                      "firearms_licence"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "firearms_licence"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "firearms_licence"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .firearms_licence
                                                                        .number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .firearms_licence,
                                                                        "number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.firearms_licence.number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Expiry Date",
                                                    "label-for":
                                                      "firearms_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "firearms_licence_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "firearms_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .firearms_licence
                                                                      .exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .firearms_licence,
                                                                      "exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.firearms_licence.exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Firearms Licence Authorization",
                                                    "label-for":
                                                      "firearms_licence_auth"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "firearms_licence_auth"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.firearmsLicenceAuthOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "firearms_licence_auth"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .firearms_licence
                                                                      .auth,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .firearms_licence,
                                                                      "auth",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.firearms_licence.auth"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Uniform Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Vest Size",
                                                    "label-for": "uniform_vest"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_vest",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.uniformVestOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "uniform_vest"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .uniform
                                                                      .vest,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .uniform,
                                                                      "vest",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.uniform.vest"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "3" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Shirt Size",
                                                    "label-for": "uniform_shirt"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_shirt",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.uniformShirtOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "uniform_shirt"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .uniform
                                                                      .shirt,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .uniform,
                                                                      "shirt",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.uniform.shirt"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Waist (inches)",
                                                    "label-for":
                                                      "uniform_pants_waist"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "uniform_pants_waist",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_pants_waist"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .uniform
                                                                        .pants_waist,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .uniform,
                                                                        "pants_waist",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.uniform.pants_waist"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Pant Length (inches)",
                                                    "label-for":
                                                      "uniform_pants_length"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "uniform_pants_length",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_pants_length"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .uniform
                                                                        .pants_length,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .uniform,
                                                                        "pants_length",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.uniform.pants_length"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Glove Size",
                                                    "label-for": "uniform_glove"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_glove",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_glove"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .uniform
                                                                        .glove,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .uniform,
                                                                        "glove",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.uniform.glove"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Hat Size",
                                                    "label-for": "uniform_hat"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_hat",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_hat"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .uniform
                                                                        .hat,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .uniform,
                                                                        "hat",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.uniform.hat"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Shoe Size (US Size)",
                                                    "label-for": "uniform_shoe"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "uniform_shoe",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "uniform_shoe"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .uniform
                                                                        .shoe,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .uniform,
                                                                        "shoe",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.uniform.shoe"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Drivers Licence Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Drivers Licence Expiry Date",
                                                    "label-for":
                                                      "drivers_licence_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "drivers_licence_exp_date"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "drivers_licence_exp_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userData
                                                                      .drivers_licence
                                                                      .exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .userData
                                                                        .drivers_licence,
                                                                      "exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "userData.drivers_licence.exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "1" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Licence Class",
                                                    "label-for":
                                                      "drivers_licence_class"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "drivers_licence_class"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "drivers_licence_class"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .userData
                                                                        .drivers_licence
                                                                        .class,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .userData
                                                                          .drivers_licence,
                                                                        "class",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "userData.drivers_licence.class"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              110353041
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }